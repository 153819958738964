import React from 'react';
//import config from '../../config';
//import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';

import css from './ContactPage.css';




const ContactPage = () => {
  // const { siteTwitterHandle, siteFacebookPage } = config;
  // const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      title="Kontakt"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ContactPage',
        description: 'Contact Sharepar',
        name: 'Contact page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ContactPage"/>
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>Kontakt</h1>

          <div className={css.contentWrapper}>
          <div className={css.contentMain}>
            <h2 className={css.pageTitle}>
            <ExternalLink
              key="MailToHelp"
              href="mailto:info@sharepar.com"
              className=""
              title="info@sharepar.com"
            >info@sharepar.com</ExternalLink></h2>
            <p>Wenn du mehr über Sharepar oder die Menschen dahinter wissen möchtest, schicke uns eine Mail an diese Adresse.
Wir melden uns bei dir. Wir freuen uns auf den Dialog mit dir.
Dein Sharepar Team</p>
          </div>

          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default ContactPage;

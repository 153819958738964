import React from 'react';
import { string } from 'prop-types';
import { /*FormattedMessage,intlShape*/ injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
//import { twitterPageURL } from '../../util/urlHelpers';
//import config from '../../config';
import {
  /*
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,*/
  ExternalLink,
  /*NamedLink,*/
} from '../../components';

import css from './Footer.css';
/*
const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};
*/
const Footer = props => {
  const { rootClassName, className /*intl*/ } = props;
  //const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);
  //const { siteSharingManifestPage } = config;
  //const { siteSharingTypPage  } = config;

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.organizationLinks}>
              <h3>Über uns</h3>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <ExternalLink
                    key="linkToSharparWebsite"
                    href="https://www.sharepar.com/"
                    className={css.link}
                  >
                    www.sharepar.com
                  </ExternalLink>
                </li>
              </ul>
            </div>

            <div className={css.infoLinks}>
              <h3>Support</h3>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <ExternalLink
                    key="linkToHowTo"
                    href="https://www.sharepar.com/wie-es-funktioniert/"
                    className={css.link}
                  >
                    Wie es funktioniert
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    key="linkToTipps"
                    href="https://www.sharepar.com/tipps-fuer-nutzerinnen/"
                    className={css.link}
                  >
                    Tipps für Nutzer:innen
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    key="linkToFAQ"
                    href="https://www.sharepar.com/faq/"
                    className={css.link}
                  >
                    FAQ
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    key="linkToDownloads"
                    href="https://www.sharepar.com/downloads/"
                    className={css.link}
                  >
                    Downloads
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    key="linkToMail"
                    href="mailto:hilfe@sharepar.com"
                    className={css.link}
                  >
                    hilfe@sharepar.com
                  </ExternalLink>
                </li>
              </ul>
            </div>

            <div className={css.appLinks}>
              <h3>Externe Apps</h3>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <ExternalLink key="linkToTapkey" href="https://tapkey.com/" className={css.link}>
                    tapkey.com
                  </ExternalLink>
                </li>
                {/* <li className={css.listItem}>
                  <ExternalLink
                    key="linkToFlinkey"
                    href="https://www.flinkey.com/"
                    className={css.link}
                  >
                    flinkey
                  </ExternalLink>
                </li> */}
                <li className={css.listItem}>
                  <ExternalLink
                    key="linkToW3W"
                    href="https://what3words.com/auswahl.witze.verliebt"
                    className={css.link}
                  >
                    what3words
                  </ExternalLink>
                </li>
              </ul>
            </div>

            <div className={css.legalLinks}>
              <h3>Rechtliche Hinweise</h3>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <ExternalLink
                    key="linkToAGB"
                    href="https://www.sharepar.com/agb/"
                    className={css.link}
                  >
                    AGB
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    key="linkToPrivacy"
                    href="https://www.sharepar.com/datenschutzerklaerung/"
                    className={css.link}
                  >
                    Datenschutzerklaerung
                  </ExternalLink>
                </li>
                <li className={css.listItem}>
                  <ExternalLink
                    key="linkToImprint"
                    href="https://www.sharepar.com/impressum/"
                    className={css.link}
                  >
                    Impressum
                  </ExternalLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  //intl: intlShape.isRequired,
};

export default injectIntl(Footer);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
  LayoutWrapperAccessProviderSideNav,
  InlineTextButton,
} from '../../components';
import { TopbarContainer } from '../../containers';
import FlinkeyAuthForm from '../../forms/FlinkeyAuthForm/FlinkeyAuthForm';
import { signUpFlinkey, flinkeyCredentialsExist, disconnectFlinkey } from '../../ducks/Flinkey.duck';

import css from './FlinkeyPage.css';

export const FlinkeyPageComponent = ({
  scrollingDisabled,
  intl,
  currentUser,
  onSignUpFlinkey,
  flinkeyCredentialsError,
  flinkeyCredentialsExist,
  onFlinkeyCredentialsExist,
  onDisconnectFlinkey,
}) => {
  const userId = currentUser?.id.uuid ?? null
  const title = intl.formatMessage({ id: 'FlinkeyPage.title' });

  useEffect(() => {
    if (userId) {
      onFlinkeyCredentialsExist(userId);
    }
  }, [userId, onFlinkeyCredentialsExist]);

  const handleDisconnectFlinkey = () => {
    onDisconnectFlinkey(userId);
  }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="FlinkeyPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="FlinkeyPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccessProviderSideNav currentTab="FlinkeyPage" />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="FlinkeyPage.heading" />
            </h1>
            <h2 className={css.title}>
              <FormattedMessage id="FlinkeyPage.info" />
            </h2>
            {flinkeyCredentialsExist ? (
              <h2>
                <FormattedMessage id="FlinkeyPage.existAccount" />
                <InlineTextButton className={css.flinkeyBtn} onClick={handleDisconnectFlinkey}>
                  <FormattedMessage id="FlinkeyPage.disconnectFlinkey" />
                </InlineTextButton>
              </h2>
            ) : (
              <FlinkeyAuthForm className={css.form} onSubmit={onSignUpFlinkey} />
            )}
            {flinkeyCredentialsError ? <div className={css.error}>
              {flinkeyCredentialsError.message}
              </div> : null}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const { bool } = PropTypes;

FlinkeyPageComponent.defaultProps = {};

FlinkeyPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => ({
  flinkeyCredentialsError: state.Flinkey.flinkeyCredentialsError,
  flinkeyCredentialsExist: state.Flinkey.flinkeyCredentialsExist,
  currentUser: state.user.currentUser,
  scrollingDisabled: isScrollingDisabled(state),
});

const mapDispatchToProps = dispatch => ({
  onSignUpFlinkey: values => dispatch(signUpFlinkey(values)),
  onFlinkeyCredentialsExist: userId =>
    dispatch(flinkeyCredentialsExist(userId)),
  onDisconnectFlinkey: userId =>
    dispatch(disconnectFlinkey(userId)),
});

const FlinkeyPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(FlinkeyPageComponent);

export default FlinkeyPage;

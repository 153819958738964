import React, { Component } from 'react';
import { object, string, shape, array } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import config from '../../config';
import * as validators from '../../util/validators';
import { Form, Button, FieldCheckbox, FieldTextInput } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './EditListingAvailabilityTimeOption.css';

const { Money } = sdkTypes;

const ListTimeOption = [
  {
    label: <FormattedMessage id="EditListingAvailabilityTimeOption.hourly" />,
    value: 'hourly',
  },
  {
    label: <FormattedMessage id="EditListingAvailabilityTimeOption.daily" />,
    value: 'daily',
  },
  {
    label: <FormattedMessage id="EditListingAvailabilityTimeOption.nightly" />,
    value: 'nightly',
  },
  // {
  //   label: <FormattedMessage id="EditListingAvailabilityTimeOption.weekly" />,
  //   value: 'weekly',
  // },
];

// const printHourStrings = h => (h > 9 ? `${h}:00` : `0${h}:00`);

// const HOURS = Array(24).fill();
// const ALL_START_HOURS = [...HOURS].map((v, i) => printHourStrings(i));
// const ALL_END_HOURS = [...HOURS].map((v, i) => printHourStrings(i + 1));

export class EditListingAvailabilityTimeOptionComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTimeOptions: [],
      currentTimePrice: {},
      showSubmitMessage: false,
      key: 0,
      hourlyInputValue: '0',
      dailyInputValue: '0',
      nightlyInputValue: '0',
      weeklyInputValue: '0',
      isNotNumber: false,
      priceBetween0and1: false,
      isOnFocus: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.getDefaultPrice = this.getDefaultPrice.bind(this);
    this.showHideMessage = this.showHideMessage.bind(this);
    this.setPriceOption = this.setPriceOption.bind(this);
    this.setPrice = this.setPrice.bind(this);
    this.getPrice = this.getPrice.bind(this);
    this.setDefaultPrice = this.setDefaultPrice.bind(this);
  }

  setPriceOption(key, value) {
    switch (key) {
      case 'hourly':
        this.setState({ hourlyInputValue: parseFloat(value) > 0 ? value / 100 : value.toString() });
        break;
      case 'daily':
        this.setState({ dailyInputValue: parseFloat(value) > 0 ? value / 100 : value.toString() });
        break;
      case 'nightly':
        this.setState({ nightlyInputValue: parseFloat(value) > 0 ? value / 100 : value.toString() });
        break;
      case 'weekly':
        this.setState({ weeklyInputValue: parseFloat(value) > 0 ? value / 100 : value.toString() });
        break;

      default:
        break;
    }
  }

  componentDidMount() {
    const { timePrice } = this.props.listing.attributes.publicData;

    if (timePrice) {
      const arr = [];
      // eslint-disable-next-line
      for (const key in timePrice) {
        if (timePrice.hasOwnProperty(key) && key !== 'currency') {
          arr.push(key);
          this.setPriceOption(key, timePrice[key]);
        }
      }

      this.setState({
        selectedTimeOptions: arr,
      });
    }
  }

  getDefaultPrice(value) {
    const { timePrice } = this.props.listing.attributes.publicData;
    let defaultPrice = null;

    if (timePrice) {
      // eslint-disable-next-line
      for (const key in timePrice) {
        if (timePrice.hasOwnProperty(key)) {
          if (key === value) {
            defaultPrice = timePrice[key];
          }
        }
      }
    }

    return defaultPrice;
  }

  getPrice(value) {
    switch (value) {
      case 'hourly':
        return this.state.hourlyInputValue;
      case 'daily':
        return this.state.dailyInputValue;
      case 'nightly':
        return this.state.nightlyInputValue;
      case 'weekly':
        return this.state.weeklyInputValue;

      default:
        break;
    }
  }

  setDefaultPrice(value) {
    const priceInNumber = this.getPrice(value) && parseFloat(this.getPrice(value));
    const currency = config.currency === 'EUR' ? '€' : config.currency;
    const price =
      isNaN(priceInNumber) || priceInNumber === ''
        ? `${this.getPrice(value)} `
        : `${priceInNumber} ${currency}`;

    return price;
  }

  setPrice(valueName, value) {
    const valueWithDot = value.replace(/,/gi , '.');
    const priceWithoutCurrency = +(Math.round(valueWithDot.split(' ')[0] + 'e+2') + 'e-2');
    const isnum =
      valueWithDot === ''
        ? true
        : (priceWithoutCurrency || priceWithoutCurrency === 0)
        ? /^[-+]?[0-9]*[.,]?[0-9]+(?:[eE][-+]?[0-9]+)?$/.test(priceWithoutCurrency)
        : false;
    const newValue = isnum ? (valueWithDot === '' ? '0' : priceWithoutCurrency) : value;
    const priceMore0Less1 = parseFloat(newValue) > 0 && parseFloat(newValue) < 1
    this.setPriceOption(valueName, newValue > 0 ? newValue * 100 : newValue);
    this.setState({ isNotNumber: !isnum, isOnFocus: false, priceBetween0and1: priceMore0Less1 });
  }

  handleFormSubmit(e) {
    const { selectedTimeOptions } = this.state;

    const price = new Money(0, config.currency);

    const updateData = {};

    let isPriceNull = false;

    // eslint-disable-next-line
    for (const key in e) {
      const price = parseFloat(e[key].split(' ')[0]);
      if (selectedTimeOptions.includes(key) && price === 0) {
        isPriceNull = true;
      }
    }

    // eslint-disable-next-line
    for (const key in e) {
      const price = parseFloat(e[key].split(' ')[0]);
      if (selectedTimeOptions.includes(key) && isPriceNull) {
        updateData[key] = 0;
        this.setState({
          hourlyInputValue: '0',
          dailyInputValue: '0',
          nightlyInputValue: '0',
          weeklyInputValue: '0',
        });
      } else if (selectedTimeOptions.includes(key) && price >= 1) {
        updateData[key] = price * 100;
        this.setPriceOption(key, price * 100);
      } else if (selectedTimeOptions.includes(key) && price > 0 && price < 1) {
        updateData[key] = 100;
        this.setPriceOption(key, 100);
      }
    }

    updateData.currency = config.currency;

    this.props.onSubmit(updateData, price, isPriceNull);
    this.setState({
      showSubmitMessage: true,
    });
  }

  showHideMessage() {
    setTimeout(() => {
      this.setState({
        showSubmitMessage: false,
        key: this.state.key + 1,
      });
    }, 2000);

    return (
      <div className={css.submitMessage}>
        <FormattedMessage id="EditListingAvailabilityTimeOption.submitedMessage" />
      </div>
    );
  }

  render() {
    const { listing, rootClassName, className, onHandleChange, intl, ...rest } = this.props;
    const { selectedTimeOptions, isNotNumber, isOnFocus, priceBetween0and1 } = this.state;
    const classes = classNames(rootClassName || css.root, className);
    const { timePrice } = listing.attributes.publicData || null;

    const isTimeOptionNotEmpty = timePrice
      ? 'hourly' in timePrice ||
        'daily' in timePrice ||
        'nightly' in timePrice ||
        'weekly' in timePrice
      : false;

    const priceRequired = validators.required(
      intl.formatMessage({
        id: 'EditListingPricingForm.priceRequired',
      })
    );

    const pricePlaceholder = intl.formatMessage({
      id: 'EditListingAvailabilityTimeOption.enterPrice',
    });

    const onChangeCheckBox = e => {
      const arrTimeOptions = [...selectedTimeOptions];
      const index = arrTimeOptions.indexOf(e.target.value);

      if (index > -1) {
        arrTimeOptions.splice(index, 1);
      } else {
        arrTimeOptions.push(e.target.value);
      }

      this.setState({
        selectedTimeOptions: arrTimeOptions,
        isNotNumber: false,
      });

      onHandleChange();
    };

    // const startTimePlaceholder = intl.formatMessage({
    //   id: 'EditListingAvailabilityPlanForm.startTimePlaceholder',
    // });
    // const endTimePlaceholder = intl.formatMessage({
    //   id: 'EditListingAvailabilityPlanForm.endTimePlaceholder',
    // });

    return (
      <FinalForm
        {...rest}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const { handleSubmit, fetchErrors } = fieldRenderProps;

          const { updateListingError } = fetchErrors || {};

          return (
            <Form onSubmit={handleSubmit} className={classes}>
              <div className={css.timeOptionsContainer}>
                <div className={css.timeOptionTitle}>
                  <FormattedMessage id="EditListingAvailabilityTimeOption.selectTimeOptions" />
                </div>
                <div className={css.checkBoxContainer}>
                  {ListTimeOption.map(item => {
                    return (
                      <div className={css.rowContainer} key={item.value}>
                        <>
                          <div className={css.checkboxRowContainer}>
                            <FieldCheckbox
                              className={css.checkbox}
                              textClassName={css.checkboxText}
                              id={item.value}
                              name="timeOptions"
                              label={item.label}
                              value={item.value}
                              checked={selectedTimeOptions.includes(item.value)}
                              onChange={onChangeCheckBox}
                            />
                            {selectedTimeOptions.includes(item.value) ? (
                              <>
                                <FieldTextInput
                                  className={css.field}
                                  type="text"
                                  id={item.value}
                                  name={item.value}
                                  min={0}
                                  placeholder={pricePlaceholder}
                                  defaultValue={this.setDefaultPrice(item.value)}
                                  validate={priceRequired}
                                  key={this.state.key}
                                  onBlur={e => {
                                    this.setPrice(item.value, e.target.value);
                                  }}
                                  onFocus={() => {
                                    this.setState({ isOnFocus: true });
                                  }}
                                />
                                <div className={css.fieldUnits}>
                                  {item.value === 'hourly' ? (
                                    <FormattedMessage id="EditListingAvailabilityTimeOption.perHour" />
                                  ) : item.value === 'nightly' ? (
                                    <FormattedMessage id="EditListingAvailabilityTimeOption.perNight" />
                                  ) : (
                                    <FormattedMessage id="EditListingAvailabilityTimeOption.perDay" />
                                  )}
                                </div>
                              </>
                            ) : null}
                          </div>
                          {/* {item.value === 'nightly' && selectedTimeOptions.includes('nightly') ? (
                            <div className={css.fieldTimeNight}>
                              <div>Select Night time</div>
                              <div className={css.fieldWrapper} key={item.value}>
                                <div className={css.formRow}>
                                  <div className={css.field}>
                                    <FieldSelect
                                      id={`${item.value}.startTime`}
                                      name={`${item.value}.startTime`}
                                      selectClassName={css.fieldSelect}
                                      onChange={e => console.log(e)}
                                      defaultValue={'00:00'}
                                    >
                                      <option disabled value="">
                                        {startTimePlaceholder}
                                      </option>
                                      {ALL_START_HOURS.map(s => (
                                        <option value={s} key={s}>
                                          {s}
                                        </option>
                                      ))}
                                    </FieldSelect>
                                  </div>
                                  <span className={css.dashBetweenTimes}>-</span>
                                  <div className={css.field}>
                                    <FieldSelect
                                      id={`${item.value}.endTime`}
                                      name={`${item.value}.endTime`}
                                      selectClassName={css.fieldSelect}
                                      defaultValue={'24:00'}
                                    >
                                      <option disabled value="">
                                        {endTimePlaceholder}
                                      </option>
                                      {ALL_END_HOURS.map(s => (
                                        <option value={s} key={s}>
                                          {s}
                                        </option>
                                      ))}
                                    </FieldSelect>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null} */}
                        </>
                      </div>
                    );
                  })}
                </div>
                <div className={css.submitButton}>
                  {updateListingError ? (
                    <p className={css.error}>
                      <FormattedMessage id="EditListingAvailabilityTimeOption.updateOptionFailed" />
                    </p>
                  ) : null}
                  {isNotNumber ? (
                    <p className={css.errorNotNumber}>
                      <FormattedMessage id="EditListingPricingForm.priceIsNumber" />
                    </p>
                  ) : null}
                  {priceBetween0and1 ? (
                    <p className={css.errorNotNumber}>
                      <FormattedMessage id="EditListingPricingForm.priceBetween0and1" />
                    </p>
                  ) : null}
                  <p className={css.commissionHint}>
                    <FormattedMessage id="EditListingAvailabilityTimeOption.commission" values={{ percent: '15' }} />
                  </p>
                  <Button
                    className={css.availabilityButton}
                    type="submit"
                    disabled={
                      selectedTimeOptions.length === 0 ||
                      isNotNumber ||
                      isOnFocus ||
                      priceBetween0and1
                    }
                  >
                    {isTimeOptionNotEmpty ? (
                      <FormattedMessage id="EditListingAvailabilityTimeOption.changeTimeOption" />
                    ) : (
                      <FormattedMessage id="EditListingAvailabilityTimeOption.saveTimeOption" />
                    )}
                  </Button>
                  {this.state.showSubmitMessage ? this.showHideMessage() : null}
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingAvailabilityTimeOptionComponent.defaultProps = {
  rootClassName: null,
  className: null,
  listingId: null,
  listing: null,
};

EditListingAvailabilityTimeOptionComponent.propTypes = {
  rootClassName: string,
  className: string,

  unitType: propTypes.bookingUnitType.isRequired,
  listingId: propTypes.uuid,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  // from injectIntl
  intl: intlShape.isRequired,
};

const EditListingAvailabilityTimeOption = compose(injectIntl)(
  EditListingAvailabilityTimeOptionComponent
);
EditListingAvailabilityTimeOption.displayName = 'EditListingAvailabilityTimeOption';

export default EditListingAvailabilityTimeOption;

import randomstring from 'randomstring';
import crypto from 'crypto';
import base64url from 'base64url';
import axios from "axios";

import { makeTapkeyRequest } from "../../util/api";

const tapkeyClientId = process.env.REACT_APP_TAPKEY_CLIENT_ID;
const tapkeyLoginBaseUrl = process.env.REACT_APP_TAPKEY_LOGIN_BASE_URL;

export const getOwnerContacts = async (userEmail, ownerId, tapkeyToken) => {
  try {
    const path = `/owners/${ownerId}/contacts?$filter=identifier eq '${userEmail}'`;
    const ownerContacts = await makeTapkeyRequest(path, "GET", {}, tapkeyToken);
    return ownerContacts.data;
  } catch(err) {
    console.log(err);
    throw new Error(err);
  }
}

export const createOwnerContact = async (userEmail, ownerId, tapkeyToken) => {
  try {
    const path = `/owners/${ownerId}/contacts`;
    const data = {
      ipId: "tapkey",
      identifier: userEmail,
    };
    const newContact = await makeTapkeyRequest(path, "PUT", data, tapkeyToken);
    return newContact.data;
  } catch(err) {
    console.log(err);
    throw new Error(err);
  }
}

export const deleteOwnerContact = async (ownerId, contactId, tapkeyToken) => {
  try {
    const path = `/owners/${ownerId}/contacts/${contactId}`;
    const data = {};
    const result = await makeTapkeyRequest(path, "DELETE", data, tapkeyToken);
    console.log(result)
  } catch(err) {
    console.log(err);
    throw new Error(err);
  }
}

export const connectWithTapkey = (currentUser) => {
  const currentUrl = window.location.href;

  const code_verifier = randomstring.generate(128);
  const base64Digest = crypto
    .createHash("sha256")
    .update(code_verifier)
    .digest("base64");
  const code_challenge = base64url.fromBase64(base64Digest);
  const state = base64url.fromBase64(randomstring.generate(64));

  const params = new URLSearchParams({
    client_id: tapkeyClientId,
    redirect_uri: currentUrl,
    code_challenge,
    state,
    response_type: 'code',
    code_challenge_method: 'S256',
    response_mode: 'query',
    scope: 'read:core:entities write:core:entities read:grants write:grants read:logs read:ttcp read:owneraccount:permissions read:owneraccounts read:ip:users offline_access',
  });

  const storeParams = {
    code_verifier,
    state,
    redirect_uri: currentUrl,
  };
  window.localStorage.setItem(`${currentUser.id.uuid}:parmas`, JSON.stringify(storeParams));

  const url = `${tapkeyLoginBaseUrl}/connect/authorize?${params.toString()}`;
  window.location.replace(url);
}

export const handleGetTapkeyAccessToken = async (currentUser) => {
  if (!currentUser) return null;

  const tapkeyParamsKey = `${currentUser.id.uuid}:parmas`;
  const tapkeyParams = JSON.parse(
    window.localStorage.getItem(tapkeyParamsKey) ?? "{}"
  );
  const queryParams = new URLSearchParams(window.location.search);
  const {
    redirect_uri,
    code_verifier,
    state,
  } = tapkeyParams;

  if (
    code_verifier &&
    state &&
    state === queryParams.get('state')
  ) {
      const headers = new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      });
      const data = new URLSearchParams({
        client_id: tapkeyClientId,
        redirect_uri,
        code_verifier,
        grant_type: 'authorization_code',
        code: queryParams.get('code'),
      });

      const requestOptions = {
        url: `${tapkeyLoginBaseUrl}/connect/token`,
        method: "POST",
        headers,
        data,
      };
      return axios(requestOptions)
        .then(response => {
          const accessToken = response.data;
          accessToken.currentDate = (new Date()).getTime();
          window.localStorage.removeItem(tapkeyParamsKey);
          return { accessToken, tapkeyParams };
        })
        .catch(error => {
          console.log(error);
          throw new Error(error);
        });
  }
  return null;
}

export const redirectToNativeApp = () => {
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const Android = /Android/.test(navigator.userAgent);
  if (iOS) {
    window.location.replace("tapkey://");

    setTimeout(() => {
      window.location.replace("https://apps.apple.com/us/app/tapkey/id1172177226");
    }, 10000);
  } else if (Android) {
    window.location.replace(`intent://tapkey.com/#Intent;scheme=https;package=net.tpky.mc;end`);
  } else {
    window.location.replace("https://my.tapkey.com/AdminUI/manage-locks");
  }
}

export const handleRevokeLock = async (params, tapkeyAccessToken) => {
  const { grantId, tapkeyOwnerId } = params;
  try {
    const path = `/owners/${tapkeyOwnerId}/grants/${grantId}/revoke?dryRun=false`;
    const res = await makeTapkeyRequest(path, "POST", {}, tapkeyAccessToken);
    return res;
  } catch(err) {
    console.log(err);
    throw new Error(err);
  }
}

export const refreshToken = async (tapkeyAccessToken) => {
  const tapkeyLoginBaseUrl = process.env.REACT_APP_TAPKEY_LOGIN_BASE_URL;
  const headers = new Headers({
    "Content-Type": "application/x-www-form-urlencoded",
  });
  const tapkeyClientId = process.env.REACT_APP_TAPKEY_CLIENT_ID;
  const data = new URLSearchParams({
    client_id: tapkeyClientId,
    grant_type: "refresh_token",
    refresh_token: tapkeyAccessToken.refresh_token,
    scope: 'read:core:entities write:core:entities read:grants write:grants read:logs read:ttcp read:owneraccount:permissions read:owneraccounts read:ip:users offline_access',
  });
  const requestOptions = {
    url: `${tapkeyLoginBaseUrl}/connect/token`,
    method: "POST",
    headers,
    data,
  };
  try {
    const newTapkeyToken = await axios(requestOptions);
    const accessToken = newTapkeyToken.data;
    accessToken.currentDate = (new Date()).getTime();
    return accessToken;
  } catch(err) {
    return err.response;
  }
}

const createDateStamp = (date) => {
  const dateFormat = new Date(
    (new Date(date)).getTime() - (3 * 24 * 60 * 60 * 1000)
  );

  return dateFormat.getFullYear() +
    ('0' + (dateFormat.getMonth() + 1)).slice(-2) +
    ('0' + dateFormat.getDate()).slice(-2) +
    'T' +
    ('0' + dateFormat.getHours()).slice(-2) +
    ('0' + dateFormat.getMinutes()).slice(-2) +
    ('0' + dateFormat.getSeconds()).slice(-2) + 'Z';
}

export const updateGrant = (grunt, timeStart, listingId) => {
  const parsed = grunt.timeRestrictionIcal.split('\n');
  const uid = `UID:${createDateStamp(timeStart)}${listingId}`;
  const startIndex = parsed.findIndex((item) => item === uid);

  if (startIndex > 0) {
    const endIndex =
      parsed.findIndex((item, i) => i > startIndex && item === 'END:VEVENT');

    return parsed.filter((_, i) => i < startIndex - 1 || i > endIndex);
  }

  return parsed;
}

import axios from 'axios';
import { storableError } from '../util/errors';
import * as log from '../util/log';

// ================ Action types ================ //

export const EMAIL_INVITE_REQUEST = 'app/EmailInvite/EMAIL_INVITE_REQUEST';
export const EMAIL_INVITE_SUCCESS = 'app/EmailInvite/EMAIL_INVITE_SUCCESS';
export const EMAIL_INVITE_ERROR = 'app/EmailInvite/EMAIL_INVITE_ERROR';

export const EMAIL_INVITE_RESET = 'app/EmailInvite/EMAIL_INVITE_RESET';

// ================ Environment ================ //

const API_URL = process.env.REACT_APP_API_URL;

// ================ Reducer ================ //

const initialState = {
  emailInviteSuccess: false,
  emailInviteError: null,
  emailInviteProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case EMAIL_INVITE_REQUEST:
      return {
        ...state,
        emailInviteProgress: true,
        emailInviteError: null,
        emailInviteSuccess: false,
      };
    case EMAIL_INVITE_SUCCESS:
      return { ...state, emailInviteProgress: false, emailInviteSuccess: true };
    case EMAIL_INVITE_ERROR:
      return { ...state, emailInviteProgress: false, emailInviteError: payload };
    case EMAIL_INVITE_RESET:
      return {
        ...state,
        emailInviteProgress: false,
        emailInviteError: false,
        emailInviteSuccess: false,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const emailInviteRequest = () => ({ type: EMAIL_INVITE_REQUEST });
export const emailInviteSuccess = () => ({ type: EMAIL_INVITE_SUCCESS });
export const emailInviteError = error => ({
  type: EMAIL_INVITE_ERROR,
  payload: error,
});
export const emailInviteReset = () => ({ type: EMAIL_INVITE_RESET });

// ================ Thunks ================ //

export const inviteMembers = data => async dispatch => {
  dispatch(emailInviteRequest());

  return axios
    .post(`${API_URL}/api/v1/users/invite`, data)
    .then(function(response) {
      if (response.status === 200) {
        dispatch(emailInviteError(null));
        dispatch(emailInviteSuccess());
        return true;
      } else {
        throw new Error('response is not ok');
      }
    })
    .catch(e => {
      log.error(storableError(e), 'error-to-send-email:');
      dispatch(emailInviteError(e));
      return false;
    });
};

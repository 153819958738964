import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ACCESS_PROVIDERS_MAP } from '../../constants/accessProviders';

import css from './TransactionPanel.css';

const TapkeyNote = ({ accessProvider }) => {
  return (
    <div className={css.tapkeyContainer}>
      <p className={css.tapkeyNote}>
        <FormattedMessage
          id="TransactionPanel.tapkeyNote"
          values={{
            link: (
              <a
                href={ACCESS_PROVIDERS_MAP[accessProvider]}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>
                  <FormattedMessage id={`ListingPage.${accessProvider}Link`} />
                </strong>
              </a>
            ),
            br: <br />,
          }}
        />
      </p>
    </div>
  );
};

export default TapkeyNote;

import { makeTapkeyRequest } from "../../util/api";


const REACT_APP_CANONICAL_ROOT_URL = process.env.REACT_APP_CANONICAL_ROOT_URL;

const formatDay = (date) => {
  const dateFormat = new Date(date);

  return dateFormat.getFullYear() +
    ('0' + (dateFormat.getMonth() + 1)).slice(-2) +
    ('0' + dateFormat.getDate()).slice(-2) +
    'T' +
    ('0' + dateFormat.getHours()).slice(-2) +
    ('0' + dateFormat.getMinutes()).slice(-2) +
    ('0' + dateFormat.getSeconds()).slice(-2);
}

const createDateStamp = (date) => {
  const dateFormat = new Date(
    (new Date(date)).getTime() - (3 * 24 * 60 * 60 * 1000)
  );

  return dateFormat.getFullYear() +
    ('0' + (dateFormat.getMonth() + 1)).slice(-2) +
    ('0' + dateFormat.getDate()).slice(-2) +
    'T' +
    ('0' + dateFormat.getHours()).slice(-2) +
    ('0' + dateFormat.getMinutes()).slice(-2) +
    ('0' + dateFormat.getSeconds()).slice(-2) + 'Z';
}

export const updateGrant = async (grunt, params, tapkeyToken) => {
  const {
    bookingStart,
    bookingEnd,
    lockId,
    tapkeyOwnerId,
    contact,
    listingId,
    timeZone,
  } = params;

  try {
    const start = formatDay(bookingStart);
    const end = formatDay(bookingEnd);
    const startStamp = createDateStamp(bookingStart);
    const path = `/owners/${tapkeyOwnerId}/grants/${grunt.id}`;

    const timeRestrictionIcal = grunt.timeRestrictionIcal;

    if (!timeRestrictionIcal) {
      const validFrom = grunt.validFrom;
      const validBefore = grunt.validBefore;

      let icalString = 'BEGIN:VCALENDAR\n';
      icalString += 'VERSION:2.0\n';
      icalString += `PRODID:${REACT_APP_CANONICAL_ROOT_URL}\n`;
      icalString += 'BEGIN:VEVENT\n';
      icalString += `UID:${createDateStamp(validFrom)}${listingId}\n`;
      icalString += `DTSTAMP:${createDateStamp(validFrom.slice(0, -1))}\n`;
      icalString += `DTSTART;TZID=${timeZone}:${formatDay(validFrom.slice(0, -1))}\n`;
      icalString += `DTEND;TZID=${timeZone}:${formatDay(validBefore.slice(0, -1))}\n`;
      icalString += 'END:VEVENT\n';
      icalString += 'BEGIN:VEVENT\n';
      icalString += `UID:${startStamp}${listingId}\n`;
      icalString += `DTSTAMP:${startStamp}\n`;
      icalString += `DTSTART;TZID=${timeZone}:${start}\n`;
      icalString += `DTEND;TZID=${timeZone}:${end}\n`;
      icalString += 'END:VEVENT\n';
      icalString += 'END:VCALENDAR';

      const data = {
        validFrom: null,
        validBefore: null,
        boundLockId: lockId,
        contactId: contact.id,
        timeRestrictionIcal: icalString,
      };

      return await makeTapkeyRequest(path, "PATCH", data, tapkeyToken);
    }

    const index = timeRestrictionIcal.split('\n').lastIndexOf('END:VEVENT');

    let icalString = 'BEGIN:VEVENT\n';
    icalString += `UID:${startStamp}${listingId}\n`;
    icalString += `DTSTAMP:${startStamp}\n`;
    icalString += `DTSTART;TZID=${timeZone}:${start}\n`;
    icalString += `DTEND;TZID=${timeZone}:${end}\n`;
    icalString += 'END:VEVENT\n';

    let newArrayIcal = timeRestrictionIcal.split('\n')
    newArrayIcal.splice(index+1, 0, ...icalString.split('\n').filter(Boolean));
    const newRestrictionIcal = newArrayIcal.join('\n');

    const data = {
      // boundLockId: lockId,
      // contactId: contact.id,
      timeRestrictionIcal: newRestrictionIcal,
    };

    return await makeTapkeyRequest(path, "PATCH", data, tapkeyToken);
  } catch(err) {
    console.log(err);
    return err.response;
  }
}


export const createGrant = async (params, tapkeyToken) => {
  const {
    bookingStart,
    bookingEnd,
    lockId,
    tapkeyOwnerId,
    contact,
    listingId,
    timeZone,
  } = params;
    const start = formatDay(bookingStart);
    const end = formatDay(bookingEnd);
    const startStamp = createDateStamp(bookingStart);

    let icalString = 'BEGIN:VCALENDAR\n';
    icalString += 'VERSION:2.0\n';
    icalString += `PRODID:${REACT_APP_CANONICAL_ROOT_URL}\n`;
    icalString += 'BEGIN:VEVENT\n';
    icalString += `UID:${startStamp}${listingId}\n`;
    icalString += `DTSTAMP:${startStamp}\n`;
    icalString += `DTSTART;TZID=${timeZone}:${start}\n`;
    icalString += `DTEND;TZID=${timeZone}:${end}\n`;
    icalString += 'END:VEVENT\n';
    icalString += 'END:VCALENDAR';

    try {
        const data = {
          boundLockId: lockId,
          contactId: contact.id,
          timeRestrictionIcal: icalString,
        };
        const path = `/owners/${tapkeyOwnerId}/grants`;

        return await makeTapkeyRequest(path, "PUT", data, tapkeyToken);
    } catch(err) {
        console.log(err);
        return err.response;
    }
}

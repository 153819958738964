import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ensureOwnListing } from '../../util/data';
import { ListingLink, ModalDeleteUser, ModalSuccessSendInvite } from '../../components';
import { EditListingMembersForm } from '../../forms';
import config from '../../config.js';

import {
  getOwnerContacts,
  createOwnerContact,
  deleteOwnerContact,
} from '../../containers/TapkeyPage/TapkeyPage.helpers';
import { updateUserTapkeyToken } from '../../ducks/Tapkey.duck';

import css from './EditListingMembersPanel.css';

const EditListingMembersPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    onChange,
    emailInviteToListingProgress,
    onInviteMembersToListing,
    emailInviteToListingSuccess,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    // onGetAllUsers,
    onGetInvites,
    onDeleteInvite,
    editPage,
    intl,
    onManageDisableScrolling,
    onEmailInviteToListingReset,
    stripeConnected,
    isPastDraft,
    currentUser,
  } = props;

  const dispatch = useDispatch();
  const [inviteError, setInviteError] = useState(false);
  const [existEmail, setExistEmail] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setSuccessShowModal] = useState(false);
  const [member, setMember] = useState();

  useEffect(() => {
    if (listing.id) onGetInvites(listing.id.uuid);
  }, [onGetInvites, listing.id]);

  // useEffect(() => {
  //   onGetAllUsers();
  // }, [onGetAllUsers]);

  // useEffect(() => {
  //   if (editPage.allUsers) {
  //     refreshMembers(editPage.allUsers);
  //   }
  //   // eslint-disable-next-line
  // }, [editPage.allUsers]);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const isPriceNull = publicData;

  const panelTitle = currentListing.id ? (
    <FormattedMessage
      id="EditListingMembersPanel.title"
      values={{
        listingTitle: <ListingLink listing={listing} />,
      }}
    />
  ) : (
    <FormattedMessage id="EditListingMembersPanel.createListingTitle" />
  );

  // function refreshMembers(users) {
  //   const listMembers = publicData.members;

  //   if (listMembers) {
  //     listMembers.forEach((member, indexMember) => {
  //       if (users.length > 0) {
  //         users.forEach((user, index) => {
  //           if (member.email === user.attributes.email) {
  //             const emailUser = user.attributes.email;
  //             const idUser = user.id.uuid;
  //             const fullName = `${user.attributes.profile.firstName} ${user.attributes.profile.lastName}`;

  //             listMembers[indexMember] = {
  //               email: emailUser,
  //               id: idUser,
  //               fullName: fullName,
  //             };

  //             const updateMembers = {
  //               publicData: {
  //                 members: listMembers,
  //               },
  //             };

  //             onSubmit(updateMembers);
  //           }
  //         });
  //       }
  //     });
  //   }
  // }

  const submitNewUser = async values => {
    setInviteError(false);
    setExistEmail(false);
    const { newMember, message } = values;
    // const maybeMembers = editPage.allInvites;

    if (!newMember) return;

    const maybeMembers = publicData.members;
    const allMembers = publicData.membersForSearch || [];
    const tapkeyOwnerContacts = publicData.tapkeyOwnerContacts ?? [];
    const tapkeyOwnerId = currentUser.attributes.profile.publicData.tapkeyOwnerId;
    const emailCurrentUser = currentUser && currentUser.attributes.email;
    let updateValues = maybeMembers;

    if (!allMembers.includes(emailCurrentUser)) {
      allMembers.unshift(emailCurrentUser);
    }

    if (maybeMembers && maybeMembers.find(element => element.email === newMember)) {
      setExistEmail(true);
    } else {
      if (maybeMembers) {
        updateValues = {
          publicData: {
            members: [...maybeMembers, { email: newMember, id: '', fullName: '' }],
            membersForSearch:
              newMember === emailCurrentUser ? [...allMembers] : [...allMembers, newMember],
          },
        };
      } else {
        updateValues = {
          publicData: {
            members: [{ email: newMember, id: '', fullName: '' }],
            membersForSearch:
              newMember === emailCurrentUser ? [...allMembers] : [...allMembers, newMember],
          },
        };
      }

      const pathname = window.location.pathname;
      const arr = pathname.split('/');
      let linkToListing = '';
      for (let i = 1; i < arr.length - 2; i++) {
        linkToListing = linkToListing + '/' + arr[i];
      }

      const data = {
        email: newMember,
        listingLink: linkToListing,
        firstName: currentUser.attributes.profile.firstName,
        lastName: currentUser.attributes.profile.lastName,
        listingTitle: listing.attributes.title,
        id: listing.id.uuid, // Listing id
        listingImage:
          listing.images[0] && listing.images[0].attributes.variants['landscape-crop'].url,
        message: message,
      };

      const emailInviteMembersToListing = await onInviteMembersToListing(data);

      if (emailInviteMembersToListing) {
        setInviteError(false);

        let tapkeyAccessToken = currentUser?.attributes?.profile?.privateData?.tapkeyToken ?? null;
        if (tapkeyAccessToken)
          tapkeyAccessToken = await updateUserTapkeyToken(dispatch, tapkeyAccessToken);

        const isOldContact = tapkeyOwnerContacts.find(contact => contact.email === newMember);

        if (tapkeyAccessToken && !isOldContact) {
          const ownerContacts = await getOwnerContacts(newMember, tapkeyOwnerId, tapkeyAccessToken);

          let newTapkeyOwnerContact = [];
          if (ownerContacts.length) {
            newTapkeyOwnerContact = [
              ...tapkeyOwnerContacts,
              { email: newMember, id: ownerContacts[0].id },
            ];
          } else {
            const newContact = await createOwnerContact(
              newMember,
              tapkeyOwnerId,
              tapkeyAccessToken
            );
            if (newContact) {
              newTapkeyOwnerContact = [
                ...tapkeyOwnerContacts,
                { email: newMember, id: newContact.id },
              ];
            }
          }

          updateValues.publicData.tapkeyOwnerContacts = newTapkeyOwnerContact;
        }
        onSubmit(updateValues);
      } else {
        setInviteError(true);
      }
    }
  };

  const deleteUser = data => {
    const { members, membersForSearch } = publicData;
    const maybeMembers = members || [];
    const maybeAllMembers = membersForSearch || [];
    const tapkeyOwnerContacts = publicData.tapkeyOwnerContacts ?? [];
    const emailCurrentUser = currentUser && currentUser.attributes.email;
    let updateValues;
    if (maybeMembers) {
      // if (data.type === 'id') {
      //   updateValues = {
      //     publicData: {
      //       members: maybeMembers.filter(user => {
      //         return user.id !== data.id;
      //       }),
      //       membersForSearch: maybeAllMembers.filter(user => {
      //         if (emailCurrentUser === data.email) {
      //           return true;
      //         } else {
      //           return user !== data.email;
      //         }
      //       }),
      //     },
      //   };
      // } else if (data.type === 'email')
      // {
      updateValues = {
        publicData: {
          members: maybeMembers.filter(user => {
            return user.email !== data.email;
          }),
          membersForSearch: maybeAllMembers.filter(user => {
            if (emailCurrentUser === data.email) {
              return true;
            } else {
              return user !== data.email;
            }
          }),
          tapkeyOwnerContacts: tapkeyOwnerContacts.filter(contact => contact.email !== data.email),
        },
      };
      // }
    }

    let requestData = {
      listingFlexId: listing.id.uuid,
    };
    data.type === 'id' ? (requestData.userId = data.id) : (requestData.email = data.email);

    const tapkeyOwnerId = currentUser.attributes.profile.publicData.tapkeyOwnerId;
    const contact = tapkeyOwnerContacts.find(contact => contact.email === data.email);
    const tapkeyAccessToken = currentUser?.attributes?.profile?.privateData?.tapkeyToken ?? null;

    if (tapkeyOwnerId && contact && tapkeyAccessToken) {
      updateUserTapkeyToken(dispatch, tapkeyAccessToken)
        .then(token => deleteOwnerContact(tapkeyOwnerId, contact.id, token))
        .catch(err => console.log(err));
    }

    setExistEmail(false);
    setInviteError(false);
    onSubmit(updateValues);
    onDeleteInvite(requestData);
    setShowModal(false);
  };

  const handleClickDelete = member => {
    setShowModal(true);
    setInviteError(false);
    setMember(member);
  };

  const closeModal = () => {
    setShowModal(false);
    setInviteError(false);
  };

  const closeModalSuccess = () => {
    setSuccessShowModal(false);
    onEmailInviteToListingReset();
  };

  const autoClose = () => {
    setTimeout(() => {
      closeModalSuccess();
      onEmailInviteToListingReset();
    }, 2000);
  };

  useEffect(() => {
    setSuccessShowModal(emailInviteToListingSuccess);
    emailInviteToListingSuccess && autoClose();
    // eslint-disable-next-line
  }, [emailInviteToListingSuccess]);

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingMembersForm
        className={css.form}
        initialValues={{ members: publicData.members }}
        onSubmit={submitNewUser}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateError={errors.updateListingError}
        updateInProgress={updateInProgress}
        membersOptions={config.custom.membersOptions}
        inviteError={inviteError}
        emailInviteToListingProgress={emailInviteToListingProgress}
        existEmail={existEmail}
        stripeConnected={stripeConnected}
        isPastDraft={isPastDraft}
        isPriceNull={isPriceNull}
      />
      <ModalDeleteUser
        id="memberDelete"
        isOpen={showModal}
        onClose={closeModal}
        onManageDisableScrolling={onManageDisableScrolling}
        intl={intl}
        emailInviteError={inviteError}
        onDelete={() =>
          deleteUser(
            member.id
              ? { type: 'id', id: member.id, email: member.email }
              : { type: 'email', email: member.email }
          )
        }
      />

      <ModalSuccessSendInvite
        id="successSendInvite"
        isOpen={showSuccessModal}
        onClose={closeModalSuccess}
        onManageDisableScrolling={onManageDisableScrolling}
      />
      <ul className={css.membersListContainer}>
        {editPage.allInvites.length > 0
          ? editPage.allInvites.sort().map((member, index) => {
              return (
                <li className={css.memberFieldContainer} key={member.email}>
                  <div className={css.memberTextContainer}>
                    <div className={css.memberIndex}>{index + 1}.</div>
                    {member.name ? (
                      <div className={css.memberText}>{member.name}</div>
                    ) : (
                      <div className={css.memberText}>{member.email}</div>
                    )}
                  </div>
                  <button className={css.memberButton} onClick={() => handleClickDelete(member)}>
                    X
                  </button>
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingMembersPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingMembersPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  emailInviteToListingProgress: bool,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  onInviteMembersToListing: func,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingMembersPanel;

import { createFlinkeyCar, uninstallFlinkeyCarFromBox } from '.././../util/api';

export const createCar = async (userId, carName, flinkeyCar) => {
  try {
    const response = await createFlinkeyCar({
      userId,
      carName,
      flinkeyCar,
    });
    return response;
  } catch (err) {
    console.log(err);
    return { error: err.response || err.message };
  }
};

export const uninstallCarFromBox = async (userId, carId, products) => {
  try {
    const response = await uninstallFlinkeyCarFromBox({
      userId,
      carId,
      products,
    });
    return response;
  } catch (err) {
    console.log(err);
    return { error: err.response || err.message };
  }
};

export const redirectToNativeApp = () => {
  const flinkeyDeveloperPortalUrl = process.env.REACT_APP_FLINKEY_DEVELOPER_PORTAL_URL;
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const Android = /Android/.test(navigator.userAgent);
  if (iOS) {
    window.location.replace('flinkey://');

    setTimeout(() => {
      window.location.replace('https://apps.apple.com/us/app/flinkey/id1435609002');
    }, 10000);
  } else if (Android) {
    window.location.replace(
      `intent://flinkey.com/#Intent;scheme=https;package=digital.witte.flky.mobile.droid.app.prod;end`
    );
  } else {
    window.location.replace(flinkeyDeveloperPortalUrl);
  }
};

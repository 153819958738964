import React from 'react';
//import config from '../../config';
//import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
//  NamedLink,
//  ExternalLink,
} from '../../components';

import css from './TipPage.css';


const TipPage = () => {
  //const { siteTwitterHandle, siteFacebookPage } = config;
  //const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      title="F.A.Q."
      schema={{
        '@context': 'http://schema.org',
        '@type': 'FAQPage',
        description: 'FAQ SharePar',
        name: 'FAQ page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="TipPage" />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>Tipps für Nutzer*innen</h1>

          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <div className={css.row}>
                <div className={css.frage}><h3>1.	Fang mit etwas Leichtem an</h3></div>
                <div className={css.antwort}>
                  <ul>
                    <p>Für den Anfang empfehlen wir dir, ein einfach zu handhabendes Objekt auszusuchen und es gemeinsam mit einer anderen oder wenigen anderen Personen zu nutzen. Das könnte ein einfaches Fahrrad sein, ein Raum, etwas, das leicht ersetzbar ist, nicht so schnell kaputtgeht und einfach zu bedienen ist. Hier ist das Risiko, dass etwas schiefgeht also gering. Und, wenn etwas schief gehen sollte, sollte es nicht tragisch sein. Man kann es jederzeit wieder reparieren. Und weitermachen.</p>
                  </ul>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>2.	Preisgestaltung</h3></div>
                <div className={css.antwort}>
                    <p>Wähle zunächst einen 0 Euro Preis, dann brauchst du und deine Mitnutzer*innen keine Kontodaten einzugeben.
                    Du kannst den Preis später, wenn du mit der App. vertraut bist, immer noch aktualisieren.
                    Wenn du einen echten Preis in Euro wählst, denke daran, dass du und deine Mitnutzer*innen Kontodaten angeben müssen,
                    zum Bezahlen und zum Empfangen von Geld. Denk auch daran, dass von deinem Preis ein kleiner Teil für Gebühren abgezogen wird.
                    Von zum Beispiel 10,00 Euro, die deine Nutzer*innen bezahlen sollen, erhältst du selbst 8,50 Euro direkt auf dein Konto überwiesen
                    (das sind 85% des Preises, denn 15% erhalten wir als Gebühr).</p>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>3.	Suche die passenden Mitnutzer</h3></div>
                <div className={css.antwort}>
                  <p>Wenn du ein Objekt gefunden hast, das du mit anderen Menschen teilen möchtest, achte darauf,
                  dass alles drei zusammenpasst: du, dein/e Mitnutzer*innen und auch das Objekt.
                  Schließlich soll das Teilen auch Spaß machen und keine Probleme verursachen.
                  Baue dir nach und nach ein gutes und freundliches Umfeld auf.</p>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>4.	Verfasse klare Regeln</h3></div>
                <div className={css.antwort}>
                  <p>Die sogenannte <i>Objektbeschreibung</i> innerhalb der App. ist dazu da, dass du deinen Mitnutzer*innen genau Auskunft darüber gibst,
                  was sie erwarten dürfen und was du von ihnen erwartest. Schreibe dort deshalb alles genau auf. Wenn es Unstimmigkeiten gibt,
                  ändere sogleich die Beschreibung entsprechend, damit das Teilen beim nächsten Mal besser funktioniert.
                  Passe es an, bis alles glatt läuft und alles klar verständlich vereinbart ist.</p>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>5.	Seid freundlich zueinander und nehmt Rücksicht aufeinander</h3></div>
                <div className={css.antwort}>
                  <p>Es sind deine <i>Freunde</i> und <i>Menschen</i>, denen <i>du vertraust</i>. Und ihr wollt Freunde <i>bleiben</i>.
                  Niemand ist perfekt und man sollte das auch nicht erwarten. Doch achtet auch auf eure gegenseitigen Bedürfnisse.
                  Gebt die Dinge, die ihr nutzt, sauber, in gutem Zustand und auch pünktlich wieder zurück.
                  Haltet euch an die Vereinbarungen, die ihr getroffen habt. Dabei hilft auch die Objektbeschreibung,
                  denn dort sollte alles klar und deutlich niedergeschrieben sein.</p>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>6.	Steigere dich langsam</h3></div>
                <div className={css.antwort}>
                  <p>Wenn du mit der Sharepar App. gut klarkommst und ein oder zwei Objekte regelmäßig mit anderen nutzt oder teilst,
                  magst du vielleicht auch in mehr Gruppen Mitglied werden und mehrere Dinge nutzen oder anbieten.
                  Lass dabei Qualität vor Quantität gelten und denke vor allem an die Lebensqualität, die dir das Teilen bietet.</p>

                  <p>Auch beim Teilen kann es ein „Zuviel“ geben, nämlich dann, wenn du den Überblick verlierst oder es mit Menschen zu tun bekommst,
                  denen du nicht vertrauen magst oder kannst. Dann bist du vielleicht einen Schritt zu weit vorausgeeilt.
                  Umgib dich mit Menschen, denen du vertraust und die dir vertrauen und halte es weiterhin so. Dann sollte alles gut gehen.</p>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>7.	Halte es überschaubar</h3></div>
                <div className={css.antwort}>
                  <p>Wie viele Objekte soll man durch Sharepar mit anderen teilen und nutzen? Darauf gibt es allgemeingültige Antwort.
                  Es ist einerseits Geschmackssache, andererseits auch eine Frage des Bedarfs.
                  Ein durchschnittlicher Haushalt beherbergt etwa 10.000 Dinge sagt man.
                  Doch darüber haben wir sicher schon längst den Überblick verloren.
                  Vielleicht fängst du mit fünf Objekten an und steigerst dich im Laufe der Zeit auf 20 oder 30 Objekte.
                  Probiere es aus und spüre in dich hinein, ob du dich damit wohlfühlst. Das ist doch vielleicht das Wichtigste: sich wohlfühlen, mit dem, was man tut.</p>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>8.	Lebe den Spirit</h3></div>
                <div className={css.antwort}>
                  <p>Sharing ist für viele Menschen etwas sehr Natürliches. In Wahrheit ist es ein Teil der Menschheitsgeschichte,
                  in der schon sehr früh begonnen wurde, zu kooperieren.
                  Individueller Besitz entstand erst sehr viel später. Wir sind bei Sharepar davon überzeugt,
                  dass das gemeinsame und verantwortungsbewusste Nutzen von Ressourcen jeglicher Art und auch das „sich gemeinsam um etwas kümmern“
                  in Zukunft wieder deutlich zunehmen wird.
                  Dabei können digitale Werkzeuge wie Sharepar durchaus behilflich sein, gerade wenn es so flexibel gehandhabt werden kann: ohne Geld,
                  mit wenig Geld und auch als Einnahmequelle, wenn man es braucht.</p>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>9.	Werde mutiger</h3></div>
                <div className={css.antwort}>
                  <p>Vielleicht wirst du schon bald denken: warum nicht auch Dinge gemeinsam mit anderen anschaffen?
                  Eigentumsgemeinschaften können sich bilden. Bei Häusern gibt es das ja schon lange. Auch Genossenschaften können Vorbilder sein.
                  Überlege doch mal, was es sein könnte. Überlege, mit wem es gehen könnte. Frage andere, frage Freunde. Vielleicht entsteht dabei etwas ganz Neues?
                  Ein Projekt? Ein Garten? Eine Siedlung? Was auch immer es sein mag, es könnte interessant werden.</p>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>10.	Trage es in die Welt</h3></div>
                <div className={css.antwort}>
                  <p>Wenn dir Sharepar gefällt, wenn dir das Teilen gefällt, wenn dir das Leben in Kooperation mit anderen gefällt, dann trag es weiter.
                  Erzähle davon. Lade andere zu Sharepar ein. Lass` sie teilhaben an dieser anderen Art, mit Dingen und Besitz umzugehen. Du bist auf einem guten Weg.
                  Du bist auf dem Weg in die Zukunft. Und die heißt nicht „Besitz“, sondern „Zugang“!</p>
                  <p><b><i>„Be smart, be Sharepar!“</i></b></p>
                  <p>Herzlich willkommen.</p>
                </div>
              </div>

            </div>

          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default TipPage;

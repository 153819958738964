import pick from 'lodash/pick';
import { storableError } from '../../util/errors';
import { resetToStartOfDay } from '../../util/dates';
import {
  addMarketplaceEntities,
} from '../../ducks/marketplaceData.duck';
import {fetchCurrentUser} from '../../ducks/user.duck';



// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/ExceptionPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ExceptionPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ExceptionPage/SHOW_LISTING_ERROR';

export const DELETE_EXCEPTION_REQUEST = 'app/ExceptionPage/DELETE_AVAILABILITY_EXCEPTION_REQUEST';
export const DELETE_EXCEPTION_SUCCESS = 'app/ExceptionPage/DELETE_AVAILABILITY_EXCEPTION_SUCCESS';
export const DELETE_EXCEPTION_ERROR = 'app/ExceptionPage/DELETE_AVAILABILITY_EXCEPTION_ERROR';

export const AVAILABILITY_EXCEPTION_REQUEST = 'app/ExceptionPage/AVAILABILITY_EXCEPTION_REQUEST';
export const AVAILABILITY_EXCEPTION_SUCCESS = 'app/ExceptionPage/AVAILABILITY_EXCEPTION_SUCCESS';
export const AVAILABILITY_EXCEPTION_ERROR = 'app/ExceptionPage/AVAILABILITY_EXCEPTION_ERROR';

// ================ Reducer ================ //

const initialState = {
  listingId: null,
  showListingError: null,
  deleteExceptionSuccess: null,
  deleteExceptionError: null,
  deleteExceptionInProgress: false,
  availabilityException: null,
  availabilityExceptionError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, listingId: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case DELETE_EXCEPTION_REQUEST:
      return {
        ...state,
        deleteExceptionError: null,
        deleteExceptionSuccess: null,
        deleteExceptionInProgress: true,
      };

    case DELETE_EXCEPTION_SUCCESS: {
      return {
        ...state,
        deleteExceptionSuccess: true,
        deleteExceptionInProgress: false,
      };
    }
    case DELETE_EXCEPTION_ERROR:
      return {
        ...state,
        deleteExceptionError: payload.error,
        deleteExceptionSuccess: false,
        deleteExceptionInProgress: false,
      };

    case AVAILABILITY_EXCEPTION_REQUEST:
      return { ...state, availabilityExceptionError: null };
    case AVAILABILITY_EXCEPTION_SUCCESS:
      return { ...state, availabilityException: payload };
    case AVAILABILITY_EXCEPTION_ERROR:
      return { ...state, availabilityExceptionError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({type: SET_INITAL_VALUES, payload: pick(initialValues, Object.keys(initialState))});
export const showListingRequest = id => ({type: SHOW_LISTING_REQUEST, payload: { id }});
export const showListingError = e => ({type: SHOW_LISTING_ERROR, error: true, payload: e});

const requestAction = actionType => params => ({ type: actionType, payload: { params } });
const successAction = actionType => result => ({ type: actionType, payload: result.data });
const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

const availabilityExceptionRequest = () => ({
  type: AVAILABILITY_EXCEPTION_REQUEST,
});
const availabilityExceptionSuccess = (payload) => ({
  type: AVAILABILITY_EXCEPTION_SUCCESS,
  payload,
});
const availabilityExceptionError = (e) => ({
  type: AVAILABILITY_EXCEPTION_ERROR,
  error: true,
  payload: e,
});

// SDK method: availabilityExceptions.delete
export const deleteAvailabilityExceptionRequest = requestAction(DELETE_EXCEPTION_REQUEST);
export const deleteAvailabilityExceptionSuccess = successAction(DELETE_EXCEPTION_SUCCESS);
export const deleteAvailabilityExceptionError = errorAction(DELETE_EXCEPTION_ERROR);


// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.landscape-crop4x',
      'variants.landscape-crop6x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  };

  const show = sdk.ownListings.show(params);

  return show
    .then(data => {
      dispatch(addMarketplaceEntities(data));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const requestDeleteAvailabilityException = params => (dispatch, getState, sdk) => {
  dispatch(deleteAvailabilityExceptionRequest(params));

  return sdk.availabilityExceptions
    .delete(params, { expand: true })
    .then(response => {
      const availabilityException = response.data.data;
      return dispatch(deleteAvailabilityExceptionSuccess({ data: availabilityException }));
    })
    .catch(e => {
      dispatch(deleteAvailabilityExceptionError({ error: storableError(e) }));
      throw e;
    });
};

const requestAvailabilityException = (listingId) => async (dispatch, getState, sdk) => {
  dispatch(availabilityExceptionRequest());

  try {
    const listing = await sdk.ownListings.show({ id: listingId });
    const timezone = listing.data.data.attributes.availabilityPlan.timezone;

    const today = new Date();
    const start = resetToStartOfDay(today, timezone, 0);
    const exceptionRange = 364;
    const end = resetToStartOfDay(today, timezone, exceptionRange);

    const params = {
      listingId,
      start,
      end,
    };
    const availabilityExceptions = await sdk.availabilityExceptions.query(params)

    dispatch(availabilityExceptionSuccess(availabilityExceptions.data.data));
  } catch(e) {
    dispatch(availabilityExceptionError(storableError(e)));
  }
}

export const loadData = params => (dispatch, getState) => {
  const listingId = params.id;

  return Promise.all([
    dispatch(showListing(listingId)),
    dispatch(requestAvailabilityException(listingId)),
  ]);
};


import React from 'react';
import { compose } from 'redux';
import { func, array, string, shape, bool } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';

import { injectIntl } from '../../util/reactIntl';

import { Form, FieldSelect } from '../../components';
import { Button } from '../../components';

import css from './EditListingLocksForm.css';

const TapkeyAccessProviderForm = props => {
  const {
    intl,
    tapkeyLocks,
    lock,
    updateInProgress,
    getTokenError,
    getLocksError,
    /* actions */
    onSubmit,
  } = props;
  const handleSaveAccessSubmit = event => {
    const selLock = tapkeyLocks.find(lock => lock.id === event.lock_id);
    onSubmit({
      lock: { id: selLock.id, title: selLock.title },
    });
  };

  const locksLabel = intl.formatMessage({
    id: 'TapkeyAccessProviderForm.locksLabel',
  });

  const saveAccessButtonText = intl.formatMessage({
    id: 'TapkeyAccessProviderForm.saveAccessButtonText',
  });

  return (
    <FinalForm
      onSubmit={handleSaveAccessSubmit}
      render={formRenderProps => {
        const {
          handleSubmit,
        } = formRenderProps;

        return (
          <Form onSubmit={handleSubmit}>
            <div className={css.error}>
              {getTokenError?.message}
              {getLocksError?.message}
            </div>
            <div className={css.row}>
              <span className={css.label}>{locksLabel}</span>
              <FieldSelect
                name='lock_id'
                defaultValue={lock && lock.id}
              >
                <option key='none' value='none'>none</option>
                {tapkeyLocks.map(lockItem => (
                  <option
                    key={lockItem.id}
                    value={lockItem.id}
                  >
                    {lockItem.title}
                  </option>
                ))}
              </FieldSelect>
            </div>
            <div className={css.row}>
              <Button
                className={css.submitButton}
                inProgress={updateInProgress}
                disabled={updateInProgress}
                type='submit'
              >
                {saveAccessButtonText}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

TapkeyAccessProviderForm.defaultProps = {};

TapkeyAccessProviderForm.propTypes = {
  tapkeyLocks: array.isRequired,
  clientId: string,
  lock: shape({
    id: string.isRequired,
    title: string.isRequired,
  }),
  getTokenError: string,
  getLocksError: string,
  getLocksPending: bool,
  /* actions */
  onSubmit: func.isRequired,
};

export default compose(injectIntl)(TapkeyAccessProviderForm);

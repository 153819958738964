import axios from 'axios';
import { storableError } from '../util/errors';
import { getAllInvitesSuccess } from '../containers/EditListingPage/EditListingPage.duck';
import * as log from '../util/log';

// ================ Action types ================ //

export const EMAIL_INVITE_TO_LISTING_REQUEST = 'app/EmailInviteToListing/EMAIL_INVITE_TO_LISTING_REQUEST';
export const EMAIL_INVITE_TO_LISTING_SUCCESS = 'app/EmailInviteToListing/EMAIL_INVITE_TO_LISTING_SUCCESS';
export const EMAIL_INVITE_TO_LISTING_ERROR = 'app/EmailInviteToListing/EMAIL_INVITE_TO_LISTING_ERROR';

export const EMAIL_INVITE_TO_LISTING_RESET = 'app/EmailInviteToListing/EMAIL_INVITE_TO_LISTING_RESET';

// ================ Environment ================ //

const API_URL = process.env.REACT_APP_API_URL;

// ================ Reducer ================ //

const initialState = {
    emailInviteToListingSuccess: false,
    emailInviteToListingError: null,
    emailInviteToListingProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case EMAIL_INVITE_TO_LISTING_REQUEST:
      return {
        ...state,
        emailInviteToListingProgress: true,
        emailInviteToListingError: null,
        emailInviteToListingSuccess: false,
      };
    case EMAIL_INVITE_TO_LISTING_SUCCESS:
      return { ...state, emailInviteToListingProgress: false, emailInviteToListingSuccess: true };
    case EMAIL_INVITE_TO_LISTING_ERROR:
      return { ...state, emailInviteToListingProgress: false, emailInviteToListingError: payload };
    case EMAIL_INVITE_TO_LISTING_RESET:
      return {
        ...state,
        emailInviteToListingProgress: false,
        emailInviteToListingError: false,
        emailInviteToListingSuccess: false,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const emailInviteToListingRequest = () => ({ type: EMAIL_INVITE_TO_LISTING_REQUEST });
export const emailInviteToListingSuccess = () => ({ type: EMAIL_INVITE_TO_LISTING_SUCCESS });
export const emailInviteToListingError = error => ({
  type: EMAIL_INVITE_TO_LISTING_ERROR,
  payload: error,
});
export const emailInviteToListingReset = () => ({ type: EMAIL_INVITE_TO_LISTING_RESET });

// ================ Thunks ================ //

export const inviteMembersToListing = data => async dispatch => {

    dispatch(emailInviteToListingRequest());

    return axios.post(`${API_URL}/api/v1/listings/listing_invite`, data)
    .then(function (response) {
      if (response.status === 200) {
        dispatch(getAllInvitesSuccess(response.data))
        dispatch(emailInviteToListingError(null));
        dispatch(emailInviteToListingSuccess());
        return true;
      } else {
        throw new Error('response is not ok');
      }
    })
    .catch(e => {
      console.log(e)
      log.error(storableError(e), 'error-to-send-email:');
      dispatch(emailInviteToListingError(e));
      return false
    });
  }

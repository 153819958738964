import React, { useEffect, useState } from 'react';
import { arrayOf, array, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureListing, ensureTransaction } from '../../util/data';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import { txIsPaymentPending } from '../../util/transaction';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  NamedRedirect,
  TransactionPanel,
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import {
  acceptSale,
  declineSale,
  loadData,
  setInitialValues,
  sendMessage,
  sendReview,
  fetchMoreMessages,
  fetchTimeSlots,
  getGrunt,
  revokeGrunt,
  getAssignments,
  deleteAssignments,
  cancelBooking,
  sendCancelMessage,
} from './TransactionPage.duck';
import {
  setTimeoption,
  fetchTransactionLineItems,
} from '../../containers/ListingPage/ListingPage.duck';
import { fetchMetadataChangeRequest } from '../CheckoutPage/CheckoutPage.duck';

import css from './TransactionPage.css';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const TransactionPageComponent = props => {
  const {
    currentUser,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    fetchMessagesError,
    fetchMessagesInProgress,
    totalMessagePages,
    oldestMessagePageFetched,
    fetchTransactionError,
    history,
    intl,
    messages,
    onFetchTimeSlots,
    onManageDisableScrolling,
    onSendMessage,
    onSendReview,
    onShowMoreMessages,
    params,
    scrollingDisabled,
    sendMessageError,
    sendMessageInProgress,
    sendReviewError,
    sendReviewInProgress,
    transaction,
    transactionRole,
    acceptInProgress,
    acceptSaleError,
    declineInProgress,
    declineSaleError,
    onAcceptSale,
    onDeclineSale,
    monthlyTimeSlots,
    processTransitions,
    callSetInitialValues,
    onInitializeCardPaymentData,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    onSetTimeoption,
    userSelectedTimeOption,
    onFetchTransactionLineItems,
    lineItemsListingPage,
    onFetchMetadataChangeRequest,
    location,
    onGetGrunt,
    grunt,
    gruntError,
    onRevokeGrunt,
    onGetAssignments,
    flinkeyAssignment,
    flinkeyAssignmentDeleted,
    onDeleteAssignments,
    onCancelBooking,
    onSendCancelMessage,
  } = props;
  const isContactMessageSend = location && location.search.includes('modal');
  const isCreateTransaction = location && location.search.includes('createTransaction');

  const currentTransaction = ensureTransaction(transaction);
  const currentListing = ensureListing(currentTransaction.listing);
  const isProviderRole = transactionRole === PROVIDER;
  const isCustomerRole = transactionRole === CUSTOMER;
  const userId = currentUser && currentUser.id;

  const [isRequestProviderSend, setIsRequestProviderSend] = useState(false);
  const [isRequesCustomertSend, setIsRequestCustomerSend] = useState(false);

  useEffect(() => {
    if (currentTransaction && currentTransaction.attributes.metadata && transactionRole) {
      const grantId = currentTransaction.attributes.metadata.grantId;
      const flinkeyAssignment = currentTransaction.attributes.metadata.flinkeyAssignment;
      if (
        transactionRole === PROVIDER &&
        currentTransaction.attributes.metadata.isOpenTransactionProvider === false &&
        !isRequestProviderSend
      ) {
        onFetchMetadataChangeRequest({
          transactionId: currentTransaction.id,
          isOpenTransactionProvider: true,
          isOpenTransactionCustomer:
            currentTransaction.attributes.metadata.isOpenTransactionCustomer,
          authorId: userId,
          operationType: 'decrement',
          grantId,
          flinkeyAssignment,
        });
        setIsRequestProviderSend(true);
      } else if (
        transactionRole === CUSTOMER &&
        currentTransaction.attributes.metadata.isOpenTransactionCustomer === false &&
        !isRequesCustomertSend
      ) {
        // setTimeout(() => {
          isContactMessageSend || isCreateTransaction
            ? onFetchMetadataChangeRequest({
                transactionId: currentTransaction.id,
                isOpenTransactionProvider:
                  currentTransaction.attributes.metadata.isOpenTransactionProvider,
                isOpenTransactionCustomer: true,
                grantId,
                flinkeyAssignment
              })
            : onFetchMetadataChangeRequest({
                transactionId: currentTransaction.id,
                isOpenTransactionProvider:
                  currentTransaction.attributes.metadata.isOpenTransactionProvider,
                isOpenTransactionCustomer: true,
                authorId: userId,
                operationType: 'decrement',
                grantId,
                flinkeyAssignment
              });
        // }, 2000);
        setIsRequestCustomerSend(true);
      }
    }
    // eslint-disable-next-line
  }, [currentTransaction.attributes.metadata, transactionRole]);

  const redirectToCheckoutPageWithInitialValues = (initialValues, listing) => {
    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: currentListing.id.uuid, slug: createSlug(currentListing.attributes.title) },
        {}
      )
    );
  };

  // If payment is pending, redirect to CheckoutPage
  if (
    txIsPaymentPending(currentTransaction) &&
    isCustomerRole &&
    currentTransaction.attributes.lineItems
  ) {
    const currentBooking = ensureListing(currentTransaction.booking);

    const initialValues = {
      listing: currentListing,
      // Transaction with payment pending should be passed to CheckoutPage
      transaction: currentTransaction,
      // Original bookingData content is not available,
      // but it is already used since booking is created.
      // (E.g. quantity is used when booking is created.)
      bookingData: {},
      bookingDates: {
        bookingStart: currentBooking.attributes.start,
        bookingEnd: currentBooking.attributes.end,
      },
    };

    redirectToCheckoutPageWithInitialValues(initialValues, currentListing);
  }

  // Customer can create a booking, if the tx is in "enquiry" state.
  const handleSubmitBookingRequest = values => {
    const { bookingStartTime, bookingEndTime, bookingDates, ...restOfValues } = values;
    const bookingStart = timestampToDate(bookingStartTime);
    const bookingEnd = timestampToDate(bookingEndTime);

    let price = 0;
    const { timePrice } = currentListing.attributes.publicData;

    // eslint-disable-next-line
    for (const key in timePrice) {
      if (userSelectedTimeOption === key) {
        price = parseInt(timePrice[key]);
      }
    }

    const bookingData = {
      quantity:
        calculateQuantityFromHours(bookingStart, bookingEnd) ||
        parseInt(lineItemsListingPage[0].quantity),
      price: parseInt(price),
      ...restOfValues,
    };

    let setBookingdates;

    if (userSelectedTimeOption === 'hourly') {
      setBookingdates = {
        bookingStart,
        bookingEnd,
      };
    } else if (
      userSelectedTimeOption === 'daily' ||
      userSelectedTimeOption === 'nightly' ||
      userSelectedTimeOption === 'weekly'
    ) {
      setBookingdates = {
        bookingStart: bookingDates.startDate,
        bookingEnd: bookingDates.endDate,
      };
    }

    const initialValues = {
      listing: currentListing,
      // enquired transaction should be passed to CheckoutPage
      transaction: currentTransaction,
      bookingData,
      bookingDates: setBookingdates,
      confirmPaymentError: null,
    };

    redirectToCheckoutPageWithInitialValues(initialValues, currentListing);
  };

  const deletedListingTitle = intl.formatMessage({
    id: 'TransactionPage.deletedListing',
  });
  const listingTitle = currentListing.attributes.deleted
    ? deletedListingTitle
    : currentListing.attributes.title;

  // Redirect users with someone else's direct link to their own inbox/sales or inbox/orders page.
  const isDataAvailable =
    currentUser &&
    currentTransaction.id &&
    currentTransaction.id.uuid === params.id &&
    currentTransaction.attributes.lineItems &&
    currentTransaction.customer &&
    currentTransaction.provider &&
    !fetchTransactionError;

  const isOwnSale =
    isDataAvailable &&
    isProviderRole &&
    currentUser.id.uuid === currentTransaction.provider.id.uuid;
  const isOwnOrder =
    isDataAvailable &&
    isCustomerRole &&
    currentUser.id.uuid === currentTransaction.customer.id.uuid;

  if (isDataAvailable && isProviderRole && !isOwnSale) {
    // eslint-disable-next-line no-console
    console.error('Tried to access a sale that was not owned by the current user');
    return <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />;
  } else if (isDataAvailable && isCustomerRole && !isOwnOrder) {
    // eslint-disable-next-line no-console
    console.error('Tried to access an order that was not owned by the current user');
    return <NamedRedirect name="InboxPage" params={{ tab: 'orders' }} />;
  }

  const detailsClassName = classNames(css.tabContent, css.tabContentVisible);

  const fetchErrorMessage = isCustomerRole
    ? 'TransactionPage.fetchOrderFailed'
    : 'TransactionPage.fetchSaleFailed';
  const loadingMessage = isCustomerRole
    ? 'TransactionPage.loadingOrderData'
    : 'TransactionPage.loadingSaleData';

  const loadingOrFailedFetching = fetchTransactionError ? (
    <p className={css.error}>
      <FormattedMessage id={`${fetchErrorMessage}`} />
    </p>
  ) : (
    <p className={css.loading}>
      <FormattedMessage id={`${loadingMessage}`} />
    </p>
  );

  const initialMessageFailed = !!(
    initialMessageFailedToTransaction &&
    currentTransaction.id &&
    initialMessageFailedToTransaction.uuid === currentTransaction.id.uuid
  );

  const selectedTimeOptions = timeOption => {
    onSetTimeoption(timeOption);
  };

  // TransactionPanel is presentational component
  // that currently handles showing everything inside layout's main view area.
  const panel = isDataAvailable ? (
    <TransactionPanel
      className={detailsClassName}
      currentUser={currentUser}
      transaction={currentTransaction}
      fetchMessagesInProgress={fetchMessagesInProgress}
      totalMessagePages={totalMessagePages}
      oldestMessagePageFetched={oldestMessagePageFetched}
      messages={messages}
      initialMessageFailed={initialMessageFailed}
      savePaymentMethodFailed={savePaymentMethodFailed}
      fetchMessagesError={fetchMessagesError}
      sendMessageInProgress={sendMessageInProgress}
      sendMessageError={sendMessageError}
      sendReviewInProgress={sendReviewInProgress}
      sendReviewError={sendReviewError}
      onFetchTimeSlots={onFetchTimeSlots}
      onManageDisableScrolling={onManageDisableScrolling}
      onShowMoreMessages={onShowMoreMessages}
      onSendMessage={onSendMessage}
      onSendReview={onSendReview}
      transactionRole={transactionRole}
      onAcceptSale={onAcceptSale}
      onDeclineSale={onDeclineSale}
      acceptInProgress={acceptInProgress}
      declineInProgress={declineInProgress}
      acceptSaleError={acceptSaleError}
      declineSaleError={declineSaleError}
      nextTransitions={processTransitions}
      onSubmitBookingRequest={handleSubmitBookingRequest}
      monthlyTimeSlots={monthlyTimeSlots}
      lineItems={lineItems}
      fetchLineItemsError={fetchLineItemsError}
      fetchLineItemsInProgress={fetchLineItemsInProgress}
      userSelectedTimeOption={userSelectedTimeOption}
      selectedTimeOptions={selectedTimeOptions}
      onFetchTransactionLineItems={onFetchTransactionLineItems}
      onGetGrunt={onGetGrunt}
      grunt={grunt}
      gruntError={gruntError}
      onRevokeGrunt={onRevokeGrunt}
      onGetAssignments={onGetAssignments}
      flinkeyAssignment={flinkeyAssignment}
      flinkeyAssignmentDeleted={flinkeyAssignmentDeleted}
      onDeleteAssignments={onDeleteAssignments}
      onCancelBooking={onCancelBooking}
      onSendCancelMessage={onSendCancelMessage}
    />
  ) : (
    loadingOrFailedFetching
  );

  return (
    <Page
      title={intl.formatMessage({ id: 'TransactionPage.title' }, { title: listingTitle })}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.root}>{panel}</div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter className={css.footer}>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

TransactionPageComponent.defaultProps = {
  currentUser: null,
  fetchTransactionError: null,
  acceptSaleError: null,
  declineSaleError: null,
  transaction: null,
  fetchMessagesError: null,
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPageComponent.propTypes = {
  params: shape({ id: string }).isRequired,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,
  currentUser: propTypes.currentUser,
  fetchTransactionError: propTypes.error,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  fetchMessagesError: propTypes.error,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailedToTransaction: propTypes.uuid,
  savePaymentMethodFailed: bool,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  callSetInitialValues: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,

  // line items
  lineItems: array,
  fetchLineItemsInProgress: bool,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchTransactionError,
    acceptSaleError,
    declineSaleError,
    acceptInProgress,
    declineInProgress,
    transactionRef,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    monthlyTimeSlots,
    processTransitions,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    grunt,
    gruntError,
    flinkeyAssignment,
    // flinkeyAssignmentError,
    flinkeyAssignmentDeleted,
  } = state.TransactionPage;
  const { currentUser } = state.user;

  const userSelectedTimeOption =
    state.ListingPage && state.ListingPage.timeOption ? state.ListingPage.timeOption : null;
  const lineItemsListingPage = state.ListingPage.lineItems;

  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;

  return {
    currentUser,
    fetchTransactionError,
    acceptSaleError,
    declineSaleError,
    acceptInProgress,
    declineInProgress,
    scrollingDisabled: isScrollingDisabled(state),
    transaction,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    monthlyTimeSlots,
    processTransitions,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    userSelectedTimeOption,
    lineItemsListingPage,
    grunt,
    gruntError,
    flinkeyAssignment,
    flinkeyAssignmentDeleted,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAcceptSale: transactionId => dispatch(acceptSale(transactionId)),
    onDeclineSale: transactionId => dispatch(declineSale(transactionId)),
    onShowMoreMessages: txId => dispatch(fetchMoreMessages(txId)),
    onSendMessage: (txId, message, transactionRole, providerId, customerId, metadata) =>
      dispatch(sendMessage(txId, message, transactionRole, providerId, customerId, metadata)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onSendReview: (role, tx, reviewRating, reviewContent) =>
      dispatch(sendReview(role, tx, reviewRating, reviewContent)),
    callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
    onFetchTimeSlots: (listingId, start, end, timeZone) =>
      dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
    onSetTimeoption: data => dispatch(setTimeoption(data)),
    onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
      dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
    onFetchMetadataChangeRequest: params => dispatch(fetchMetadataChangeRequest(params)),
    onGetGrunt: params => dispatch(getGrunt(params)),
    onRevokeGrunt: params => dispatch(revokeGrunt(params)),
    onGetAssignments: (carId, userId, bookingStart, bookingEnd) =>
      dispatch(getAssignments(carId, userId, bookingStart, bookingEnd)),
    onDeleteAssignments: (assignmentId, userId) =>
      dispatch(deleteAssignments(assignmentId, userId)),
    onCancelBooking: (id) => dispatch(cancelBooking(id)),
    onSendCancelMessage: (params) => dispatch(sendCancelMessage(params))
  };
};

const TransactionPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TransactionPageComponent);

TransactionPage.loadData = loadData;
TransactionPage.setInitialValues = setInitialValues;

export default TransactionPage;

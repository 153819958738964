import React, { useState, useEffect } from 'react';
import { array, bool, func, shape, string, object } from 'prop-types';
import { compose } from 'redux';

import { injectIntl } from '../../util/reactIntl';

import { Button, NamedLink } from '../../components';

import {
  TAPKEY_PROVIDER,
  NONE_PROVIDER,
  ACCESS_PROVIDERS_OPTIONS,
  // FLINKEY_PROVIDER,
} from '../../constants/accessProviders';

// import FlinkeyAccessProvider from '../../components/FlinkeyAccessProvider/FlinkeyAccessProvider';
import TapkeyAccessProviderForm from './TapkeyAccessProviderForm';
import css from './EditListingLocksForm.css';

// import { createCar, uninstallCarFromBox } from './EditListingLocksForm.helper';

export const EditListingLocksFormComponent = props => {
  const {
    updateInProgress,
    stripeConnected,
    isPastDraft,
    isPriceNull,
    intl,
    tapkeyStateData,
    tapkeyData,
    accessProvider,
    onSubmit,
    onGetTapkeyLocks,
    currentUser,
    // currentListing,
    // onFlinkeyCredentialsExist,
    // flinkeyCredentialsExist,
  } = props;
  const [provider, setProvider] = useState(accessProvider ?? NONE_PROVIDER);
  // const [flinkeyError, setFlinkeyError] = useState(null);
  // const userId = currentUser?.id.uuid ?? null;
  // const { flinkeyCar, flinkeyProducts, isFlinkeyInstalled } = currentListing.attributes.publicData;
  // const title = currentListing.attributes.title;

  const isTapkey = tapkeyData && tapkeyData.lock;

  const tapkeyAccessToken = currentUser?.attributes?.profile?.privateData?.tapkeyToken ?? null;

  const connectedAccessProviders = ACCESS_PROVIDERS_OPTIONS.filter(({ value }) => {
    return (
      (value === TAPKEY_PROVIDER && tapkeyAccessToken)
      // (value === FLINKEY_PROVIDER && flinkeyCredentialsExist)
    )
  });

  useEffect(() => {
    // onFlinkeyCredentialsExist(userId);
    if (provider === TAPKEY_PROVIDER) {
      onGetTapkeyLocks();
    }
  }, [onGetTapkeyLocks, provider]);

  // const submitFlinkeyAccessProvider = async () => {
  //   const result = await createCar(userId, title, flinkeyCar);
  //   if (result.error) {
  //     setFlinkeyError(result.error);
  //     return;
  //   }
  //   handleSubmit(result);
  // };

  // const uninstallBox = async () => {
  //   const result = await uninstallCarFromBox(userId, flinkeyCar, flinkeyProducts);
  //   if (result.error) {
  //     setFlinkeyError(result.error);
  //     return;
  //   }

  //   const updateValues = {
  //     publicData: {
  //       access_provider: null,
  //       isFlinkeyInstalled: false,
  //     },
  //   };

  //   onSubmit(updateValues);
  // };

  const handleProviderChange = event => setProvider(event.target.value);

  const handleSubmit = values => {
    let updateValues = {};
    switch (provider) {
      case TAPKEY_PROVIDER: {
        updateValues = {
          publicData: {
            access_provider: provider,
            tapkey_data: {
              lock: values.lock,
            },
          },
        };
        break;
      }
      // case FLINKEY_PROVIDER: {
      //   updateValues = {
      //     publicData: {
      //       access_provider: provider,
      //       flinkeyCar: values.car,
      //       flinkeyProducts: values.products,
      //       isFlinkeyInstalled: values.installed,
      //     },
      //   };
      //   break;
      // }
      case NONE_PROVIDER: {
        updateValues = {
          publicData: {
            access_provider: null,
            tapkey_data: null,
          },
        };
        break;
      }
      default:
        break;
    }
    onSubmit(updateValues);
  };

  const providerLabelText = intl.formatMessage({
    id: 'EditListingLocksForm.providerLabelText',
  });

  return (
    <>
      <div>
        {intl.formatMessage({ id: 'EditListingLocksForm.accessProvidersNote' })}
        {/* <NamedLink name={tapkeyAccessToken && flinkeyCredentialsExist ? "TapkeyPage" : tapkeyAccessToken ? "FlinkeyPage" : "TapkeyPage"}> */}
        <NamedLink name={"TapkeyPage"}>
          {intl.formatMessage({ id: 'EditListingLocksForm.clickHere' })}
        </NamedLink>
      </div>
      {connectedAccessProviders.length ? (<div className={css.row}>
        <span className={css.label}>{providerLabelText}</span>
        <select name="access_provider" value={provider} onChange={handleProviderChange}>
          <option value={NONE_PROVIDER}>{NONE_PROVIDER}</option>
          {connectedAccessProviders.map(provider => (
            <option key={provider.value} value={provider.value}>
              {provider.text}
            </option>
          ))}
        </select>
      </div>) : null}
      {/* {flinkeyError ? <div className={css.error}>{flinkeyError}</div> : null} */}

      {/* {provider === FLINKEY_PROVIDER ? (
        <FlinkeyAccessProvider
          intl={intl}
          handleSubmit={submitFlinkeyAccessProvider}
          flinkeyCredentials={flinkeyCredentialsExist}
          uninstallBox={uninstallBox}
          isFlinkeyInstalled={isFlinkeyInstalled}
        />
      ) : null} */}
      {provider === TAPKEY_PROVIDER && (
        <TapkeyAccessProviderForm
          updateInProgress={updateInProgress}
          buttonDisabled={isPriceNull ? false : !stripeConnected || !isPastDraft}
          tapkeyLocks={tapkeyStateData.locks}
          lock={tapkeyData && tapkeyData.lock}
          getTokenError={tapkeyStateData.getTokenError}
          getLocksError={tapkeyStateData.getLocksError}
          getLocksPending={tapkeyStateData.getLocksPending}
          onSubmit={handleSubmit}
        />
      )}
      {/* {(isFlinkeyInstalled || isTapkey) && provider === NONE_PROVIDER ? ( */}
      {isTapkey && provider === NONE_PROVIDER ? (
        <div className={css.row}>
           <Button
            className={css.submitButton}
            onClick={() => handleSubmit({})}
          >
            {intl.formatMessage({ id: 'EditListingLocksForm.clearAccessProvider' })}
          </Button>
        </div>
      ) : null}
    </>
  );
};

EditListingLocksFormComponent.defaultProps = {};

EditListingLocksFormComponent.propTypes = {
  accessProvider: string,
  tapkeyData: shape({
    client_id: string,
    lock: shape({
      id: string.isRequired,
      title: string.isRequired,
    }),
  }),
  tapkeyStateData: shape({
    locks: array.isRequired,
    getTokenError: object,
    getLocksError: object,
    getLocksPending: bool,
  }),
  /* actions */
  onSubmit: func.isRequired,
  onGetTapkeyLocks: func.isRequired,
};

export default compose(injectIntl)(EditListingLocksFormComponent);

import React, { useState } from 'react';

import { Form, FieldRadioButton, FieldTextInput } from '../../components';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import css from './TimeOptionTypesForm.css';

function TimeOptionTypesForm(props) {
  const [selectedTimeOptions, setSelectedTimeOptions] = useState('');
  const [enterWeeks, setEnterWeeks] = useState(1);

  const {
    rootClassName,
    className,
    changeTimeOptions,
    changeWeeksNumber,
    listing,
    intl,
    price: unitPrice,
    ...rest
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  function handleFormSubmit(e) {
    props.onSubmit(e);
  }

  return (
    <FinalForm
      {...rest}
      onSubmit={handleFormSubmit}
      render={fieldRenderProps => {
        const { pristine } = fieldRenderProps;

        const numberOfWeekLabel = intl.formatMessage({
          id: 'TimeOptionTypesForm.numberOfWeek',
        });

        const showAsRequired = pristine;

        const onChangeRadioButton = e => {
          const timeOption = e.target.value;
          setSelectedTimeOptions(timeOption);

          changeTimeOptions(timeOption);
        };

        const onChangeNumberWeek = e => {
          const numberOfWeeks = e.target.value;
          setEnterWeeks(numberOfWeeks);

          changeWeeksNumber(parseInt(numberOfWeeks));
        };

        const showRadioButton = value => {
          const { timePrice } = listing.attributes.publicData;
          let showButton = css.hideRadioButton;

          if (timePrice) {
            // eslint-disable-next-line
            for (const key in timePrice) {
              if (value === key) {
                showButton = css.showRadioButton;
              }
            }
          }

          return showButton;
        };

        return (
          <Form className={classes}>
            <div className={css.timeOptionsContainer}>
              <div className={css.timeOptionTitle}>
                <FormattedMessage id="TimeOptionTypesForm.selectTitle" />
              </div>
              <div className={css.radioButtonRow}>
                <FieldRadioButton
                  id="hourly"
                  name="timeOptions"
                  label={<FormattedMessage id="EditListingAvailabilityTimeOption.hourly" />}
                  value="hourly"
                  showAsRequired={showAsRequired}
                  checked={selectedTimeOptions === 'hourly'}
                  onChange={onChangeRadioButton}
                  className={showRadioButton('hourly')}
                />
                <FieldRadioButton
                  id="daily"
                  name="timeOptions"
                  label={<FormattedMessage id="EditListingAvailabilityTimeOption.daily" />}
                  value="daily"
                  showAsRequired={showAsRequired}
                  checked={selectedTimeOptions === 'daily'}
                  onChange={onChangeRadioButton}
                  className={showRadioButton('daily')}
                />
                <FieldRadioButton
                  id="nightly"
                  name="timeOptions"
                  label={<FormattedMessage id="EditListingAvailabilityTimeOption.nightly" />}
                  value="nightly"
                  showAsRequired={showAsRequired}
                  checked={selectedTimeOptions === 'nightly'}
                  onChange={onChangeRadioButton}
                  className={showRadioButton('nightly')}
                />
                {/* <FieldRadioButton
                  id="weekly"
                  name="timeOptions"
                  label={<FormattedMessage id="EditListingAvailabilityTimeOption.weekly" />}
                  value="weekly"
                  showAsRequired={showAsRequired}
                  checked={selectedTimeOptions === 'weekly'}
                  onChange={onChangeRadioButton}
                  className={showRadioButton('weekly')}
                /> */}
              </div>
              {selectedTimeOptions === 'weekly' ? (
                <div className={css.weeklyField}>
                  <FieldTextInput
                    className={css.field}
                    type="number"
                    id="weekleSelect"
                    name="weekleSelect"
                    min={1}
                    placeholder={'1 weeks'}
                    defaultValue={enterWeeks}
                    label={numberOfWeekLabel}
                    onChange={onChangeNumberWeek}
                  />
                </div>
              ) : null}
            </div>
          </Form>
        );
      }}
    />
  );
}

TimeOptionTypesForm.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
};

TimeOptionTypesForm.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

TimeOptionTypesForm.displayName = 'TimeOptionTypesForm';

export default compose(injectIntl)(TimeOptionTypesForm);

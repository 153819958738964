import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import { Modal } from '../../components';

import css from './ModalSuccessSendInvite.css';

const ModalSuccessSendInvite = props => {
  const { id, isOpen, onClose, onManageDisableScrolling } = props;

  return (
    <Modal
      id={id}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.modalContainer}>
        <p className={css.modalTitle}>
          <FormattedMessage id="ModalSuccessSendInvite.message" />
        </p>
      </div>
    </Modal>
  );
};

export default ModalSuccessSendInvite;

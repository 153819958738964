import React from 'react';
//import config from '../../config';
//import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  //ExternalLink,
} from '../../components';

import css from './AboutPage.css';


import carsten from '../../assets/carsten_hokema.jpg';
import tom from '../../assets/tom_ruthenberg.jpg';
import tobias from '../../assets/tobias_rothenberger.jpg';

const AboutPage = () => {
  // const { siteTwitterHandle, siteFacebookPage } = config;
  // const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Yogatime',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="AboutPage"/>
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>Über Uns</h1>
          <div className={css.row}>
            <div className={css.founder}>
              <img className={css.coverImage} src={carsten} alt="Carsten Hokema" />
              <h2>Carsten Hokema</h2>
            </div>
            <div className={css.founder}>
              <img className={css.coverImage} src={tom} alt="Tom Ruthenberg" />
              <h2>Tom Ruthenberg</h2>
            </div>
            <div className={css.founder}>
              <img className={css.coverImage} src={tobias} alt="Tobias Rothenberger" />
              <h2>Tobias Rothenberger</h2>
            </div>
          </div>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
            <p>Sharepar wurde 2018 von den Kreativen Carsten Hokema, Tom Ruthenberg und Tobias Rothenberger in Berlin erdacht und entwickelt, um die Kultur des Teilens von Objekten im Alltag auf das nächste Level zu bringen.</p>

            <p>Für viele ist das Teilen schon selbstverständlich geworden, andere Menschen interessieren sich bereits dafür und möchten damit beginnen.</p>

            <p>Was bisher fehlte, war die Möglichkeit, das Teilen von Dingen selbst in die Hand zu nehmen, ohne dabei auf Plattformen und deren Angebote angewiesen zu sein.</p>

            <p>Dafür gibt es jetzt Sharepar: ein einfaches Tool, das es dir ermöglicht, eigene Sharing Gruppen zu gründen und zu betreiben.</p>

            <p>Das Prinzip ist simpel: Ein Objekt, eine Gruppe. So passt beides gut zusammen.</p>

            </div>

          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;

import React from 'react';
//import config from '../../config';
//import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
//  ExternalLink,
} from '../../components';

import css from './FAQPage.css';


const HelpPage = () => {
  //const { siteTwitterHandle, siteFacebookPage } = config;
  //const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      title="F.A.Q."
      schema={{
        '@context': 'http://schema.org',
        '@type': 'FAQPage',
        description: 'FAQ SharePar',
        name: 'FAQ page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="FAQPage" />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>FAQ – Häufig gestellte Fragen</h1>

          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <div className={css.row}>
                <div className={css.frage}><h3>1.	Was ist Sharepar und was ist es nicht?</h3></div>
                <div className={css.antwort}>
                  <ul>
                    <p>Sharepar ist ein Tool, also ein Werkzeug, mit dem du eigene Sharing-Gruppen betreiben und organisieren kannst.</p>
                    <p>Sharepar ist daher <b>kein Marktplatz</b> im eigentlichen Sinne.</p>
                    <p>Du findest hier also keine frei angezeigten Objekte zum Nutzen oder Teilen, wie auf einer öffentlichen Plattform.</p>
                    <p>Stattdessen kümmerst du dich selbst darum, in deinem Freundes- oder Bekanntenkreis das Sharing zu betreiben und gründest eine Gruppe jeweils um ein Objekt herum.</p>
                    <p>Deshalb werden bei dir immer genau die Objekte angezeigt, die du selbst verwaltest und die, in denen du Mitglied bist.</p>
                    <p>Niemand außer dir selbst, den jeweiligen Objektbesitzern und deine eigenen Gruppenmitglieder sieht, was du anbietest oder nutzt.</p>
                  </ul>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>2. Wie funktioniert Sharepar?</h3></div>
                <div className={css.antwort}>
                  <ul>
                    <li><p>1. Du legst zunächst ein persönliches Profil an, am besten mit deinem Klarnamen und einem sympathischen Foto von dir. Mit diesem Profil steuerst du alle deine Sharing-Aktivitäten. Der Klarname ist wichtig, weil es bei Sharepar eine Bezahlfunktion gibt, mit der du nicht nur die Nutzung von Objekten bezahlen, sondern auch deine Einnahmen erhalten kannst. Wenn du ausschließlich Objekte nutzt, die kostenlos sind oder du deine Objekte kostenlos anbietest, sind keine Kontodaten erforderlich.</p></li>
                    <li><p>2. Als nächstes stellst du am besten ein oder mehrere Objekte  ein, die du mit anderen Menschen aus deinem Freundes- oder Bekanntenkreis teilen möchtest. Diese kann du mit einem Preis für die Nutzung versehen oder auch kostenlos anbieten.</p></li>
                    <li><p>3. Nun lädst du in der App Freundinnen und Freunde dazu ein, dein/e Objekt/e zu nutzen. Dazu brauchst du die jeweilige Emailadresse der Person. Sobald jemand zugestimmt hat und der Einladung gefolgt ist, kann er/sie das Objekt nutzen. Du kannst auch Freunde und andere Personen allgemein zu Sharepar einladen, wenn du einfach nur die App empfehlen möchtest. Sie haben dann keinen Zugang zu deinen Objekten, können aber selbst damit beginnen, ihre eigenen Gruppen zu gründen und zu organisieren.</p></li>
                  </ul>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>3. Was ist das Sharepar Prinzip?</h3></div>
                <div className={css.antwort}>
                  <p>Das Sharepar Prinzip ist das Prinzip Augenhöhe. Der Name bedeutet übersetzt auch <i>„teilen von gleich zu gleich“</i>.</p>
                  <p>Das heißt: Shareparianer bewegen sich in sogenannten Sharing-Gruppen, in deren Mittelpunkt jeweils immer genau ein Objekt steht. Ein Objekt = eine Gruppe.</p>
                  <p>Dieses Objekt wird in der Regel von einer Person (dem Owner) gestellt und von den anderen mitbenutzt, auf Wunsch des Owners gegen eine Gebühr oder auch kostenlos.</p>
                  <p>Ein wichtiger Aspekt des Sharepar Prinzips besteht darin, dass du keine Buchungsanfragen stellen musst, um ein Objekt zu nutzen. Du kannst es einfach direkt buchen, wenn du Mitglied in dieser Gruppe bist und das Objekt nicht anderweitig gebucht oder geblockt worden ist. Augenhöhe bedeutet für eine Gruppe, dass du direkten Zugang hast.</p>
                  <p>Man kann stets in mehreren, ja vielerlei Gruppen Owner oder auch Mitnutzer sein. Die Mitglieder dieser Gruppe haben dabei stets direkten Zugang zu dem jeweiligen Objekt.</p>
                  <p>So kommt auch die Vielfalt ins Spiel: in je mehr Gruppen du Mitglied bist, desto mehr Objekte kannst du direkt nutzen.</p>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>4. Kostet die Benutzung von Sharepar etwas?</h3></div>
                <div className={css.antwort}>
                  <p>Die Nutzung der Sharepar App ist grundsätzlich kostenlos.</p>
                  <p>Nutzer von Objekten bezahlen nur den angegebenen Preis für die Nutzung des jeweiligen Objektes. Von diesem Preis erhält Sharepar eine Provision von 15 %. Davon wird unter anderem auch der Zahlungsdienstleister Stripe bezahlt.</p>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>5. Wo liegen die Vorteile des Teilens/Sharing von Objekten?</h3></div>
                <div className={css.antwort}>
                  <p>Es gibt eine Reihe von guten Gründen, die für die gemeinsame Nutzung von Objekten spricht. Du allein entscheidest darüber, welche Vorteile du in den Mittelpunkt stellst:</p>
                  <p>Grund Nr. 1: Kosten sparen</p>
                  <p>Geld zu sparen, ist für viele der wichtigste Grund für das Teilen, denn zum Beispiel ein Auto, das du mit nur einer weiteren Person nutzt, kostet schon deutlich weniger, als wenn du es alleine unterhalten musst.</p>
                  <p>Grund Nr. 2: Vielfalt nutzen</p>
                  <p>Wenn du Mitglied in mehreren Sharing-Gruppen bist, hast du auch Zugang zu deren Objekten und damit mehr Möglichkeiten, deine Freizeit zu gestalten oder attraktive Dinge zu nutzen.</p>
                  <p>Grund Nr. 3: Gemeinschaft genießen</p>
                  <p>Das Teilen von Dingen kann dich mit anderen Menschen zusammenbringen, wenn du es magst. Vielleicht willst du die Zeit gemeinsam mit anderen in einem Garten verbringen? Oder auf einem Boot über das Wasser schippern, oder regelmäßig mit einem Hund spazieren gehen oder, oder, oder ...</p>
                  <p>Grund Nr. 4: Einnahmen erzielen.</p>
                  <p>Wenn du selbst mehrere Objekte besitzt, kannst du sie auch anderen gegen eine Gebühr zur Verfügung stellen und damit Einnahmen erzielen. Es spricht auch nichts dagegen, Sharepar gewerblich einzusetzen, wenn du Dinge dein Eigen nennst, für die andere bezahlen würden. Einnahmen unterliegen in der Regel steuerlichen Gesetzen, die es einzuhalten gilt. Dazu solltest du dich im konkreten Fall mit deinem Finanzamt oder Steuerberater in Verbindung setzen. Sharepar übernimmt keine Verantwortung dafür, wenn du entsprechende Einnahmen nicht versteuerst.</p>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>6. Wie viele Objekte kann ich mit Sharepar teilen?</h3></div>
                <div className={css.antwort}>
                  <p>Unsere Empfehlung lautet, es nicht zu übertreiben. Wenn du zu viele Objekte in deiner App hast, verlierst du auch leichter den Überblick und dann macht das Ganze keinen Spaß mehr.</p>
                  <p>Und wenn es dir keinen Spaß mehr macht, wirst du die Vorteile des Teilens nicht mehr zu schätzen wissen und damit aufhören.</p>
                  <p>Das wäre doch schade.</p>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>7. Muss ich eine Zahlungsmethode und eine Kontoverbindung angeben?</h3></div>
                <div className={css.antwort}>
                  <p>Sobald du aber kostenpflichtige Objekte nutzen oder selbst anbieten möchtest, sind für die Zahlung die Angabe einer Zahlungsmethode (Kreditkarte) und deine Einnahmen dein Bankkonto erforderlich.</p>
                  <p>Wir von Sharepar erhalten keine dieser Daten, da wir dafür den großen und bekannten Zahlungsdienstleister <a href='https://stripe.com/de'>Stripe</a> nutzen.</p>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>8. Darf ich Sharepar auch gewerblich oder als Nebenerwerb nutzen?</h3></div>
                <div className={css.antwort}>
                  <p>Grundsätzlich darfst du Sharepar so nutzen, wie du es möchtest, solange du nicht gegen gesetzliche Regelungen und/oder unsere AGB verstößt.</p>
                  <p>Wenn du Einkünfte erzielen willst, solltest du dich dazu am besten zuvor bei deinem zuständigen Finanzamt oder deinem Steuerberater erkundigen. Für die Abgabe einer entsprechenden Steuererklärung und die Zahlung deiner Steuern bist du in jedem Fall selbst verantwortlich.</p>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>9. Wie finde ich Freunde oder Bekannte, mit denen ich Objekte teilen oder deren Objekte ich nutzen kann?</h3></div>
                <div className={css.antwort}>
                  <p>Bis auf Weiteres bist du noch auf deine eigene Community, deine Facebook-Kontakte, dein Adressbuch, deinen Freundeskreis und deine Familie angewiesen, um entsprechende Mitnutzer/innen zu finden. Schreib deinen Freunden dort einfach, wenn du etwas in einer Gruppe teilen möchtest oder eine Gruppe suchst, die ein bestimmtes Objekt bereits besitzt.</p>
                  <p>Für die Zukunft planen wir, eine entsprechende Funktion in die Sharepar App einzubauen, mit der du auch gezielt in weiteren Kreisen suchen kannst.</p>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>10. Ich habe keine Idee, was ich mit Sharepar teilen könnte. Könnt ihr mir helfen?</h3></div>
                <div className={css.antwort}>
                  <p>Wir haben eine lange Liste von Objekten zusammengestellt, die auch heute schon von Menschen miteinander gefeilt werden: Hier ist ein kleiner Auszug: Arbeitsraum, Atelier, Auto, Balkon, Camper, Drohne, Fahrrad, Ferienhaus, Ferienwohnung, Garten, Hund, Moped, Motorrad, Pferd, Rasenmäher, Studio, Traktor, Werkzeug, Werkstatt, Wohnwagen etc.</p>
                  <p>Du kannst dich in der Stadt und im Sommer auch gemeinsam um einen Baum kümmern und ihn regelmäßig gießen. Im Grunde lässt sich alles, was du allein benutzt, auch mit anderen teilen. Deiner Fantasie sind da im Grunde keine Grenzen gesetzt.</p>
                </div>
              </div>

              <div className={css.row}>
                <div className={css.frage}><h3>11. Welche Vorteile bietet das Teilen mit Sharepar?</h3></div>
                <div className={css.antwort}>
                  <p>Sharepar bietet dir in Zukunft eine optimale organisatorische Umgebung, die das Teilen besonders einfach macht. Du kannst dazu wahlweise dein Smartphone, Tablet oder auch einen Computer benutzen, da Sharepar im Browser auf jedem Gerät funktionieren sollte. Da das Teilen von Objekten mit mehr als zwei Personen durchaus kompliziert werden kann, ist es zum Beispiel sinnvoll, eine Buchungsfunktion zu haben und die Absprachen über die Nutzung klar festzuhalten. Auch die Versicherung bestimmter Risiken kann sehr sinnvoll sein. Wir werden die Funktionen von Sharepar im Laufe der Zeit stets weiter entwickeln und deinen Bedürfnissen anpassen, um das Sharing für alle wirklich leicht zu machen.</p>
                </div>
              </div>
              <div className={css.row}>
                <div className={css.frage}><h3>12. Wie erzielt Sharepar Einnahmen?</h3></div>
                <div className={css.antwort}>
                  <p>Sharepar erzielt seine Einnahmen aus einem Anteil des Nutzungspreises des Objekts und in Zukunft in einigen Fällen durch Kooperationen mit Servicepartnern, von denen wir eine Vermittlungsprovision erhalten; zum Beispiel, wenn ein Objekt versichert werden soll. Nähere Angaben dazu findest du in unseren <NamedLink name="TermsOfServicePage"> AGB</NamedLink>.</p>
                </div>
              </div>
              <div className={css.row}>
                <div className={css.frage}><h3>13. Was passiert mit meinen Daten?</h3></div>
                <div className={css.antwort}>
                  <p>Sharepar unterliegt selbstverständlich der DSGVO und geht mit deinen Daten genau so um, wie es die Gesetze verlangen und erlauben. Personenbezogene Daten werden nur für den Zweck des Sharing gespeichert und genutzt und nicht an Dritte weitergegeben. Genauere Informationen findest du in unserer <NamedLink name="PrivacyPolicyPage"> Datenschutzerklärung</NamedLink>.</p>
                </div>
              </div>
            </div>

          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default HelpPage;

import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import { Modal, PrimaryButton } from '../../components';

import css from './ModalDeleteUser.css';

const ModalDeleteUser = props => {
  const { id, isOpen, onClose, onManageDisableScrolling, onDelete } = props;

  return (
    <Modal
      id={id}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.modalContainer}>
        <p className={css.modalTitle}>
          <FormattedMessage id="ModalDeleteUser.deleteMember" />
        </p>
        <div className={css.buttonContainer}>
          <PrimaryButton
            className={css.buttonAcept}
            onClick={onDelete}
          >
            <FormattedMessage id="ModalDeleteUser.buttonAcept" />
          </PrimaryButton>
          <PrimaryButton className={css.buttonCancel} onClick={onClose}>
            <FormattedMessage id="ModalDeleteUser.buttonCancel" />
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDeleteUser;

import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { Form, Button, FieldTextInput, FieldPhoneNumberInput } from '../../components';
//import CustomCertificateSelectFieldMaybe from './CustomCertificateSelectFieldMaybe';

import css from './EditListingDescriptionForm.css';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        //       certificate,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const hintMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.hint' });


      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const ownerNameText = intl.formatMessage({
        id: 'EditListingDescriptionForm.ownerName',
      });
      const objectTypeText = intl.formatMessage({
        id: 'EditListingDescriptionForm.objectType',
      });
      const markeModelText = intl.formatMessage({
        id: 'EditListingDescriptionForm.markeModel',
      });
      const necessaryConditionsText = intl.formatMessage({
        id: 'EditListingDescriptionForm.necessaryConditions',
      });
      const meaningfulFurtherText = intl.formatMessage({
        id: 'EditListingDescriptionForm.meaningfulFurther',
      });
      const securityNotesText = intl.formatMessage({
        id: 'EditListingDescriptionForm.securityNotes',
      });
      const userAgreementsText = intl.formatMessage({
        id: 'EditListingDescriptionForm.userAgreements',
      });
      const contactPersonText = intl.formatMessage({
        id: 'EditListingDescriptionForm.contactPerson',
      });

      const ownerNamePlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.ownerNamePlaceholder',
      });
      const objectTypePlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.objectTypePlaceholder',
      });
      const markeModelPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.markeModelPlaceholder',
      });
      const necessaryConditionsPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.necessaryConditionsPlaceholder',
      });
      const meaningfulFurtherPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.meaningfulFurtherPlaceholder',
      });
      const securityNotesPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.securityNotesPlaceholder',
      });
      const userAgreementsPlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.userAgreementsPlaceholder',
      });
      const contactPersonNamePlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.contactPersonNamePlaceholder',
      });
      const contactPersonTelephonePlaceholder = intl.formatMessage({
        id: 'EditListingDescriptionForm.contactPersonTelephonePlaceholder',
      });
      const isCorrectNumber = validators.phoneNumberWithPlus(
        intl.formatMessage({
          id: 'EditListingDescriptionForm.contactPersonTelephoneFormat',
        })
      );

      const maxLength60Message = validators.maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const maxLength120Message = validators.maxLength(maxLengthMessage, 120);
      const maxLength600Message = validators.maxLength(maxLengthMessage, 600);
      const maxLength1000Message = validators.maxLength(maxLengthMessage, 1000);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const onFocusHandler = (e) => {
        e.preventDefault();
        if (!e.target.value) {
          e.target.value = validators.PHONE_NUMBER_WITH_PLUS;
        }
      }

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            hint={hintMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={validators.composeValidators(validators.required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />
          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            hint={hintMessage}
            placeholder={descriptionPlaceholderMessage}
            maxLength={120}
            validate={validators.composeValidators(validators.required(descriptionRequiredMessage))}
          />

          <FieldTextInput
            id="ownerName"
            name="ownerName"
            className={css.description}
            type="text"
            label={ownerNameText}
            placeholder={ownerNamePlaceholder}
            maxLength={120}
            validate={validators.composeValidators(validators.required(descriptionRequiredMessage), maxLength120Message)}
          />

          <FieldTextInput
            id="objectType"
            name="objectType"
            className={css.description}
            type="textarea"
            label={objectTypeText}
            placeholder={objectTypePlaceholder}
            maxLength={120}
            validate={validators.composeValidators(validators.required(descriptionRequiredMessage), maxLength120Message)}
          />

          <FieldTextInput
            id="markeModel"
            name="markeModel"
            className={css.description}
            type="textarea"
            label={markeModelText}
            placeholder={markeModelPlaceholder}
            maxLength={120}
            validate={validators.composeValidators(maxLength120Message)}
          />

          <FieldTextInput
            id="necessaryConditions"
            name="necessaryConditions"
            className={css.description}
            type="textarea"
            label={necessaryConditionsText}
            placeholder={necessaryConditionsPlaceholder}
            maxLength={1500}
            validate={validators.composeValidators(validators.required(descriptionRequiredMessage), maxLength120Message)}
          />

          <FieldTextInput
            id="meaningfulFurther"
            name="meaningfulFurther"
            className={css.description}
            type="textarea"
            label={meaningfulFurtherText}
            placeholder={meaningfulFurtherPlaceholder}
            maxLength={1500}
            validate={validators.composeValidators(maxLength600Message)}
          />

          <FieldTextInput
            id="securityNotes"
            name="securityNotes"
            className={css.description}
            type="textarea"
            label={securityNotesText}
            placeholder={securityNotesPlaceholder}
            maxLength={1500}
            validate={validators.composeValidators(maxLength600Message)}
          />

          <FieldTextInput
            id="userAgreements"
            name="userAgreements"
            className={css.description}
            type="textarea"
            label={userAgreementsText}
            placeholder={userAgreementsPlaceholder}
            maxLength={1500}
            validate={validators.composeValidators(validators.required(descriptionRequiredMessage), maxLength1000Message)}
          />

          <div className={css.contactPerson}>
            <FieldTextInput
              id="contactPersonName"
              name="contactPersonName"
              className={css.description}
              type="textarea"
              label={contactPersonText}
              placeholder={contactPersonNamePlaceholder}
              maxLength={120}
              validate={validators.composeValidators(maxLength120Message)}
            />
            <FieldPhoneNumberInput
              id="contactPersonTelephone"
              name="contactPersonTelephone"
              className={css.description}
              placeholder={contactPersonTelephonePlaceholder}
              maxLength={60}
              validate={validators.composeValidators(isCorrectNumber)}
              onFocus={onFocusHandler}
            />
          </div>

          {/*<CustomCertificateSelectFieldMaybe
            id="certificate"
            name="certificate"
            certificate={certificate}
            intl={intl}
          />*/}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificate: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);

/**
 * DateRangeInput wraps DateRangePicker from React-dates and gives a list of all default props we use.
 * Styles for DateRangePicker can be found from 'public/reactDates.css'.
 *
 * N.B. *isOutsideRange* in defaultProps is defining what dates are available to booking.
 */
import React, { Component } from 'react';
import { bool, func, instanceOf, oneOf, shape, string, arrayOf } from 'prop-types';
import { DateRangePicker, isInclusivelyAfterDay, isInclusivelyBeforeDay } from 'react-dates';
import { intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import moment from 'moment';
import {
  START_DATE,
  END_DATE,
  isInRange,
  resetToStartOfDay,
  dateIsAfter,
  monthIdStringInTimeZone,
  getMonthStartInTimeZone,
  nextMonthFn,
  prevMonthFn,
} from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  isDayBlockedFn,
  isOutsideRangeFn,
  isBlockedBetween,
  apiEndDateToPickerDate,
  pickerEndDateToApiDate,
} from './DateRangeInput.helpers';

import { IconArrowHead } from '../../components';
// import NextMonthIcon from '../FieldDateInput/NextMonthIcon';
// import PreviousMonthIcon from '../FieldDateInput/PreviousMonthIcon';
import css from './DateRangeInput.css';

export const HORIZONTAL_ORIENTATION = 'horizontal';
export const ANCHOR_LEFT = 'left';
const TODAY = new Date();
const MAX_TIME_SLOTS_RANGE = 180;

const endOfRange = (date, timeZone) => {
  return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};

// Since final-form tracks the onBlur event for marking the field as
// touched (which triggers possible error validation rendering), only
// trigger the event asynchronously when no other input within this
// component has received focus.
//
// This prevents showing the validation error when the user selects a
// value and moves on to another input within this component.
const BLUR_TIMEOUT = 100;

// IconArrowHead component might not be defined if exposed directly to the file.
// This component is called before IconArrowHead component in components/index.js
const PrevIcon = props => (
  <IconArrowHead {...props} direction="left" rootClassName={css.arrowIcon} />
);
const NextIcon = props => (
  <IconArrowHead {...props} direction="right" rootClassName={css.arrowIcon} />
);

// Possible configuration options of React-dates
const defaultProps = {
  initialDates: null, // Possible initial date passed for the component
  value: null, // Value should keep track of selected date.

  // input related props
  startDateId: 'startDate',
  endDateId: 'endDate',
  startDatePlaceholderText: null, // Handled inside component
  endDatePlaceholderText: null, // Handled inside component
  disabled: false,
  required: false,
  readOnly: false,
  screenReaderInputMessage: null, // Handled inside component
  showClearDates: false,
  showDefaultInputIcon: false,
  customArrowIcon: <span />,
  customInputIcon: null,
  customCloseIcon: null,
  noBorder: true,
  block: false,

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: HORIZONTAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  appendToBody: false,
  disableScroll: false,
  daySize: 38,
  isRTL: false,
  initialVisibleMonth: null,
  firstDayOfWeek: config.i18n.firstDayOfWeek,
  numberOfMonths: 1,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  renderCalendarInfo: null,
  hideKeyboardShortcutsPanel: true,

  // navigation related props
  navPrev: <PrevIcon />,
  navNext: <NextIcon />,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  transitionDuration: 200, // milliseconds between next month changes etc.

  renderCalendarDay: undefined, // If undefined, renders react-dates/lib/components/CalendarDay
  // day presentation and interaction related props
  renderDayContents: day => {
    return <span className="renderedDay">{day.format('D')}</span>;
  },
  minimumNights: 1,
  enableOutsideDays: false,
  isDayBlocked: () => false,

  // outside range -><- today ... today+available days -1 -><- outside range
  isOutsideRange: day => {
    const endOfRange = config.dayCountAvailableForBooking - 1;
    return (
      !isInclusivelyAfterDay(day, moment()) ||
      !isInclusivelyBeforeDay(day, moment().add(endOfRange, 'days'))
    );
  },
  isDayHighlighted: () => {},

  // Internationalization props
  // Multilocale support can be achieved with displayFormat like moment.localeData.longDateFormat('L')
  // https://momentjs.com/
  displayFormat: 'ddd, MMM D',
  monthFormat: 'MMMM YYYY',
  weekDayFormat: 'dd',
  phrases: {
    closeDatePicker: null, // Handled inside component
    clearDate: null, // Handled inside component
  },
};

const getMonthlyTimeSlots = (monthlyTimeSlots, date, timeZone) => {
  const monthId = monthIdStringInTimeZone(date, timeZone);

  return !monthlyTimeSlots || Object.keys(monthlyTimeSlots).length === 0
    ? []
    : monthlyTimeSlots[monthId] && monthlyTimeSlots[monthId].timeSlots
    ? monthlyTimeSlots[monthId].timeSlots
    : [];
};

const getAllTimeSlots = monthlyTimeSlots => {
  let arr = [];

  // eslint-disable-next-line
  for (const key in monthlyTimeSlots) {
    // eslint-disable-next-line
    key && monthlyTimeSlots[key].timeSlots && arr.push(...monthlyTimeSlots[key].timeSlots);
  }

  return arr;
};

// const Next = props => {
//   const { currentMonth, timeZone } = props;
//   const nextMonthDate = nextMonthFn(currentMonth, timeZone);

//   return dateIsAfter(nextMonthDate, endOfRange(TODAY, timeZone)) ? null : <NextMonthIcon />;
// };
// const Prev = props => {
//   const { currentMonth, timeZone } = props;
//   const prevMonthDate = prevMonthFn(currentMonth, timeZone);
//   const currentMonthDate = getMonthStartInTimeZone(TODAY, timeZone);

//   return dateIsAfter(prevMonthDate, currentMonthDate) ? <PreviousMonthIcon /> : null;
// };

class DateRangeInputComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: null,
      currentStartDate: null,
      currentMonth: getMonthStartInTimeZone(TODAY, props.timeZone),
    };

    this.blurTimeoutId = null;
    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.fetchMonthData = this.fetchMonthData.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Update focusedInput in case a new value for it is
    // passed in the props. This may occur if the focus
    // is manually set to the date picker.
    if (this.props.focusedInput && this.props.focusedInput !== prevProps.focusedInput) {
      this.setState({ focusedInput: this.props.focusedInput });
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.blurTimeoutId);
  }

  fetchMonthData(date) {
    const { listingId, timeZone, onFetchTimeSlots } = this.props;
    const endOfRangeDate = endOfRange(TODAY, timeZone);

    // Don't fetch timeSlots for past months or too far in the future
    if (isInRange(date, TODAY, endOfRangeDate)) {
      // Use "today", if the first day of given month is in the past
      const start = dateIsAfter(TODAY, date) ? TODAY : date;

      // Use endOfRangeDate, if the first day of the next month is too far in the future
      const nextMonthDate = nextMonthFn(date, timeZone);
      const end = dateIsAfter(nextMonthDate, endOfRangeDate)
        ? resetToStartOfDay(endOfRangeDate, timeZone, 0)
        : nextMonthDate;

      // Fetch time slots for given time range
      onFetchTimeSlots(listingId, start, end, timeZone);
    }
  }

  onDatesChange(dates) {
    const { unitType, timeSlots, timeZone, timeOption, numberWeeks } = this.props;

    let startDate, endDate;

    if (timeOption === 'weekly') {
      startDate = dates.startDate !== null && dates.startDate.hour(0);
      endDate = startDate !== null && moment(startDate).add(7 * numberWeeks, 'days');
    } else if (timeOption === 'daily') {
      // In this case 1 day = 00.00 - 24.00
      startDate = dates.startDate !== null && dates.startDate.hour(0);
      endDate = dates.endDate !== null && dates.endDate.hour(24);
    } else if (timeOption === 'nightly') {
      startDate = dates.startDate !== null && dates.startDate.hour(12);
      endDate = dates.endDate !== null && dates.endDate;
    }

    const timeSlotsOnSelectedMonth = getMonthlyTimeSlots(
      timeSlots,
      this.state.currentMonth,
      timeZone
    );

    // both dates are selected, a new start date before the previous start
    // date is selected
    const startDateUpdated =
      timeSlotsOnSelectedMonth &&
      startDate &&
      endDate &&
      this.state.currentStartDate &&
      startDate.isBefore(this.state.currentStartDate);

    // clear the end date in case a blocked date can be found
    // between previous start date and new start date
    const clearEndDate = startDateUpdated
      ? isBlockedBetween(
          timeSlotsOnSelectedMonth,
          startDate,
          //moment(this.state.currentStartDate).add(1, 'days')
          moment(this.state.currentStartDate)
        )
      : false;

    const startDateAsDate = startDate instanceof moment ? startDate.toDate() : null;
    const endDateAsDate = clearEndDate ? null : pickerEndDateToApiDate(unitType, endDate);

    this.setState(() => ({
      currentStartDate: startDateAsDate,
    }));

    this.props.onChange({ startDate: startDateAsDate, endDate: endDateAsDate });
  }

  onMonthClick(monthFn) {
    const { onMonthChanged, timeZone } = this.props;

    this.setState(
      prevState => ({ currentMonth: monthFn(prevState.currentMonth, timeZone) }),
      () => {
        // Callback function after month has been updated.
        // react-dates component has next and previous months ready (but inivisible).
        // we try to populate those invisible months before user advances there.
        this.fetchMonthData(monthFn(this.state.currentMonth, timeZone));

        // If previous fetch for month data failed, try again.
        const monthId = monthIdStringInTimeZone(this.state.currentMonth, timeZone);
        const currentMonthData = this.props.timeSlots[monthId];
        if (currentMonthData && currentMonthData.fetchTimeSlotsError) {
          this.fetchMonthData(this.state.currentMonth, timeZone);
        }

        // Call onMonthChanged function if it has been passed in among props.
        if (onMonthChanged) {
          onMonthChanged(monthId);
        }
      }
    );
  }

  onFocusChange(focusedInput) {
    // DateRangePicker requires 'onFocusChange' function and 'focusedInput'
    // but Fields of React-Form deals with onFocus & onBlur instead
    this.setState({ focusedInput });

    if (focusedInput) {
      window.clearTimeout(this.blurTimeoutId);
      this.props.onFocus(focusedInput);
    } else {
      window.clearTimeout(this.blurTimeoutId);
      this.blurTimeoutId = window.setTimeout(() => {
        this.props.onBlur();
      }, BLUR_TIMEOUT);
    }
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {
      className,
      unitType,
      initialDates,
      intl,
      name,
      startDatePlaceholderText,
      endDatePlaceholderText,
      onBlur,
      onChange,
      onFocus,
      phrases,
      screenReaderInputMessage,
      useMobileMargins,
      value,
      children,
      render,
      timeSlots,
      timeZone,
      numberWeeks,
      timeOption,
      ...datePickerProps
    } = this.props;
    /* eslint-enable no-unused-vars */

    const isDaily = timeOption === 'daily';
    const isWeekly = timeOption === 'weekly';
    const initialStartMoment = initialDates ? moment(initialDates.startDate) : null;
    const initialEndMoment = initialDates ? moment(initialDates.endDate) : null;
    const startDate =
      value && value.startDate instanceof Date ? moment(value.startDate) : initialStartMoment;
    const endDate =
      apiEndDateToPickerDate(unitType, value ? value.endDate : null) || initialEndMoment;

    // const timeSlotsOnSelectedMonth = getMonthlyTimeSlots(
    //   timeSlots,
    //   this.state.currentMonth,
    //   timeZone
    // );

    const allTimeSlots = getAllTimeSlots(timeSlots);

    let isDayBlocked = isDayBlockedFn(
      allTimeSlots,
      startDate,
      endDate,
      this.state.focusedInput,
      unitType,
      timeOption,
      numberWeeks
    );

    let isOutsideRange = isOutsideRangeFn(
      allTimeSlots,
      startDate,
      endDate,
      this.state.focusedInput,
      unitType,
      timeOption,
      numberWeeks
    );

    const startDatePlaceholderTxt =
      startDatePlaceholderText ||
      intl.formatMessage({ id: 'FieldDateRangeInput.startDatePlaceholderText' });
    const endDatePlaceholderTxt =
      endDatePlaceholderText ||
      intl.formatMessage({ id: 'FieldDateRangeInput.endDatePlaceholderText' });
    const screenReaderInputText =
      screenReaderInputMessage ||
      intl.formatMessage({ id: 'FieldDateRangeInput.screenReaderInputMessage' });
    const closeDatePickerText = phrases.closeDatePicker
      ? phrases.closeDatePicker
      : intl.formatMessage({ id: 'FieldDateRangeInput.closeDatePicker' });
    const clearDateText = phrases.clearDate
      ? phrases.clearDate
      : intl.formatMessage({ id: 'FieldDateRangeInput.clearDate' });

    const classes = classNames(css.inputRoot, className, {
      [css.withMobileMargins]: useMobileMargins,
    });

    return (
      <div className={classes}>
        <DateRangePicker
          {...datePickerProps}
          focusedInput={this.state.focusedInput}
          onFocusChange={this.onFocusChange}
          startDate={startDate}
          endDate={endDate}
          minimumNights={isDaily ? 0 : isWeekly ? 6 : 1}
          onDatesChange={this.onDatesChange}
          startDatePlaceholderText={startDatePlaceholderTxt}
          endDatePlaceholderText={endDatePlaceholderTxt}
          screenReaderInputMessage={screenReaderInputText}
          phrases={{ closeDatePicker: closeDatePickerText, clearDate: clearDateText }}
          isDayBlocked={isDayBlocked}
          isOutsideRange={isOutsideRange}
          onPrevMonthClick={() => this.onMonthClick(prevMonthFn)}
          onNextMonthClick={() => this.onMonthClick(nextMonthFn)}
          // navNext={<Next currentMonth={this.state.currentMonth} timeZone={timeZone} />}
          // navPrev={<Prev currentMonth={this.state.currentMonth} timeZone={timeZone} />}
        />
      </div>
    );
  }
}

DateRangeInputComponent.defaultProps = {
  className: null,
  useMobileMargins: false,
  timeSlots: null,
  ...defaultProps,
};

DateRangeInputComponent.propTypes = {
  className: string,
  startDateId: string,
  endDateId: string,
  unitType: propTypes.bookingUnitType.isRequired,
  focusedInput: oneOf([START_DATE, END_DATE]),
  initialDates: instanceOf(Date),
  intl: intlShape.isRequired,
  name: string.isRequired,
  isOutsideRange: func,
  onChange: func.isRequired,
  onBlur: func.isRequired,
  onFocus: func.isRequired,
  phrases: shape({
    closeDatePicker: string,
    clearDate: string,
  }),
  useMobileMargins: bool,
  startDatePlaceholderText: string,
  endDatePlaceholderText: string,
  screenReaderInputMessage: string,
  value: shape({
    startDate: instanceOf(Date),
    endDate: instanceOf(Date),
  }),
  timeSlots: arrayOf(propTypes.timeSlot),
};

export default injectIntl(DateRangeInputComponent);

export const NONE_PROVIDER = 'none';
export const TAPKEY_PROVIDER = 'tapkey';
// export const FLINKEY_PROVIDER = 'flinkey';

export const ACCESS_PROVIDERS_OPTIONS = [
  { value: TAPKEY_PROVIDER, text: 'Tapkey' },
  // { value: FLINKEY_PROVIDER, text: 'Flinkey' },
];
export const TAPKEY_URL = 'https://tapkey.com/';
// export const FLINKEY_URL = 'https://www.flinkey.com/';

export const ACCESS_PROVIDERS_MAP = {
  [TAPKEY_PROVIDER]: TAPKEY_URL,
  // [FLINKEY_PROVIDER]: FLINKEY_URL,
};

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { isScrollingDisabled } from "../../ducks/UI.duck";
import {
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
  LoginTapkey,
  LayoutWrapperAccessProviderSideNav,
} from "../../components";
import { TopbarContainer } from "../../containers";
import { connectWithTapkey } from "./TapkeyPage.helpers";
import { getTapkeyAccessToken, disconnectTapkey } from "../../ducks/Tapkey.duck";

import css from "./TapkeyPage.css";

export const TapkeyPageComponent = ({
  scrollingDisabled,
  intl,
  onGetTapkeyAccessToken,
  tokenError,
  currentUser,
  onDisconnectTapkey,
}) => {
  const tapkeyAccessToken = currentUser?.attributes?.profile?.privateData?.tapkeyToken ?? null;
  const title = intl.formatMessage({ id: 'TapkeyPage.title' });

  useEffect(() => {
    if (currentUser && !tapkeyAccessToken) {
      onGetTapkeyAccessToken(currentUser);
    }
  }, [currentUser, tapkeyAccessToken, onGetTapkeyAccessToken]);

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="TapkeyPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="TapkeyPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperAccessProviderSideNav currentTab="TapkeyPage" />
        <LayoutWrapperMain>
          <div className={css.content}>
            {tokenError ? <div className={css.error}>{tokenError?.message}</div> : null}
            <h1 className={css.title}>
              <FormattedMessage id="TapkeyPage.heading" />
            </h1>
            <LoginTapkey
              isAuthorized={!!tapkeyAccessToken}
              handleConnectWithTapkey={connectWithTapkey}
              intl={intl}
              currentUser={currentUser}
              handleDisconnectTapkey={onDisconnectTapkey}
            />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

const { bool } = PropTypes;

TapkeyPageComponent.defaultProps = {};

TapkeyPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  tokenError: state.Tapkey.getTokenError,
  scrollingDisabled: isScrollingDisabled(state),
});

const mapDispatchToProps = dispatch => ({
  onGetTapkeyAccessToken: (currentUser) => dispatch(getTapkeyAccessToken(currentUser)),
  onDisconnectTapkey: () => dispatch(disconnectTapkey()),
});

const TapkeyPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(TapkeyPageComponent);

export default TapkeyPage;

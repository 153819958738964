import React from 'react';
import { NamedLink } from '../index';
import { arrayOf } from 'prop-types';
import { propTypes } from '../../util/types';
import defaultImage from '../../images/default-avatar.png';

import css from './GroupUsersAvatars.css';

const AVATAR_SIZES_LARGE = '96px';

const GroupUsersAvatars = props => {
  const { users, isPopup } = props;

  return (
    <div className={isPopup ? css.groupUsersContainerPopup : css.groupUsersContainer}>
      {users.map((user, index) => {
        const u = user.data;
        const image = user.included?.find(({ type }) => type === 'image');
        const url = image ? image.attributes.variants['square-small2x'].url : defaultImage;

        return (
          <div className={css.coUsersItem} key={u.id.uuid}>
            <NamedLink className={css.coUsersLink} name="ProfilePage" params={{ id: u.id.uuid }}>
              <img
                className={css.coUsersImg}
                src={url}
                height={AVATAR_SIZES_LARGE}
                width={AVATAR_SIZES_LARGE}
                key={index}
                alt={index}
                title={u.attributes.profile.displayName}
              />
            </NamedLink>
          </div>
        );
      })}
    </div>
  );
};

GroupUsersAvatars.defaultProps = {
  users: [],
};

GroupUsersAvatars.propTypes = {
  users: arrayOf(propTypes.users),
};

export default GroupUsersAvatars;

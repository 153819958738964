import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { timeOptionHelper } from '../../util/timeOptionHelper';
import {
  storeOptionData,
  storedOptionData,
} from '../../containers/CheckoutPage/CheckoutPageSessionHelpers';
import classNames from 'classnames';
import config from '../../config';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
  CancelModal,
  Button,
} from '../../components';
import { SendMessageForm } from '../../forms';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import RevokeGrunt from './RevokeGrunt';
// import DeleteFlinkeyAssignment from './DeleteFlinkeyAssignment';
import RedirectToNativeApp from './RedirectToNativeApp';
import TapkeyNote from './TapkeyNote';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
} from './PanelHeading';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  // FLINKEY_PROVIDER,
  TAPKEY_PROVIDER,
  NONE_PROVIDER
 } from '../../constants/accessProviders';

import css from './TransactionPanel.css';

const { Money } = sdkTypes;

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      isCancelModalOpen: false,
      reviewSubmitted: false,
      tapkeyError: null,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    const { transaction, onGetGrunt } = this.props;
    this.isMobSaf = isMobileSafari();
    const accessProvider = transaction?.listing?.attributes?.publicData?.access_provider ?? null;
    if (accessProvider === TAPKEY_PROVIDER) {
      onGetGrunt({
        grantId: transaction.attributes.metadata.grantId,
        tapkeyOwnerId: transaction.provider.attributes.profile.publicData.tapkeyOwnerId,
        providerId: transaction.provider.id.uuid,
      });
    }
    // else if (
    //   accessProvider === FLINKEY_PROVIDER &&
    //   currentUser.id.uuid === transaction.provider.id.uuid
    // ) {
    //   const carId = transaction?.listing?.attributes?.publicData?.flinkeyCar ?? null;
    //   const userId = transaction?.customer?.id.uuid ?? null;
    //   const bookingStart = transaction?.booking?.attributes?.start ?? null;
    //   const bookingEnd = transaction?.booking?.attributes?.end ?? null;
    //   onGetAssignments(carId, userId, bookingStart, bookingEnd);
    // }
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage, transactionRole } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    const metadata = ensuredTransaction.attributes.metadata;

    const customerId = ensuredTransaction && ensuredTransaction.customer.id;
    const providerId = ensuredTransaction && ensuredTransaction.provider.id;

    if (!message) {
      return;
    }

    onSendMessage(ensuredTransaction.id, message, transactionRole, providerId, customerId, metadata)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onFetchTimeSlots,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      monthlyTimeSlots,
      nextTransitions,
      onFetchTransactionLineItems,
      fetchLineItemsInProgress,
      lineItems,
      fetchLineItemsError,
      userSelectedTimeOption,
      selectedTimeOptions,
      grunt,
      gruntError,
      onRevokeGrunt,
      onCancelBooking,
      onSendCancelMessage,
      // flinkeyAssignment,
      // flinkeyAssignmentDeleted,
      // onDeleteAssignments,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const accessProvider =
      currentTransaction.listing.attributes.publicData?.access_provider ?? null;

    const today = new Date();
    const bookingStart = currentTransaction?.booking?.attributes?.start;
    const showCancelBookingBtn = today < bookingStart;


    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    // const checkIfBookingPeriodInProgress = bookingStart => {
    //   const toDate = new Date();

    //   if (new Date(bookingStart).getTime() >= toDate.getTime()) {
    //     return false;
    //   }
    //   return true;
    // };

    // const handleDeleteFlinkeyAssignment = () => {
    //   const userId = currentUser?.id.uuid ?? null;
    //   onDeleteAssignments(flinkeyAssignment.assignment_id, userId);
    // };

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const isPriceNull = publicData && publicData.isPriceNull && publicData.isPriceNull;
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const recivedOption = timeOptionHelper(userSelectedTimeOption);

    if (userSelectedTimeOption !== '' && userSelectedTimeOption !== null) {
      storeOptionData(recivedOption, 'Option');
    }
    const option =
      userSelectedTimeOption !== '' && userSelectedTimeOption !== null
        ? recivedOption
        : Object.values(storedOptionData('Option'))[0];

    const isNightly = option === LINE_ITEM_NIGHT;
    const isDaily = option === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    let price = new Money(0, config.currency);

    if (currentListing.attributes.price.amount !== 0) {
      price = currentListing.attributes.price;
    } else {
      price =
        transaction.attributes &&
        transaction.attributes.lineItems &&
        transaction.attributes.lineItems.length > 0
          ? transaction.attributes.lineItems[0].unitPrice
          : transaction.attributes.price;
    }

    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptSale(currentTransaction.id)}
        onDeclineSale={() => onDeclineSale(currentTransaction.id)}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const isTapkeyError = this.state.tapkeyError ? (
      <p className={classNames(css.tapkeyError, css.genericError)}>{this.state.tapkeyError}</p>
    ) : gruntError ? (
      <p className={classNames(css.tapkeyError, css.genericError)}>{gruntError?.message}</p>
    ) : null;

    const classes = classNames(rootClassName || css.root, className);

    const isBookingAccepted = currentTransaction.attributes.lastTransition === 'transition/accept';
    const isCancelModalOpen = this.state.isCancelModalOpen;

    const handleCancelBooking = () => {
      const tapkeyOwnerId = currentProvider?.attributes?.profile?.publicData?.tapkeyOwnerId ?? null;
      const grantId = transaction.attributes.metadata.grantId;

      onCancelBooking(currentTransaction.id).then(() => {
        if (tapkeyOwnerId && grantId) {
          const {
            provider,
            booking: { attributes: { start } },
            listing
          } = currentTransaction;

          onRevokeGrunt({
            grunt,
            tapkeyOwnerId,
            timeStart: start,
            providerId: provider.id.uuid,
            listingId: listing.id.uuid,
          });
        } else {
          return Promise.resolve();
        }
      }).then(() => onSendCancelMessage({
        message: "Die Buchung des Objekts wurde storniert!",
        id: currentTransaction.id
      }));

      this.setState({ isCancelModalOpen: false });
    }

    const handleOpenCancelationModal = () => {
      this.setState({ isCancelModalOpen:  true });
    }

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
              listingId={currentListing.id && currentListing.id.uuid}
              listingDeleted={listingDeleted}
            />
            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
            />

            <div className={css.bookingDetailsMobile}>
              <AddressLinkMaybe
                rootClassName={css.addressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              <BreakdownMaybe
                transaction={currentTransaction}
                transactionRole={transactionRole}
                timeOptions={userSelectedTimeOption}
                isPriceNull={isPriceNull}
              />
            </div>

            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}
            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
            />
            {showSendMessageForm ? (
              <SendMessageForm
                formId={this.sendMessageFormName}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                inProgress={sendMessageInProgress}
                sendMessageError={sendMessageError}
                onFocus={this.onSendMessageFormFocus}
                onBlur={this.onSendMessageFormBlur}
                onSubmit={this.onMessageSubmit}
              />
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )}

            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}

            {accessProvider && accessProvider !== NONE_PROVIDER && (grunt)? (
              <>
                <TapkeyNote accessProvider={accessProvider} />
                <RedirectToNativeApp accessProvider={accessProvider} />
              </>
            ) : null}
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
                listingId={currentListing.id && currentListing.id.uuid}
                listingDeleted={listingDeleted}
              />

              <DetailCardHeadingsMaybe
                showDetailCardHeadings={stateData.showDetailCardHeadings}
                listingTitle={listingTitle}
                subTitle={bookingSubTitle}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              {stateData.showBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  lineItems={lineItems}
                  fetchLineItemsError={fetchLineItemsError}
                  selectedTimeOptions={selectedTimeOptions}
                  timeOption={userSelectedTimeOption}
                />
              ) : null}
              <BreakdownMaybe
                className={css.breakdownContainer}
                transaction={currentTransaction}
                transactionRole={transactionRole}
                timeOptions={userSelectedTimeOption}
                isPriceNull={isPriceNull}
              />

              {isCustomer && isBookingAccepted ?
                <div className={css.cancellationPanel}>
                  { showCancelBookingBtn ? <p className={css.cancellationTip1}>
                    <FormattedMessage
                      id="TransactionPanel.cancellationTip1"
                      values={{
                        value: <b><FormattedMessage id="TransactionPanel.cancellationTip1bold" /></b>
                      }}
                    />
                  </p> : null}
                  { showCancelBookingBtn ? <Button className={css.cancelBtn} onClick={handleOpenCancelationModal} >
                    <FormattedMessage id="TransactionPanel.openCancelModal" />??
                  </Button> : null}
                  <p className={css.cancellationTip2}>
                    <FormattedMessage
                      id="TransactionPanel.cancellationTip2"
                      values={{
                        value1: <b><FormattedMessage id="TransactionPanel.cancellationTip2bold" /></b>,
                        value2: <b><FormattedMessage id="TransactionPanel.cancellationTip2bold2" /></b>,
                        br: <br/>
                      }}
                    />
                  </p>
                </div>
                : null}
              {/* {isProvider &&
                grunt &&
                grunt.state === 'Ok' &&
                currentTransaction.attributes &&
                currentTransaction.attributes.lastTransition === 'transition/accept' ? (
                  <RevokeGrunt handleRevokeLock={onRevokeLock} />
                ) : null
              } */}
              {isTapkeyError}

              {/* {isProvider &&
              flinkeyAssignment &&
              !flinkeyAssignmentDeleted &&
              !checkIfBookingPeriodInProgress(flinkeyAssignment.start) ? (
                <DeleteFlinkeyAssignment
                  handleDeleteFlinkeyAssignment={handleDeleteFlinkeyAssignment}
                />
              ) : null} */}

              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
        <CancelModal
          id="CancelModal"
          isOpen={isCancelModalOpen}
          onCloseModal={() => this.setState({ isCancelModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          handleCancelBooking={handleCancelBooking}
        />
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // from injectIntl
  intl: intlShape,

  // line items
  onFetchTransactionLineItems: func,
  fetchLineItemsInProgress: bool,
  lineItems: array,
  fetchLineItemsError: propTypes.error,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;

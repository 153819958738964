import React, { useState } from 'react';
import { func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, intlShape, injectIntl, FormattedDate } from '../../util/reactIntl';
import { propTypes, DATE_TYPE_DATETIME, DATE_TYPE_DATE } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import {createSlug, stringify} from '../../util/urlHelpers';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  AvatarLarge,
  NamedLink,
  Avatar,
  Button,
  Modal,
} from '../../components';
import { TopbarContainer } from '../../containers';

import { loadData, setInitialValues, requestDeleteAvailabilityException} from './ExceptionPage.duck';

import css from './ExceptionPage.css';
import DetailCardImage from "../../components/TransactionPanel/DetailCardImage";
import {createResourceLocatorString} from "../../util/routes";
import routeConfiguration from "../../routeConfiguration";

const BookingPeriod = props => {
  const { startDate, endDate, dateType, timeZone } = props;

  const timeFormatOptions =
    dateType === DATE_TYPE_DATE
      ? {
          weekday: 'long',
        }
      : {
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
        };

  const dateFormatOptions = {
    month: 'short',
    day: 'numeric',
  };

  const timeZoneMaybe = timeZone ? { timeZone } : null;

  return (
      <div className={css.bookingPeriod}>
        <div className={css.bookingPeriodSection}>
          <div className={css.dayLabel}>
            <FormattedMessage id="BookingBreakdown.bookingStart" />
          </div>
          <div className={css.dayInfo}>
            <FormattedDate value={startDate} {...timeFormatOptions} {...timeZoneMaybe} />
          </div>
          <div className={css.itemLabel}>
            <FormattedDate value={startDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </div>
        </div>

        <div className={css.bookingPeriodSectionRigth}>
          <div className={css.dayLabel}>
            <FormattedMessage id="BookingBreakdown.bookingEnd" />
          </div>
          <div className={css.dayInfo}>
            <FormattedDate value={endDate} {...timeFormatOptions} {...timeZoneMaybe} />
          </div>
          <div className={css.itemLabel}>
            <FormattedDate value={endDate} {...dateFormatOptions} {...timeZoneMaybe} />
          </div>
        </div>
      </div>
  );
};

const createListingLink = (listingId, label, listingDeleted, searchParams = {}, className = '') => {
  if (!listingDeleted) {
    const params = { id: listingId, slug: createSlug(label) };
    const to = { search: stringify(searchParams) };
    return (
      <NamedLink className={className} name="ListingPage" params={params} to={to}>
        {label}
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

export const ExceptionPageComponent = props => {
  const {
    currentUser,
    deleteExceptionError,
    deleteExceptionInProgress,
    location,
    history,
    intl,
    scrollingDisabled,
    getOwnListing,
    onDeleteAvailabilityException,
    onManageDisableScrolling,
    availabilityException,
  } = props;
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const listingId = location?.state?.listingId;
  const exId = location?.state?.exId;
  const currentListing = ensureOwnListing(getOwnListing(listingId));
  const listingLoaded = !!currentListing.id;
  const listingDeleted = listingLoaded && currentListing.attributes.deleted;
  const listingTitle = listingLoaded ? currentListing.attributes.title : 'Title';
  const listingLink = listingId && listingLoaded ? createListingLink(listingId.uuid, listingTitle, listingDeleted) : null;
  const displayName = currentUser?.attributes?.profile?.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const today = new Date();
  const exception = availabilityException ? availabilityException.find(({id}) => id.uuid === exId) : null;

  const disabled = !(exception && today < exception.attributes.start);

  const localStartDate = exception && exception.attributes.start;
  const endDay = exception && exception.attributes.end;
  const dateType = DATE_TYPE_DATETIME;
  const timeZone = currentListing?.attributes?.availabilityPlan?.timezone ?? null;

  const deleteExceptionErrorMessage = deleteExceptionError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;

  return (
    <Page
      title={intl.formatMessage({ id: 'TransactionPage.title' }, { title: 'listingTitle' })}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          {listingLoaded && <div className={css.container}>
            <div className={css.txInfo}>
              <DetailCardImage
                rootClassName={css.imageWrapperMobile}
                avatarWrapperClassName={css.avatarWrapperMobile}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentUser}
                isCustomer={true}
                listingId={currentListing.id && currentListing.id.uuid}
                listingDeleted={listingDeleted}
              />
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentUser} className={css.avatarDesktop}/>
              </div>

              {currentUser && listingLoaded && (
                <h1 className={css.headingSale}>
                  <span className={css.mainTitle}>
                    <FormattedMessage
                      id="TransactionPanel.saleAcceptedTitle"
                      values={{customerName: displayName, listingLink}}
                    />
                  </span>
                </h1>
              )}

              <div className={css.feedContainer}>
                <h3 className={css.feedHeading}>
                  <FormattedMessage id="TransactionPanel.activityHeading" />
                </h3>
                <div className={css.feed}>
                  <div className={css.messageItem}>
                    <div className={css.message}>
                      <Avatar className={css.avatar} user={currentUser} disableProfileLink />
                      <div>
                        <p className={css.messageContent}>
                          <FormattedMessage
                            id="ActivityFeed.transitionAccept"
                            values={{displayName}}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={css.asideDesktop}>
              <div className={css.detailCard}>
                <DetailCardImage
                  avatarWrapperClassName={css.avatarWrapperDesktop}
                  listingTitle={listingTitle}
                  image={firstImage}
                  provider={currentUser}
                  isCustomer={true}
                  listingId={currentListing.id && currentListing.id.uuid}
                  listingDeleted={listingDeleted}
                />

                  {exception ? <BookingPeriod
                    startDate={localStartDate}
                    endDate={endDay}
                    dateType={dateType}
                    timeZone={timeZone}
                  /> : null}

                <Modal
                  id={'calcelModal'}
                  isOpen={isCancelModalOpen}
                  onClose={() => setCancelModalOpen(false)}
                  onManageDisableScrolling={onManageDisableScrolling}
                  closeButtonMessage={'close'}
                >
                  <h1 className={css.modalTitle}>
                    <FormattedMessage id="TransactionPanel.cancelBookingModalTitle"/>
                  </h1>
                  <ul className={css.cancellationTips}>
                    <li className={css.cancellationTipItem}><FormattedMessage
                      id="TransactionPanel.tip1"/></li>
                    <li className={css.cancellationTipItem}><FormattedMessage
                      id="TransactionPanel.tip2"/></li>
                    <li className={css.cancellationTipItem}><FormattedMessage
                      id="TransactionPanel.tip3"/></li>
                  </ul>
                  <Button
                    className={css.cancellationBtn}
                    inProgress={deleteExceptionInProgress}
                    disabled={deleteExceptionInProgress}
                    onClick={() => onDeleteAvailabilityException({id: exId}).then(() => history.push(createResourceLocatorString('InboxPage', routeConfiguration(), {tab: 'exceptions'}, {})))}>
                    <FormattedMessage id="TransactionPanel.cancelBtn" />
                  </Button>
                </Modal>

                {deleteExceptionErrorMessage}
              </div>

              <div className={css.buttonHolder}>
                <Button disabled={disabled} className={css.cancelBtn} onClick={() => setCancelModalOpen(!isCancelModalOpen)} >
                  <FormattedMessage id="TransactionPanel.openCancelModal" />
                </Button>
              </div>

            </div>
          </div>}
        </LayoutWrapperMain>
        <LayoutWrapperFooter className={css.footer}>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

ExceptionPageComponent.defaultProps = {
  currentUser: null,
};

ExceptionPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    showListingError,
    deleteExceptionError,
    deleteExceptionInProgress,
    deleteExceptionSuccess,
    availabilityException,
  } = state.ExceptionPage;
  const { currentUser } = state.user;

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    getOwnListing,
    showListingError,
    deleteExceptionError,
    deleteExceptionInProgress,
    deleteExceptionSuccess,
    availabilityException,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onDeleteAvailabilityException: params =>
      dispatch(requestDeleteAvailabilityException(params)),
  };
};

const ExceptionPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ExceptionPageComponent);

ExceptionPage.loadData = loadData;
ExceptionPage.setInitialValues = setInitialValues;

export default ExceptionPage;

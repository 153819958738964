import config from '../config';

function timeOptionHelper(timeOptions) {
  let option = config.bookingUnitType;
  switch (timeOptions) {
    case 'hourly':
      option = config.bookingUnitType;
      break;
    case 'daily':
      option = config.bookingDailyType;
      break;
    case 'weekly':
      option = config.bookingDailyType;
      break;
    case 'nightly':
      option = config.bookingNightlyType;
      break;
    default:
      break;
  }

  return option;
}

function getAliasHelper(timeOption, isPriceNull) {
  // let alias = config.bookingProcessAliasHourly;

  const alias = isPriceNull ? config.bookingProcessAliasHourlyNoPrice : config.bookingProcessAliasHourly;

  // switch (timeOption) {
  //   case 'hourly':
  //     alias = isPriceNull
  //       ? config.bookingProcessAliasHourlyNoPrice
  //       : config.bookingProcessAliasHourly;
  //     break;
  //   case 'daily':
  //     alias = isPriceNull
  //       ? config.bookingProcessAliasHourlyNoPrice
  //       : config.bookingProcessAliasHourly;
  //     break;
  //   case 'weekly':
  //     alias = isPriceNull
  //       ? config.bookingProcessAliasHourlyNoPrice
  //       : config.bookingProcessAliasHourly;
  //     break;
  //   case 'nightly':
  //     alias = isPriceNull
  //       ? config.bookingProcessAliasHourlyNoPrice
  //       : config.bookingProcessAliasHourly;
  //     break;
  //   default:
  //     break;
  // }

  return alias;
}

export { timeOptionHelper, getAliasHelper };

import React from 'react';
import { compose } from 'redux';
import { string } from 'prop-types';
import {injectIntl,  FormattedMessage } from '../../util/reactIntl';

import { ACCESS_PROVIDERS_MAP } from '../../constants/accessProviders';

import css from './ListingPage.css';

const SectionAccessMaybe = props => {
  const {
    accessProvider,
    intl,
  } = props;

  if (!accessProvider) {
    return null;
  }

  const description = intl.formatMessage(
    { id: 'ListingPage.accessDescription' },
    {
      providerName: `<strong>${accessProvider}</strong>`,
      providerUrl: ACCESS_PROVIDERS_MAP[accessProvider]
    }
  );

  return (
    <div className={css.sectionDescription}>
      <h2 className={css.accessTitle}>
        <FormattedMessage id="ListingPage.accessTitle" />
      </h2>
      <p dangerouslySetInnerHTML={{__html: description}} />
    </div>
  );
}

SectionAccessMaybe.propTypes = {
  accessProvider: string,
};

export default compose(injectIntl)(SectionAccessMaybe);

import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemBasePriceMaybe = props => {
  const { transaction, unitType, intl } = props;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const translationKey = isNightly
    ? 'BookingBreakdown.baseUnitNight'
    : isDaily
    ? 'BookingBreakdown.baseUnitDay'
    : 'BookingBreakdown.baseUnitQuantity';

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';

  let unitPurchase;

  if (unitType === 'line-item/units') {
    unitPurchase = transaction.attributes.lineItems.find(
      item => item.code === unitType && !item.reversal
    );
  } else {
    unitPurchase = transaction.attributes.lineItems.find(
      item =>
        (item.code === 'line-item/daily' && !item.reversal) ||
        (item.code === 'line-item/nightly' && !item.reversal) ||
        (item.code === 'line-item/weekly' && !item.reversal) ||
        (item.code === 'line-item/units' && !item.reversal)
    );

    if (!unitPurchase) {
      unitPurchase = transaction.attributes.lineItems.find(
        item =>
          (item.code === 'line-item/night' && !item.reversal) ||
          (item.code === 'line-item/day' && !item.reversal)
      );
    }
  }

  const quantity = unitPurchase && unitPurchase.quantity ? unitPurchase.quantity.toString() : null;
  const unitPrice =
    unitPurchase && unitPurchase.unitPrice ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total =
    unitPurchase && unitPurchase.lineTotal ? formatMoney(intl, unitPurchase.lineTotal) : null;

  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  // unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;

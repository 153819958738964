import React, {Component} from 'react';
import css from './MySlider.css';


//import slide1_bg from '../../assets/sharepar_home_slide_01.jpg';
//import slide2_bg from '../../assets/sharepar_home_slide_02.jpg';
//import slide3_bg from '../../assets/sharepar_home_slide_03.jpg';
import sharepar_home from '../../assets/sharepar_home_2024.jpg';


// Data for carousel
const carouselSlidesData = [
  {
    image:sharepar_home,
    content:"Teile deinen Camper ..."
  }
];

// Component for left arrow
/*
class SliderLeftArrow extends Component {
  render() {
    return (
      <a
        href="#"
        className={css.slider__arrow__left}
        onClick={this.props.onClick}
      >
        <span className={css.fa_angle_left}>&lsaquo;</span>
      </a>
    );
  }
}
*/

// Component for right arrow
/*
class SliderRightArrow extends Component {
  render() {
    return (
      <a
        href="#"
        className={css.slider__arrow__right}
        onClick={this.props.onClick}
      >
        <span className={css.fa_angle_right}>&rsaquo;</span>
      </a>
    );
  }
}
*/

// Component for slide
class CarouselSlide extends Component {

  render() {
    return (
      <li
        className={
          this.props.index < this.props.activeIndex
            ? css.slider__slide__active
            : css.slider__slide
        }
        style={{
        backgroundColor: 'none',
      }}
      >
        <div className={css.carousel_slide__bg}  style={{
          backgroundImage: `url(${this.props.slide.image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}>
        </div>
        {/*<div className={css.carousel_slide__content}>{this.props.slide.content}</div>*/}
      </li>
    );
  }
}



class MySliderComponent extends Component {
  constructor(props) {
    super(props);
    this.slides={carouselSlidesData};
    this.goToSlide = this.goToSlide.bind(this);
    this.goToPrevSlide = this.goToPrevSlide.bind(this);
    this.goToNextSlide = this.goToNextSlide.bind(this);
    this.runSlider = this.runSlider.bind(this);

    this.mySliderInterval = null;
    this.mySliderTimeout = null;


    this.state = ({
      activeIndex: 1
    });

    //this.runSlider()

  }

  runSlider(){
    this.mySliderInterval = setInterval(() => {
      let index = this.state.activeIndex;
      ++index;
      this.setState({
        activeIndex: index
      });
      if(index>2){
        clearInterval(this.mySliderInterval);

        this.mySliderTimeout = setTimeout(() => {
          this.runSlider();
          this.setState({
            activeIndex: 1
          });
        }, 8000);
      }
    }, 5000);
    return () => clearInterval(this.mySliderInterval);
  }

  componentWillUnmount(){
    // clearing the intervals
    if(this.mySliderInterval) clearInterval(this.mySliderInterval)
    if(this.mySliderTimeout) clearTimeout(this.mySliderTimeout)
  }



  goToSlide(index) {
    this.setState({
      activeIndex: index
    });
  }




  goToPrevSlide(e) {
    e.preventDefault();

    let index = this.state.activeIndex;
    let { slides } = this.props;
    let slidesLength = slides.length;

    if (index < 1) {
      index = slidesLength;
    }

    --index;

    this.setState({
      activeIndex: index
    });
  }

  goToNextSlide(e) {
    e.preventDefault();

    let index = this.state.activeIndex;
    let { slides } = this.props;
    let slidesLength = slides.length - 1;

    if (index === slidesLength) {
      //index = -1;
    }

    ++index;

    this.setState({
      activeIndex: index
    });
  }


  render() {
    return (
      <div className={css.slider}>
        {/*<SliderLeftArrow onClick={e => this.goToPrevSlide(e)} />*/}
          <ul className={css.slider__slides}>
            {this.props.slides.map((slide, index) =>
              <CarouselSlide
                key={index}
                index={index}
                activeIndex={this.state.activeIndex}
                slide={slide}
              />
            )}
          </ul>
        {/*<SliderRightArrow onClick={e => this.goToNextSlide(e)} />*/}
      </div>
    );
  }
}

class MySlider extends Component {
  render(){return (<MySliderComponent slides={carouselSlidesData} />);}
}


export default MySlider;

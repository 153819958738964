import React from 'react';
import classNames from 'classnames';
import { DATE_TYPE_DATETIME } from '../../util/types';
import { ensureListing } from '../../util/data';
import { BookingBreakdown } from '../../components';
import { timeOptionHelper } from '../../util/timeOptionHelper';
import {
  storeOptionData,
  storedOptionData,
} from '../../containers/CheckoutPage/CheckoutPageSessionHelpers';

import css from './TransactionPanel.css';

// Functional component as a helper to build BookingBreakdown
const BreakdownMaybe = props => {
  const {
    className,
    rootClassName,
    breakdownClassName,
    transaction,
    transactionRole,
    timeOptions,
    isPriceNull,
  } = props;
  const loaded = transaction && transaction.id && transaction.booking && transaction.booking.id;
  const listingAttributes = ensureListing(transaction.listing).attributes;
  const timeZone =
    loaded && listingAttributes.availabilityPlan
      ? listingAttributes.availabilityPlan.timezone
      : 'Etc/UTC';

  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(breakdownClassName || css.breakdown);

  const recivedOption = timeOptionHelper(timeOptions);

  if (timeOptions !== '' && timeOptions !== null) {
    storeOptionData(recivedOption, 'Option');
  }
  const option =
    timeOptions !== '' && timeOptions !== null
      ? recivedOption
      : Object.values(storedOptionData('Option'))[0];

  return loaded ? (
    <div className={classes}>
      <BookingBreakdown
        className={breakdownClasses}
        userRole={transactionRole}
        unitType={option}
        transaction={transaction}
        booking={transaction.booking}
        dateType={DATE_TYPE_DATETIME}
        timeZone={timeZone}
        isPriceNull={isPriceNull}
      />
    </div>
  ) : null;
};

export default BreakdownMaybe;

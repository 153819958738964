import React from 'react';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { Form, Button } from '../../components';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './EditListingMembersForm.css';

export const EditListingMembersFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{
      ...arrayMutators,
    }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        invalid,
        saveActionMsg,
        updateInProgress,
        fetchErrors,
        inviteError,
        form,
        emailInviteToListingProgress,
        existEmail,
        stripeConnected,
        isPastDraft,
        isPriceNull,
        intl,
      } = formRenderProps;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage =
        updateListingError || inviteError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingLocationForm.updateFailed" />
          </p>
        ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const doubleEmail = existEmail ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingMembersPanel.existEmailMessage" />
        </p>
      ) : null;

      const noStripe = isPriceNull ? (
        false
      ) : !stripeConnected ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingMembersPanel.noStripe" />
        </p>
      ) : null;

      const isListingDraft = !isPastDraft ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingMembersPanel.listingIsDraft" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitInProgress = updateInProgress || emailInviteToListingProgress;
      const submitDisabled =
        invalid || disabled || submitInProgress || emailInviteToListingProgress;

      const messagePlaceholder = intl.formatMessage({
        id: 'EditListingMembersPanel.messagePlaceholder',
      });

      const messageDescription = intl.formatMessage({
        id: 'EditListingMembersPanel.messageDescription',
      });

      return (
        <Form className={classes} onSubmit={event => {
          const promise = handleSubmit(event);
          promise &&
          promise.then(() => {
            form.reset();
          });
          return promise;
        }}>
          {errorMessage}
          {errorMessageShowListing}
          {doubleEmail}
          <div className={css.row}>
            <Field name="newMember" component="input" placeholder="email" type="email" />
          </div>
          <div className={css.row}>
            <Field name="message" component="input" placeholder={messagePlaceholder} type="text" />
          </div>
          <span className={css.hint}>{messageDescription}</span>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled || isPriceNull ? false : !stripeConnected || !isPastDraft}
          >
            {saveActionMsg}
          </Button>
          {noStripe}
          {isListingDraft}
        </Form>
      );
    }}
  />
);

EditListingMembersFormComponent.defaultProps = {};

EditListingMembersFormComponent.propTypes = {};

export default compose(injectIntl)(EditListingMembersFormComponent);

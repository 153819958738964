import React from "react";
import { FormattedMessage } from '../../util/reactIntl';
import { Button, InlineTextButton } from '../../components';

const LoginTapkey = ({
  isAuthorized,
  intl,
  handleConnectWithTapkey,
  currentUser,
  handleDisconnectTapkey
}) => {
  const btnLabel = intl.formatMessage({ id: "LoginTapkey.btnLabel" });
  const removeTapleyConnection = intl.formatMessage({ id: "LoginTapkey.removeTapkeyConnection" });

  return (
    <div>
      {isAuthorized ? <>
        <FormattedMessage id="LoginTapkey.tapkeyConnected" />
        <InlineTextButton onClick={handleDisconnectTapkey}>{removeTapleyConnection}</InlineTextButton>
      </> :
      <Button onClick={() => handleConnectWithTapkey(currentUser)}>{btnLabel}</Button>}
    </div>
  )
}

export default LoginTapkey;

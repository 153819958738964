import React, { useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const TapkeyRedirectPageContainerComponent = props => {
  useEffect(() => {
    let tapkeyParams = window.localStorage.getItem();
    if (!tapkeyParams) {
      return ;
    }
    tapkeyParams = JSON.parse(tapkeyParams);
    if (tapkeyParams.code_verifier && tapkeyParams.state && tapkeyParams.client_id) {
      const queryParams = new URLSearchParams(window.location.search);
      if (tapkeyParams.state === queryParams.get('state')) {
        const headers = new Headers();
        headers.append("Content-Type", "application/x-www-form-urlencoded");

        const body = new URLSearchParams();
        body.append('client_id', tapkeyParams.client_id);
        body.append('redirect_uri', tapkeyParams.redirect_uri);
        body.append('code_verifier', tapkeyParams.code_verifier);
        body.append('grant_type', 'authorization_code');
        body.append('code', queryParams.get('code'));

        const requestOptions = {
          method: 'POST',
          headers,
          body,
          redirect: 'follow'
        };
        fetch('https://login.tapkey.com/connect/token', requestOptions)
          .then(response => response.json())
          .then(token => window.localStorage.setItem("", JSON.stringify(token)))
          .catch(error => window.localStorage.setItem("", JSON.stringify({error: 'Get token error'})))
          .finally(() => window.close())
        ;
      }
    }
  }, []);

  return <div>Waiting...</div>;
};

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => ({
});

const TapkeyRedirectPageContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(TapkeyRedirectPageContainerComponent);

export default TapkeyRedirectPageContainer;

import axios from 'axios';
import Decimal from 'decimal.js';

import { types as sdkTypes, transit } from './sdkLoader';
import config from '../config';

const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };
  return window
    .fetch(url, options)
    .then(res => {
      if (res.status >= 400) {
        const e = new Error('Local API request failed');
        e.apiResponse = res;
        throw e;
      }
      return res;
    })
    .then(res => {
      const contentTypeHeader = res.headers.get('Content-Type');
      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;
      if (contentType === 'application/transit+json') {
        return res.text().then(deserialize);
      } else if (contentType === 'application/json') {
        return res.json();
      }
      return res.text();
    });
};

const get = path => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'GET',
    credentials: 'include',
    cache: 'no-store',
    headers: {
      'Content-Type': 'application/transit+json',
    },
  };
  return window
    .fetch(url, options)
    .then(res => {
      if (res.status >= 400) {
        const e = new Error('Local API request failed');
        e.apiResponse = res;
        throw e;
      }
      return res;
    })
    .then(res => {
      const contentTypeHeader = res.headers.get('Content-Type');
      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;
      if (contentType === 'application/transit+json') {
        return res.text().then(deserialize);
      } else if (contentType === 'application/json') {
        return res.json();
      }
      return res.text();
    });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

export const metadataChangeRequest = body => {
  return post('/api/transaction-metadata', body);
};

export const changeCountNotification = body => {
  return post('/api/user-count-notification', body);
};

export const updateCountNotification = body => {
  return post('/api/user-all-transactions', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the booking data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

export const makeTapkeyRequest = async (path, method, data, tapkeyToken) => {
  const tapkeyBaseUrl = process.env.REACT_APP_TAPKEY_BASE_URL;

  try {
    if (!tapkeyToken) {
      throw new Error('Tapkey token does not exist!');
    }
    const { accessToken } = tapkeyToken;

    const headers = {
      Authorization: `${accessToken.token_type} ${accessToken.access_token}`,
    };

    const params = {
      url: `${tapkeyBaseUrl}${path}`,
      method,
      headers,
      data,
    };

    return await axios(params);
  } catch (err) {
    console.log(err);
    return err.response || err.message;
  }
};

export const getListingAuthor = userId =>
  get(
    `/api/listing-author?${new URLSearchParams({
      id: userId,
    })}`
  );

export const getFlinkeyAssignments = (carId, userId, bookingStart, bookingEnd) =>
  get(
    `/api/flinkey-assignments?${new URLSearchParams({
      carId,
      userId,
      bookingStart,
      bookingEnd,
    })}`
  );

export const getFlinkeyToken = (email, password, userId) =>
  get(
    `/api/flinkey-token?${new URLSearchParams({
      email,
      password,
      userId,
    })}`
  );

export const fetchFlinkeyCredentialsExist = userId =>
  get(`/api/flinkey-credentials-exist?${new URLSearchParams({ userId })}`);

export const fetchDisconnectFlinkey = body => post('/api/flinkey-disconnect-user', body);

export const createAssignments = body => post('/api/flinkey-assignments', body);

export const createFlinkeyCar = body => post('/api/flinkey-car', body);

export const uninstallFlinkeyCarFromBox = body => post('/api/uninstall-car', body);

export const deleteFlinkeyAssignments = body => post('/api/delete-assignments', body);

export const getInvitedUsersAPI = body => post('/api/get-invited-users', body);
// export const createFlinkeyUser = body => post('/api/create-flinkey-user', body);

import { storableError } from '../util/errors';
import { getFlinkeyToken, fetchFlinkeyCredentialsExist, fetchDisconnectFlinkey } from "../util/api";


// ================ Action types ================ //

export const FLINKEY_SIGNUP_REQUEST = 'app/Flinkey/FLINKEY_SIGNUP_REQUEST';
export const FLINKEY_SIGNUP_SUCCESS = 'app/Flinkey/FLINKEY_SIGNUP_SUCCESS';
export const FLINKEY_SIGNUP_ERROR = 'app/Flinkey/FLINKEY_SIGNUP_ERROR';

export const FLINKEY_CREDENTIAL_EXIST_REQUEST = 'app/Flinkey/FLINKEY_CREDENTIAL_EXIST_REQUEST';
export const FLINKEY_CREDENTIAL_EXIST_SUCCESS = 'app/Flinkey/FLINKEY_CREDENTIAL_EXIST_SUCCESS';
export const FLINKEY_CREDENTIAL_EXIST_ERROR = 'app/Flinkey/FLINKEY_CREDENTIAL_EXIST_ERROR';

export const DISCONNECT_FLINKEY_REQUEST = 'app/Flinkey/DISCONNECT_FLINKEY_REQUEST';
export const DISCONNECT_FLINKEY_SUCCESS = 'app/Flinkey/DISCONNECT_FLINKEY_SUCCESS';
export const DISCONNECT_FLINKEY_ERROR = 'app/Flinkey/DISCONNECT_FLINKEY_ERROR';

// ================ Reducer ================ //

const initialState = {
  flinkeyCredentialsExist: false,
  flinkeyCredentialsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {

    case FLINKEY_CREDENTIAL_EXIST_REQUEST:
    case FLINKEY_SIGNUP_REQUEST:
    case DISCONNECT_FLINKEY_REQUEST:
      return { ...state, flinkeyCredentialsError: null };
    case FLINKEY_CREDENTIAL_EXIST_SUCCESS:
    case FLINKEY_SIGNUP_SUCCESS:
    case DISCONNECT_FLINKEY_SUCCESS:
      return { ...state, flinkeyCredentialsExist: payload };
    case FLINKEY_CREDENTIAL_EXIST_ERROR:
    case FLINKEY_SIGNUP_ERROR:
    case DISCONNECT_FLINKEY_ERROR:
      return { ...state, flinkeyCredentialsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const flinkeySignUpRequest = () => ({ type: FLINKEY_SIGNUP_REQUEST });
export const flinkeySignUpSuccess = (token) => ({ type: FLINKEY_SIGNUP_SUCCESS, payload: token });
export const flinkeySignUpError = (error) => ({ type: FLINKEY_SIGNUP_ERROR, payload: error });

export const flinkeyCredentialExistRequest = () => ({ type: FLINKEY_CREDENTIAL_EXIST_REQUEST });
export const flinkeyCredentialExistSuccess = (payload) => ({ type: FLINKEY_CREDENTIAL_EXIST_SUCCESS, payload });
export const flinkeyCredentialExistError = (error) => ({ type: FLINKEY_CREDENTIAL_EXIST_ERROR, payload: error });

export const disconnectFlinkeyRequest = () => ({ type: DISCONNECT_FLINKEY_REQUEST });
export const disconnectFlinkeySuccess = (payload) => ({ type: DISCONNECT_FLINKEY_SUCCESS, payload });
export const disconnectFlinkeyError = (error) => ({ type: DISCONNECT_FLINKEY_ERROR, payload: error });

// ================ Thunks ================ //

export const signUpFlinkey = ({email, password}) => async (dispatch, getState) => {
  dispatch(flinkeySignUpRequest());

  const currentUser = getState().user.currentUser;

  try {
    const response = await getFlinkeyToken(email, password, currentUser.id.uuid);

    dispatch(flinkeySignUpSuccess(response.data));
  } catch(err) {
    dispatch(flinkeySignUpError(storableError(err)));
  }
}

export const flinkeyCredentialsExist = (userId) => async (dispatch) => {
  dispatch(flinkeyCredentialExistRequest());

  try {
    const response = await fetchFlinkeyCredentialsExist(userId);

    dispatch(flinkeyCredentialExistSuccess(response.data));
  } catch(err) {
    dispatch(flinkeyCredentialExistError(storableError(err)));
  }
}

export const disconnectFlinkey = (userId) => async (dispatch) => {
  dispatch(disconnectFlinkeyRequest());

  try {
    await fetchDisconnectFlinkey({ userId });

    dispatch(disconnectFlinkeySuccess(false));
  } catch(err) {
    dispatch(disconnectFlinkeyError(storableError(err)));
  }
}

import React, { useState, useEffect } from 'react';
import * as validators from '../../util/validators';
import { FormattedMessage } from '../../util/reactIntl';

import { Modal, PrimaryButton } from '../../components';

import css from './ModalInviteForm.css';

const ModalInviteForm = props => {
  const {
    id,
    isOpen,
    onClose,
    onManageDisableScrolling,
    intl,
    handleSubmit,
    currentUser,
    value,
    emailInviteError,
    emailInviteSuccess,
    emailInviteProgress,
  } = props;

  const [inputEmailValue, setInputEmailValue] = useState(value);
  const [buttonSubmitDisabled, setButtonSubmitDisabled] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(null);

  useEffect(() => {
    if (inputEmailValue !== '') {
      setButtonSubmitDisabled(false);
    } else {
      setButtonSubmitDisabled(true);
    }
  }, [inputEmailValue]);

  const emailInvalidMessage = intl.formatMessage({
    id: 'ContactDetailsForm.emailInvalid',
  });
  const emailValid = validators.emailFormatValid(emailInvalidMessage);

  const emailLabel = intl.formatMessage({
    id: 'ModalInviteForm.emailLabel',
  });
  const emailPlaceholder = intl.formatMessage({
    id: 'ModalInviteForm.emailPlaceholder',
  });

  function handleChange(e) {
    setInputEmailValue(e.target.value);
    
    const validEmail = emailValid(e.target.value);
    setEmailIsValid(validEmail);
  }

  function sendForm(e) {
      handleSubmit(e);
      setInputEmailValue('');
  }

  return (
    <Modal
      id={id}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div className={css.modalContainer}>
        <p className={css.modalTitle}>
          <FormattedMessage id="ModalInviteForm.inviteMember" />
        </p>
        <form onSubmit={e => sendForm(e)} className={css.formContainer}>
          <div className={css.field}>
            <label className={css.label}>{emailLabel}</label>
            <input
              className={css.input}
              type="email"
              placeholder={emailPlaceholder}
              name="email"
              id="email"
              value={inputEmailValue}
              onChange={handleChange}
            />
          </div>
          <p className={css.inviteSend}>
            {emailInviteSuccess ? <FormattedMessage id="ModalInviteForm.inviteSend" /> : null}
          </p>
          <p className={css.emailErr}>
            {emailIsValid !== null && emailIsValid !== undefined && inputEmailValue.length > 4
              ? emailIsValid
              : null}
          </p>

          <PrimaryButton
            type="submit"
            disabled={
              buttonSubmitDisabled && currentUser ? true : false || emailIsValid !== undefined
            }
            className={`${
              emailInviteError ? css.inviteNewMemberButtonError : css.inviteNewMemberButton
            }`}
            inProgress={emailInviteProgress}
          >
            {emailInviteError ? (
              <FormattedMessage id="ModalInviteForm.buttonError" />
            ) : (
              <FormattedMessage id="ModalInviteForm.button" />
            )}
          </PrimaryButton>
        </form>
      </div>
    </Modal>
  );
};

export default ModalInviteForm;

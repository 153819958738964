import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';

import { Button, Modal } from '../../components';

import css from './CancelModal.module.css';

const CancelModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    handleCancelBooking,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'TransactionPanel.closeLabel' });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={closeButtonMessage}
    >
      <h1 className={css.modalTitle}>
        <FormattedMessage id="TransactionPanel.cancelBookingModalTitle" />
      </h1>
      <ul className={css.cancellationTips}>
        <li className={css.cancellationTipItem}><FormattedMessage id="TransactionPanel.tip1" /></li>
        <li className={css.cancellationTipItem}><FormattedMessage id="TransactionPanel.tip2" /></li>
        <li className={css.cancellationTipItem}><FormattedMessage id="TransactionPanel.tip3" /></li>
      </ul>
      <Button onClick={handleCancelBooking} className={css.cancellationBtn}>
        <FormattedMessage id="TransactionPanel.cancelBtn" />
      </Button>
    </Modal>
  );
};

const { string } = PropTypes;

CancelModal.defaultProps = {
  className: null,
  rootClassName: null,
};

CancelModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(CancelModal);

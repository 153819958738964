import React from 'react';
//import config from '../../config';
//import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ExternalLink,
} from '../../components';

import css from './HelpPage.css';

const HelpPage = () => {
  // const { siteTwitterHandle, siteFacebookPage } = config;
  // const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      title="Hilfe"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'HelpPage',
        description: 'Help SharePar',
        name: 'Help page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="HelpPage" />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>Hilfe</h1>

          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <h2 className={css.pageTitle}>
              <ExternalLink
                key="MailToHelp"
                href="mailto:hilfe@sharepar.com"
                className=""
                title="hilfe@sharepar.com"
              >hilfe@sharepar.com</ExternalLink></h2>
              <p>Wenn du Hilfe brauchst, weil du eine Funktion nicht verstehst oder irgend etwas nicht so funktioniert, wie es sollte,
wende dich an uns. Wir versuchen, dir so schnell wie möglich weiterzuhelfen.
Dein Sharepar Team</p>
            </div>

          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default HelpPage;

import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description, publicData } = props;
  const {
    ownerName,
    objectType,
    markeModel,
    necessaryConditions,
    meaningfulFurther,
    securityNotes,
    userAgreements,
    contactPersonName,
    contactPersonTelephone,
  } = publicData;
  return description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.descriptionTitle" />
      </h2>
      <p className={css.description}>
        {richText(description, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
      {ownerName ? (
        <>
          <h2 className={css.additionalTitle}>
            <FormattedMessage id="ListingPage.ownerName" />
          </h2>
          <p className={css.additionaltext}>
            {richText(ownerName, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
              longWordClass: css.longWord,
            })}
          </p>
        </>
      ) : null}
      {objectType ? (
        <>
          <h2 className={css.additionalTitle}>
            <FormattedMessage id="ListingPage.objectType" />
          </h2>
          <p className={css.additionaltext}>
            {richText(objectType, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
              longWordClass: css.longWord,
            })}
          </p>
        </>
      ) : null}
      {markeModel ? (
        <>
          <h2 className={css.additionalTitle}>
            <FormattedMessage id="ListingPage.markeModel" />
          </h2>
          <p className={css.additionaltext}>
            {richText(markeModel, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
              longWordClass: css.longWord,
            })}
          </p>
        </>
      ) : null}
      {necessaryConditions ? (
        <>
          <h2 className={css.additionalTitle}>
            <FormattedMessage id="ListingPage.necessaryConditions" />
          </h2>
          <p className={css.additionaltext}>
            {richText(necessaryConditions, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
              longWordClass: css.longWord,
            })}
          </p>
        </>
      ) : null}
      {meaningfulFurther ? (
        <>
          <h2 className={css.additionalTitle}>
            <FormattedMessage id="ListingPage.meaningfulFurther" />
          </h2>
          <p className={css.additionaltext}>
            {richText(meaningfulFurther, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
              longWordClass: css.longWord,
            })}
          </p>
        </>
      ) : null}
      {securityNotes ? (
        <>
          <h2 className={css.additionalTitle}>
            <FormattedMessage id="ListingPage.securityNotes" />
          </h2>
          <p className={css.additionaltext}>
            {richText(securityNotes, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
              longWordClass: css.longWord,
            })}
          </p>
        </>
      ) : null}
      {userAgreements ? (
        <>
          <h2 className={css.additionalTitle}>
            <FormattedMessage id="ListingPage.userAgreements" />
          </h2>
          <p className={css.additionaltext}>
            {richText(userAgreements, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
              longWordClass: css.longWord,
            })}
          </p>
        </>
      ) : null}
      {contactPersonName && contactPersonTelephone ? (
        <>
          <h2 className={css.additionalTitle}>
            <FormattedMessage id="ListingPage.contactPerson" />
          </h2>
          <div className={css.contactContainer}>
            <p className={css.additionaltext}>
              {richText(contactPersonName, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                longWordClass: css.longWord,
              })}
            </p>
            <p className={css.additionalTextTelephone}>
              {richText(contactPersonTelephone, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
                longWordClass: css.longWord,
              })}
            </p>
          </div>
        </>
      ) : null}
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Berlin, am 23.11.2020 (letzte Aktualisierung)</p>

      <h2>Präambel</h2>

      <p>
        Im nachfolgenden Text findest du die Nutzungsbedingungen von Sharepar. Diese regeln die Benutzung der Sharepar App. Außerhalb dieser Regelungen gelten keine weiteren Regelungen. Gesetzliche Vorschriften sind davon unberührt und gelten entsprechend. Wer die Sharepar App benutzt, erkennt diese Regeln durch seine/ihre Nutzung an. Diese Regelungen sind also in jedem Fall für alle Nutzer*innen bindend. Bitte lies sie dir durch, damit dir alles klar ist und du weißt, was du durch die Nutzung anerkennst. Bei Fragen kannst du uns gerne per Email an diese Adresse <a href="mailto:support@sharepar.com">support@sharepar.com</a> kontaktieren.
      </p>

      <h3>Die Sharepar Vision</h3>
      <p>
        Wir haben eine ganz klare Vision, die sich auf die Nutzung von Dingen und das Miteinander der Menschen bezieht:
      </p>
      <p>
        <i>„Jeder Mensch ist aktiver Teil einer Sharing-Gemeinschaft!“</i>
      </p>
      <p>
        „Eine/r für alle, alle für eine/n!“, könnte ein schöner Claim für diese Vision sein und damit auch die Gesellschaft berühren und verändern. Unser Umgang mit Dingen und Ressourcen ist in dieser Welt noch lange nicht optimal organisiert. Auch das Miteinander in der Gemeinschaft funktioniert besser, wenn wir den/die andere/n ein Stück weit mitdenken.
      </p>
      <p>
        Kooperation ist in der Natur seit jeher eines der wichtigsten Prinzipien des Überlebens. Und Kooperation ist auch der Kernbestandteil der Sharepar Vision.
      </p>
      <p>
        Mit Kooperation erreichen wir gemeinsam mehr und müssen zugleich weniger Energie und Ressourcen für ein gutes Leben aufbringen.
      </p>
      <p>
        Vielleicht könnte man es auch so ausdrücken: Wenn du die Vision des Teilens Wirklichkeit werden lassen willst, werde Mitglied bei Sharepar und Teil einer lebendigen Sharing-Community. Die Zahl der Möglichkeiten ist unbegrenzt.
      </p>

      <h3>Unsere Mission</h3>
      <p>
      Wir sind da, um die Vision von lebendigen Sharing-Gemeinschaften Wirklichkeit werden zu lassen. Das tun wir, indem wir mit der Sharepar App. ein Werkzeug zur Verfügung stellen, mit dem das Teilen und gemeinsame Nutzen von Dingen und anderem, so einfach und leicht wie möglich funktioniert.
      </p>
      <p>
      <i>"Durch ein gutes Werkzeug macht Sharepar das alltägliche Teilen mit anderen ganz leicht!"</i>
      </p>
      <p>
      Daher arbeiten wir fortan beständig an der Weiterentwicklung und Verbesserung dieses Werkzeugs, das du in der Sharepar App findest.
      </p>
      <p>
      Um Sharepar in einen größeren Zusammenhang zu stellen, haben wir das <a href="https://www.sharingmanifest.de/">Sharing Manifest</a> formuliert. Es ist ein klares Statement zu sechs Aspekten des Teilens. Darin geht es um nichts Geringeres, als eine bestimmte Haltung (Mindset), den Nutzen von Dingen, die Gemeinschaft, Qualität, Rücksichtnahme und letztlich auch die Spürbarkeit von Freiheit im Leben des/der Einzelnen.
      </p>
      <p>
      Das <a href="https://www.sharingmanifest.de/">Sharing Manifest</a> ist ein Teil der Sharepar Welt und der Welt des Teilens.
      Wir sind nicht allein und wir möchten damit einen allgemeingültigen Beitrag für alle Menschen und Initiativen leisten, die nach einer Orientierung für ein Leben der Kooperation mit anderen suchen. Schau dir das <a href="https://www.sharingmanifest.de/">Sharing Manifest</a> einmal an.
      </p>

      <h3>Das Sharepar Prinzip</h3>
      <p>
        Das Sharepar Prinzip ist sehr einfach und klar. Es unterscheidet sich von der Idee des Marktplatzes anderer Sharing-Plattformen in einigen wesentlichen Punkten:
      </p>
        <ol>
        <li>
        <b>Sharepar</b> ist kein Marktplatz, sondern <b>ein Werkzeug</b>. Es gibt also keine Öffentlichkeit, sondern nur das Objekt und die Menschen, die dieses Objekt nutzen. Das nennen wir "Sharing-Gruppe". Du gründest deine Gruppen immer um ein einzelnes Objekt herum. Daraus ergibt sich der nächste Punkt: "Ein Objekt, eine Gruppe!"
        </li>
        <li>
        <b>"Ein Objekt, eine Gruppe!"</b>: Es gibt immer <b>nur eine Gruppe rund um ein Objekt</b>. Das hat den guten Grund, dass das Objekt auch zu den eingeladenen Menschen passen muss. Wer keinen Autoführerschein hat, kann/darf auch kein Auto benutzen. Für ein Segelboot braucht es Erfahrung und/oder einen Segelschein und so weiter. Der/die Besitzer/in eines Objekts wählt seine/ihre Mitnutzer*innen also am besten mit Bedacht aus, damit beim täglichen Teilen und Nutzen auch alles gut funktioniert.
        </li>
        <li>
        <b>Sharepar ist für alltägliches Teilen in stabilen und dauerhaften Gruppen gemacht</b>, in denen Menschen, sich vertrauen, Dinge miteinander teilen und nutzen. Einen schnellen Deal kannst du hier nicht landen. Wenn du bestimmte Dinge nutzen möchtest, musst du dir vorher eine entsprechende Gruppe gesucht oder aufgebaut haben. Für eine Bohrmaschine fährt man nicht gerne durch die ganze Stadt. Das funktioniert nur im nahen räumlichen Umfeld.  Bei einem Ferienhaus ist das anders. Das liegt in den Bergen oder am Meer oder sonst wo und die Mitnutzer*innen leben eben dort, wo sie leben. Sharepar ist individuell. Du baust dir deine eigene Sharing-Umgebung auf, indem du genau in den Gruppen teilst, die zu dir passen und das Objekt bieten, dass du regelmäßig nutzt.
        </li>
        <li>
        Mit Sharepar nutzt man die Dinge auf <b>Augenhöhe</b>, von "gleich zu gleich". Dafür steht das Wort "Par" in unserem Namen (Sharepar = "teilen von gleich zu gleich"). Ganz praktisch bedeutet das, dass jede/r Nutzer*in einer Gruppe das Objekt direkt und ohne Voranfrage buchen kann. In diesem Punkt funktioniert Sharepar ganz anders, als die öffentlichen Plattformen, die zudem meist der Vermietung von Objekten dienen. Dort kennt man sich meist nicht, ist anonym und der gesamte Vorgang ist dadurch verlangsamt, weil man die Nutzer oft erst prüfen muss.  Das fällt bei Sharepar weg, da das <b>Teilen und Nutzen in vertrauten Gruppen</b> geschieht. Das Kennenlernen hat bereits zuvor stattgefunden und dazu geführt, dass man in dieser oder jener Gruppe ein festes Mitglied ist.
        </li>
        <li>
        <b>Mit Sharepar teilt und nutzt man ganz undogmatisch.</b> Je nach Festlegung durch den Owner (Besitzer*in oder Eigentümer*in) geht das entweder kostenlos, gegen eine Beteiligung an den Selbstkosten oder eine anders kalkulierte Gebühr. Dadurch wird wieder echtes Teilen möglich und die Vermietung steht nicht mehr zwangsläufig im Vordergrund. Sharepar überlässt die Entscheidung, wie genutzt und geteilt werden soll, letztlich der Gruppe selbst. In der jeweiligen Objektbeschreibung werden die eigentlichen Nutzungsbedingungen des Objektes für diese Gruppe festgelegt. Das Sharing darf dann das sein, was es hier sein soll: nicht-monetäres Teilen, die Mitnutzung gegen eine Kostenbeteiligung oder auch eine Vermietung.
        </li>
        </ol>
      <h3>Die zwei Welten der Sharepar Umgebung</h3>
        <ol>
          <li>
          <p>
            <b>Die erste Welt: Sharepar und du</b>
            </p>
            <p>
              Sharepar ist hier der Leistungsanbieter und du nutzt die Funktionen des Tools von Sharepar als Nutzer*in. In dieser Welt gelten unsere <b>Nutzungsbedingungen Teil A, Allgemeine Geschäftsbedingungen.</b>
            </p>
          </li>
          <li>
            <p><b>Die zweite Welt: Du und deine Sharing-Gruppe</b>
            </p>
            <p>
              In dieser Welt geht es bunt zu, weil jede Gruppe anders ist. Hier gelten die Regeln, die du als Owner (Besitzer*in/Eigentümer*in) für die Nutzung deines Objekts festlegst oder eben die, denen du als Nutzer*in einer Gruppe zugestimmt hast.
            </p>
            <p>
              Wir von Sharepar wollen diese Gruppenregeln nicht bestimmen und tun dieses nur aus einer Meta-Ebene heraus. Wir geben nur Empfehlungen, die dazu beitragen mögen, Missverständnisse innerhalb der Gruppen zu vermeiden.
            </p>
            <p>
              Diese findest du in den <b>Nutzungsempfehlungen, Teil B „Sharepar Tipps für Nutzer*innen“</b> unten auf unser App.
            </p>
            <p>
              In dieser zweiten Welt sind die Owner die Leistungsanbieter und setzen entweder selbst oder in Abstimmung mit der Gruppe die Nutzungsregeln fest.
            </p>
            <p>
              Als Mitglied einer solchen Gruppe entscheidest du einzig und allein durch die Mitgliedschaft in eben dieser Gruppe und die Nutzung des dortigen Objekts, ob du mit den dortigen Nutzungsbedingungen einverstanden bist.
            </p>
            <p>
              Der Objektbeschreibung kommt daher eine sehr wichtige Funktion zu. Deshalb sollten hier alle wichtigen Punkte genannt werden, die man als Nutzer*in dort wissen muss, um das Objekt bestimmungsgemäß nutzen zu können.
            </p>
          </li>
        </ol>
      <p>
        Das Sharepar Team wünscht viel Freude bei der Nutzung der <b>Sharepar App.</b> und natürlich beim Teilen und Nutzen möglichst vieler schöner und praktischer Dinge, mit deinen Freunden, deiner Familie und all denen, denen zu vertraust.
      </p>
      <p>
        <b>Dein Sharepar Team</b>
      </p>
  <h2>Nutzungsbedingungen, Teil A</h2>

  <h2>Allgemeine Geschäftsbedingungen</h2>

    <h3>§ 1 Leistungsbeschreibung</h3>
      <p>
        1.1 Die Sharepar App. ist ein Werkzeug für Menschen, die die gemeinschaftliche Nutzung von Dingen/Objekten in selbstorganisierten Gruppen verantwortlich praktizieren wollen.
      </p>
      <p>
        1.2 Die Sharepar App. ist kein Marktplatz, der dir etwas anbietet. Sharepar funktioniert nur, wenn du selbst aktiv bist.
      </p>
      <p>
        1.3 Sharepar bietet keines der Objekte selbst an, die du mit anderen nutzen und teilen kannst. Sie stammen daher entweder von dir selbst oder von Menschen, die dir vertrauen und die dich in ihre Sharing-Gruppe eingeladen haben.
      </p>
      <p>
        1.4 Sharepar stellt in Regel keine Öffentlichkeit in Bezug auf die Objekte und die Gruppen her, die die Sharepar App. für sich nutzen; es sei denn wir nutzen einzelne gelungene Beispiele für die Unternehmenskommunikation. Das geschieht ausschließlich mit Zustimmung der und in Abstimmung mit den betreffenden Akteuren.
      </p>
      <p>
        1.5 Die Aktivitäten innerhalb der jeweiligen Sharing-Gruppe gehen ausschließlich von dem sogenannten Owner (Besitzer*in, Eigentümer*in) aus und werden von diesem/r gesteuert und geregelt.
      </p>
      <p>
        1.6 Die Regeln der jeweiligen Gruppe in Bezug auf die Nutzung und den Umgang mit dem Objekt des Teilens werden vom Owner in der Objektbeschreibung festgehalten. Mitglieder der jeweiligen Objektgruppe erkennen diese Regeln durch ihre Mitgliedschaft in dieser Gruppe und die Nutzung des Objekts an.
      </p>
      <p>
        1.7 Die Sprache unseres Angebotes und des Vertrages, den wir mit Nutzer*innen schließen ist deutsch. Für eine entsprechende Verständigungsqualität und Kommunikation mit Sharepar und den Mitgliedern der jeweiligen Gruppe hat der/die Nutzer*in selbst zu sorgen.
      </p>

    <h3>§ 2 Teilnahmeberechtigung, Registrierung, Nutzerkonto</h3>
      <p>
        2.1 Die Nutzung der Sharepar App. ist nur voll geschäftsfähigen Personen gestattet, die ihren ständigen Wohnsitz in der EU haben. Minderjährige Nutzer*innen benötigen zur Nutzung eine explizite, schriftliche Einwilligung ihrer gesetzlichen Vertreter.
      </p>
      <p>
        2.2 Sharepar führt in der Regel keine Feststellung der Identität seiner Nutzer*innen durch. Im Zweifelsfall und im Falle missbräuchlicher Verwendung der Sharepar App. können wir, im Rahmen der geltenden Gesetze, zum Zwecke der Transparenz und Betrugsprävention entsprechende Informationen anfordern oder Überprüfungen durchführen.
      </p>
      <p>
        2.3 Die Nutzung der Sharepar App. kann nur im Rahmen der jeweiligen technischen Möglichkeiten gewährleistet werden. Ausfälle und Unterbrechungen der Dienstleistung sind daher möglich und werden von uns so schnell, wie möglich behoben. Über vorhersehbare Einschränkungen und Unterbrechungen der Dienstleistung werden wir so rechtzeitig, wie möglich informieren.
      </p>

    <h3>§ 3 Art der Objekte/Inhalte der Objektbeschreibungen/ethisches Verhalten</h3>
      <p>
        3.1 Die Sharepar App. ist ein Werkzeug zur Organisation der gemeinsamen Nutzung von Dingen und anderen Objekten innerhalb von sogenannten Sharing-Gruppen.
      </p>
      <p>
        3.2 Da Sharepar kein Marktplatz ist und keine Öffentlichkeit für diese Gruppen herstellt, sind diese auf sich selbst gestellt und für die Einhaltung der allgemein anerkannten Regeln des Anstands und der Gesetze selbst verantwortlich.
      </p>
      <p>
        3.3 In Deutschland geltende Gesetze sind generell einzuhalten, das gilt insbesondere für die Artikel 1-4 des Grundgesetzes und das Abgaben- und Steuerrecht der Bundesrepublik Deutschland. Die Einhaltung anderer Gesetze bleibt davon unberührt.
      </p>
      <p>
        3.4 Die Nutzung der Sharepar App. ist darüber hinaus von unserer Seite her untersagt, wenn lebendigen Wesen jeglicher Art, durch die Nutzung der Sharepar App. bedingt, in irgendeiner Weise Leid oder Schaden zugefügt oder dieses geduldet wird. Sharepar erwartet von seinen Nutzer*innen, derartige Verhaltensweisen zu verhindern, uns diese gegebenenfalls zu melden und/oder diese selbst zur Anzeige zu bringen. Sharepar erwartet ferner, dass Nutzer*innen sich klar, deutlich und konsequent von derartig schädigendem Verhalten distanzieren. Die Leid oder Schaden verursachenden oder duldenden Nutzer*innen müssen damit rechnen, von der Nutzung der Sharepar App. dauerhaft ausgeschlossen zu werden. Entsprechend notwendige rechtliche Konsequenzen bleiben davon unberührt.
      </p>
      <p>
        3.5 Aufbauend auf den §§ 3.1/3.2/3.3 und 3.4 dieser AGB sind diskriminierende, rassistische,  sexistische oder auf eine andere Weise herabwürdigend wirkende Formulierungen, Texte oder eine derartige Sprache, sowie entsprechende Handlungen innerhalb des Systems Sharepar unerwünscht. Mitglieder von Sharepar und Gruppen, die gegen diese Regel verstoßen, werden von der Nutzung der Sharepar App. konsequent und dauerhaft ausgeschlossen.
      </p>
      <p>
        3.6 Im Gegensatz zu den in § 3 (5) genannten Verstößen gegen die Sharepar Ethik ist heilsames, unterstützendes, lebensfreundliches, die Gemeinschaft sowie Friedfertigkeit von Lebewesen förderndes Verhalten bei Sharepar explizit erwünscht und wird uns nach Möglichkeit unterstützt.
      </p>
    <h3>§ 4 Ausschluss von Teilnehmern/Mitgliedern</h3>
      <p>
        4.1 Eine Nutzung der Sharepar Dienstleistungen ist ohne eine Registrierung und ohne ein Nutzerkonto nicht möglich.
      </p>
      <p>
        4.2 Ein Anspruch auf Einrichtung eines Nutzerkontos besteht nicht. Sharepar behält sich vor, eine Einrichtung im Einzelfall ohne Nennung von Gründung abzulehnen.
      </p>
      <p>
        4.3 Mit der Registrierung erklärt sich der/die Nutzer*in mit den AGB und den Datenschutzbestimmungen von Sharepar einverstanden.
      </p>
      <p>
        4.3 Mit der Registrierung versichert der/die Nutzer*in, dass alle bei Sharepar gemachten Angaben und Daten im Hinblick auf seine/ihre Person aktuell und zutreffend sind. Falsche Angaben können zum Ausschluss von der Sharepar Nutzergemeinschaft und zur Löschung des Nutzerkontos führen.
      </p>
    <h3>§ 5 Zahlungsbedingungen und -systeme</h3>
      <p>
        5.1 Sharepar nutzt den Zahlungsdienstleister <a href="https://stripe.com/de">Stripe</a>. Alle, die Zahlung betreffenden Vorgänge und Daten liegen bei diesem Dienstleister. Sharepar hat keinen Zugang zu diesen Daten. Mit der Eingabe von Kontodaten öffnet sich ein separater, entsprechend gesicherter Dialog mit Stripe. Mit dieser Interaktion entsteht ein entsprechendes Vertragsverhältnis zwischen diesem Dienstleister und dem/der Nutzer*in.
      </p>
      <p>
        5.2 Nutzer*innen, die ein Objekt gegen eine Gebühr nutzen, lösen durch den Buchungsvorgang eine Zahlungsaufforderung bei dem entsprechenden Zahlungsdienstleister aus. Vor der Zahlung wird der/die Nutzer*in darauf hingewiesen, dass der Vorgang der Buchung zahlungspflichtig ist und die Zahlung mit dem entsprechenden Schritt (Klick auf den Buchungsbutton) ausgelöst wird. Der/die Nutzerin hat für eine entsprechende Kontodeckung zu sorgen, damit der Zahlungsvorgang ordnungsgemäß durchgeführt werden kann.
      </p>
      <p>
        5.3 Stornierungen und Rückzahlungsanweisungen werden ebenfalls über diesen Zahlungsdienstleister abgewickelt und lösen stets Kosten aus. Stornierungen von zahlungspflichtigen Buchungen lösen entsprechende Transaktions- und Bearbeitungskosten aus, die dem/r Verursacher*in entstehen werden.
      </p>
      <h3>§ 6 Übergabe und Rückgabe der Objekte</h3>
        <p>
          6.1 Das Teilen von Dingen in  Sharing-Gruppen macht es in der Regel erforderlich, den tatsächlichen und physischen Zugang zu dem entsprechenden Objekt und dessen Rückgabe an den Owner (Besitzer*in/Eigentümer*in) sicherzustellen.
        </p>
        <p>
          6.2 Die beiden Vorgänge (Übergabe und Rückgabe) zu definieren, sicherzustellen und diese in der Objektbeschreibung als Regelvorgänge hinreichend darzustellen, obliegt dem jeweiligen Owner. Nutzer*innen akzeptieren mit der Mitgliedschaft in der entsprechenden Objekt-Gruppe (Sharing-Gruppe) die in der Objektbeschreibung dargestellten Vorgänge. Die Möglichkeit, beider Parteien (Owner und Nutzer*in) von dem jeweiligen Regelvorgang im Einzelfall abzuweichen, bleibt hiervon unberührt.
        </p>
        <p>
          6.3 Die Festlegung und Einhaltung von Regeln zur Nutzung eines Objekts trägt entscheidend zur Zufriedenheit aller Beteiligten und zur Harmonie innerhalb der Sharing-Gruppe bei. Mitglieder einer Sharing-Gruppe erkennen dieses an und bemühen sich, durch entsprechendes Verhalten zu diesen Qualitäten beizutragen.
        </p>
      <h3>§ 7 Haftung und Haftungsfreistellung</h3>
        <p>
          7.1 Sharepar haftet für Schäden, deren Verursachung auf das vorsätzliche oder grob fahrlässige Verhalten von Sharepar oder eines seiner gesetzlichen Vertreter oder Erfüllungsgehilfen zurückzuführen ist. Für fahrlässiges Verhalten haftet Sharepar nur bei einer Pflichtverletzung hinsichtlich vertragsnotwendiger Leistungen, auf die Vertragspartner regelmäßig vertrauen dürfen, wenn Sharepar mit den Folgen dieser Handlung aufgrund der zum Zeitpunkt des Vertragsabschlusses bekannten Situation rechnen musste . Im Übrigen ist die Haftung von Sharepar, seiner Vertreter und seinen Erfüllungsgehilfen ausgeschlossen.
        </p>
        <p>
          7.2 Die Haftungsbeschränkung gilt dort nicht, wo die Regelungen nach dem Produkthaftungsgesetz, das BGB in § 309 Abs. 7 a) und § 310 Abs. 3 (Haftungsausschluss bei Verletzung von Leben, Körper und Gesundheit) davon Abweichendes bestimmen.
        </p>
        <p>
          7.3 Die Mitglieder der sogenannten Sharing-Gruppen (auch Objektgruppen genannt) stellen Sharepar von jedweden Ansprüchen frei, die sich aus den Handlungen und vertraglichen Vereinbarungen innerhalb der Gruppe, insbesondere zwischen Owner (Besitzer*in/Eigentümer*in) und den übrigen Mitgliedern (Nutzer*innen) der Gruppe ergeben können. Die Mitglieder einer Gruppe handeln eigenverantwortlich und sind verpflichtet, ihre eigenen Gruppenregeln hinreichend eindeutig zu verfassen, so dass dadurch Unklarheiten jeglicher Art vermieden werden.
        </p>
      <h3>§ 8 Besondere Bedingungen für Unternehmer*innen</h3>
        <p>
        8.1 Die gewerbliche Nutzung von Sharepar ist unter der Bedingung der Einhaltung der den konkreten Einzelfall betreffenden gesetzlichen Regelungen grundsätzlich erlaubt. Nutzer*innen, die Sharepar auf diese Weise nutzen, gelten als Unternehmer*innen.
        </p>
        <p>
          8.2 Jede/r Unternehmer*in, die Sharepar gewerblich nutzt, ist zur Einhaltung aller entsprechend geltenden gesetzlichen Regelungen verpflichtet und handelt vollständig eigenverantwortlich.
        </p>
        <p>
        	8.3 Jede/r Unternehmer*in, die Sharepar gewerblich nutzt, verpflichtet sich dazu, sich über den aktuellen Stand aller entsprechend geltenden gesetzlichen Regelungen eigenverantwortlich und hinreichend zu informieren.
        </p>
        <p>
        	8.4 Jede/r Unternehmer*in, die Sharepar gewerblich nutzt, verpflichtet sich dazu, die entstandenen Einnahmen entsprechend steuerlich zu deklarieren und zu versteuern.
        </p>
      <h3>§ 9 Nutzung der Sharepar App.</h3>
        <p>
          9.1 Die Nutzung von Websites birgt stets Risiken, die nicht vollständig vermieden werden können. Jede/r Nutzer*in handelt bei der Nutzung der Sharepar App. auf eigene Gefahr.
        </p>
        <p>
          9.2 Sharepar bemüht sich auf angemessene Weise, auch im Verbund mit den Kooperationspartnern, diese Risiken zu minimieren. Daraus resultiert jedoch keine Übernahme der Verpflichtung dieses auch sicherstellen zu können.
        </p>
        <p>
          9.3 Verschiedene Ursachen können zu Störungen oder Unterbrechungen des Betriebs der App. führen. Sharepar wird stets bemüht sein, Störungen und Unterbrechungen jedweder Art im Rahmen der eigenen Möglichkeiten zu beseitigen.
        </p>
      <h3>§ 10 Kooperationspartner</h3>
        <p>
         10.1 Sharepar nutzt externe Unternehmen als Kooperationspartner, um spezielle Dienste, wie zum Beispiel die professionelle Durchführung des Zahlungsverkehrs, anbieten zu können. Dadurch entstehen weitere Vertragsbeziehungen, auf die im Vorfeld hingewiesen wird.
        </p>
        <p>
         10.2 Sharepar nutz den Softwaredienstleister <a href="https://www.sharetribe.com/">Sharetribe</a>, der einen Teil der Funktionen von Sharepar liefert.
        </p>
        <p>
         10.3 Sharepar nutzt den Zahlungsdienstleister <a href="https://stripe.com/de">Stripe</a>, der die Durchführung des  Zahlungsverkehrs übernimmt.
        </p>
        <p>
         10.4 Sharepar wird in Zukunft auch die Dienste weiterer Kooperationspartner nutzen, um die Funktionen der Sharepar App. weiter auszubauen und zu verbessern.
        </p>
      <h3>§ 11 Streitschlichtung</h3>
        <p>
          11.1 Sharepar ist weder bereit, noch verpflichtet, an Streitbeilegungsverfahren vor Verbraucherschlichtungsstellen teilzunehmen.
        </p>
        <p>
          11.2 Streit innerhalb von Sharing-Gruppen möge durch klare Vereinbarungen und zuverlässiges Einhalten dieser Vereinbarungen im Vorfeld verhindert werden.
        </p>
        <p>
          11.3 Streit innerhalb von Sharing-Gruppen möge im Guten beigelegt werden.
        Vor der Beschreitung des Rechtswegs mögen die Beteiligten wohlwollend und lösungsorientiert miteinander sprechen und eine Einigung herbeiführen.
        </p>
        <p>
          11.4 Die Wahrscheinlichkeit von Streiten zwischen Nutzer*innen und Sharepar möge zunächst durch eine wohlwollende Haltung vermindert werden. Im tatsächlichen Falle von Differenzen mögen beiden Seiten zunächst das klärende Gespräch suchen und eine einvernehmliche Regelung des Konflikts anstreben. Das Recht auf die Inanspruchnahme des Rechtsweges bleibt von dieser Regelung unberührt.
        </p>
      <h3>§ 12 Änderung dieser AGB</h3>
        <p>
          12.1 Sharepar hat das Recht, diese AGB jederzeit zu ändern und kennzeichnet den aktuellen Stand der AGB stets durch das entsprechende Änderungsdatum oberhalb der Präambel dieser AGB.
        </p>
        <p>
          12.2 Sharepar informiert jede/n Nutzer mindestens einen Monat bevor, diese Änderungen in Kraft treten per Email und durch die Veröffentlichung der jeweils geltenden AGB unten am Fuße der Sharepar App. unter AGB.
        </p>
        <p>
          12.3 Sharepar Nutzer*innen haben insbesondere dann das Recht, die Löschung des Sharepar Kontos zu verlangen, wenn sie mit den jeweiligen Änderungen der AGB nicht einverstanden sind. Andere Gründe für die Löschung des Nutzer*in-Konto bleiben davon unberührt.
        </p>
      <h3>§ 13 Schlussbestimmungen</h3>
        <p>
          13.1 Selbst, wenn einzelne Regelung dieser AGB unwirksam sein sollten, berührt dieses nicht die übrigen Regelungen dieser AGB.
        </p>
        <p>
          13.2 Erfüllungsort und Gerichtsstand ist Berlin.
        </p>
        <p>
          13.3 Sharepar ist berechtigt, Rechte und Pflichten auf andere Unternehmen zu übertragen.
        </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;

import React, { Component } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import {
  // LINE_ITEM_DAY,
  // LINE_ITEM_NIGHT,
  propTypes
} from '../../util/types';
// import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';


import css from './ListingCard.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

// const priceData = (price, intl) => {
//   if (price && price.currency === config.currency) {
//     const formattedPrice = formatMoney(intl, price);
//     return { formattedPrice, priceTitle: formattedPrice };
//   } else if (price) {
//     return {
//       formattedPrice: intl.formatMessage(
//         { id: 'ListingCard.unsupportedPrice' },
//         { currency: price.currency }
//       ),
//       priceTitle: intl.formatMessage(
//         { id: 'ListingCard.unsupportedPriceTitle' },
//         { currency: price.currency }
//       ),
//     };
//   }
//   return {};
// };

const getCertificateInfo = (certificateConfig, key) => {
  return certificateConfig.find(c => c.key === key);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

const EditLink = props => {
  const {
    isOwnListing,
    editParams,
    formattedMessage,
  } = props;
  if(isOwnListing){
    return(
      <div className={css.editLink}>
        <NamedLink className={css.editListingLink} name="EditListingPage" params={editParams}>
          <FormattedMessage id={formattedMessage} />
        </NamedLink>
      </div>
    )
  };
  return (null);
}

const OwnerMark = props => {
  const {
    isOwnListing,
  } = props;
  if(isOwnListing){
    return(
      <div className={css.ownerMark}>
        <FormattedMessage id={"ManageListingCard.ownerMark"} />
      </div>
    )
  };
  return (null);
}

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    // intl,
    listing,
    renderSizes,
    certificateConfig,
    setActiveListing,
    currentUser,
  } = props;

  const authorAvailable = listing && listing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =  userAndListingAuthorAvailable && listing.author.id.uuid === currentUser.id.uuid;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const {
    title = '',
    // price,
    publicData
  } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const certificate = publicData
    ? getCertificateInfo(certificateConfig, publicData.certificate)
    : null;
  // const { formattedPrice, priceTitle } = priceData(price, intl);

  // const unitType = config.bookingUnitType;
  // const isNightly = unitType === LINE_ITEM_NIGHT;
  // const isDaily = unitType === LINE_ITEM_DAY;

  // const unitTranslationKey = isNightly
  //   ? 'ListingCard.perNight'
  //   : isDaily
  //   ? 'ListingCard.perDay'
  //   : 'ListingCard.perUnit';


  return (
    <div className={classes}>
      <NamedLink name="ListingPage" params={{ id, slug }}>
        <div
          className={css.threeToTwoWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          <div className={css.aspectWrapper}>
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
              sizes={renderSizes}
            />
            <OwnerMark isOwnListing={isOwnListing}/>
          </div>
        </div>
        <div className={css.info}>
          {/* <div className={css.price}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
            <div className={css.perUnit}>
              <FormattedMessage id={unitTranslationKey} />
            </div>
          </div> */}
          <div className={css.mainInfo}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}

            </div>
            <div className={css.certificateInfo}>
              {certificate && !certificate.hideFromListingInfo ? (
                <span>{certificate.label}</span>
              ) : null}
            </div>
          </div>
        </div>
      </NamedLink>
      <EditLink
        formattedMessage='EditListingButtonLine1'
        isOwnListing={isOwnListing}
        editParams={{
          id: id,
          slug: slug,
          type: 'edit',
          tab: 'description',
        }}
      />
      <EditLink
      formattedMessage='EditListingButtonLine2'
        isOwnListing={isOwnListing}
        editParams={{
          id: id,
          slug: slug,
          type: 'edit',
          tab: 'availability',
        }}
      />
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  certificateConfig: config.custom.certificate,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  certificateConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,

};

export default injectIntl(ListingCardComponent);

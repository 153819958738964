import React from 'react';
import { AvatarLarge, AvatarMedium, Button, GroupUsersAvatars } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingPage.css';

const SectionAvatar = props => {
  const { user, coUsers, showUsersBtn, viewport } = props;

  const showTwoUser = viewport.width < 570;

  // const usersRegistered = coUsers.filter(user => user.flex_id !== null);
  const displayUsers = showTwoUser ? coUsers.slice(0, 2) : coUsers.slice(0, 6);

  return (
    <div className={css.sectionAvatar}>
      <AvatarLarge
        user={user}
        className={css.avatarDesktop}
        initialsClassName={css.initialsDesktop}
        //disableProfileLink
      />

      <AvatarMedium user={user} className={css.avatarMobile} disableProfileLink />
      <div className={css.coUsersHolder}>
        <GroupUsersAvatars users={displayUsers} />

        {showTwoUser && coUsers?.length > 2 ? (
          <Button type="button" onClick={showUsersBtn} className={css.coUsersBtn}>
            <FormattedMessage id="ListingPage.showMoreCoUsers" />
          </Button>
        ) : (
          coUsers?.length > 6 && (
            <Button type="button" onClick={showUsersBtn} className={css.coUsersBtn}>
              <FormattedMessage id="ListingPage.showMoreCoUsers" />
            </Button>
          )
        )}
      </div>
    </div>
  );
};

export default SectionAvatar;

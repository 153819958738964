
import { storableError } from '../util/errors';
import {
  handleGetTapkeyAccessToken,
  refreshToken,
} from "../containers/TapkeyPage/TapkeyPage.helpers";
import { updateProfile } from "../containers/ProfileSettingsPage/ProfileSettingsPage.duck"
import { makeTapkeyRequest } from "../util/api";

import { getOwnerContacts, createOwnerContact } from "../containers/TapkeyPage/TapkeyPage.helpers";

// ================ Action types ================ //

export const GET_TOKEN_REQUEST = 'app/Tapkey/GET_TOKEN_REQUEST';
export const GET_TOKEN_SUCCESS = 'app/Tapkey/GET_TOKEN_SUCCESS';
export const GET_TOKEN_ERROR = 'app/Tapkey/GET_TOKEN_ERROR';
export const GET_LOCKS_REQUEST = 'app/Tapkey/GET_LOCKS_REQUEST';
export const GET_LOCKS_SUCCESS = 'app/Tapkey/GET_LOCKS_SUCCESS';
export const GET_LOCKS_ERROR = 'app/Tapkey/GET_LOCKS_ERROR';

// ================ Reducer ================ //

const initialState = {
  locks: [],
  getLocksPending: false,
  getTokenPending: false,
  getTokenError: null,
  getLocksError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case GET_TOKEN_REQUEST:
      return { ...state, getTokenPending: true };
    case GET_TOKEN_SUCCESS:
      return { ...state, getTokenPending: false };
    case GET_TOKEN_ERROR:
      return { ...state, getTokenPending: false, getTokenError: payload };
    case GET_LOCKS_REQUEST:
      return { ...state, getLocksPending: true };
    case GET_LOCKS_SUCCESS:
      return { ...state, getLocksPending: false, locks: payload };
    case GET_LOCKS_ERROR:
      return { ...state, getLocksPending: false, getLocksError: payload };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const selectTapkeyLocksPending = state =>
  state.Tapkey.getLocksPending || state.Tapkey.getTokenPending
;

// ================ Action creators ================ //

export const getTokenRequest = () => ({ type: GET_TOKEN_REQUEST });
export const getTokenSuccess = () => ({ type: GET_TOKEN_SUCCESS });
export const getTokenError = (error) => ({ type: GET_TOKEN_ERROR, payload: error });
export const getLocksRequest = () => ({ type: GET_LOCKS_REQUEST });
export const getLocksSuccess = (locks) => ({ type: GET_LOCKS_SUCCESS, payload: locks });
export const getLocksError = (error) => ({ type: GET_LOCKS_ERROR, payload: error });

// ================ Thunks ================ //

export const onGetTapkeyLocks = () => async (dispatch, getState) => {
  dispatch(getLocksRequest());
  const currentUser = getState().user.currentUser;
  let tapkeyToken = currentUser?.attributes?.profile?.privateData?.tapkeyToken ?? null;

  try {
    if (tapkeyToken) tapkeyToken = await updateUserTapkeyToken(dispatch, tapkeyToken);

    if (!tapkeyToken) {
      dispatch(getLocksSuccess([]));
      return;
    }

    const owners = await makeTapkeyRequest("/owners", "GET", {}, tapkeyToken);
    if (!owners.data) {
      throw new Error(owners);
    }
    const ownerId = owners?.data?.[0].id ?? null;
    const locks = await makeTapkeyRequest(`/owners/${ownerId}/boundlocks`, "GET", {}, tapkeyToken);
    if (!locks.data) {
      throw new Error(locks);
    }
    dispatch(getLocksSuccess(locks.data));
  } catch(err) {
    console.log(err);
    dispatch(getLocksError(storableError(err)));
  }
};

export const getTapkeyAccessToken = (currentUser) => async (dispatch, getState, sdk) => {
  let tapkeyToken = currentUser?.attributes?.profile?.privateData?.tapkeyToken ?? null;

  try {
    tapkeyToken = !tapkeyToken ?
      await handleGetTapkeyAccessToken(currentUser) :
      await updateUserTapkeyToken(dispatch, tapkeyToken);

    if (!tapkeyToken) return;

    const currentUserListings = await sdk.ownListings.query();
    const listings = currentUserListings.data.data ?? [];

    const owners = await makeTapkeyRequest("/owners", "GET", {}, tapkeyToken);
    const ownerId = owners?.data?.[0]?.id ?? false;

    for (let i = 0; i < listings.length; i++) {
      const {
        id,
        attributes: {
          publicData: {
            members = [],
            tapkeyOwnerContacts = [],
          }
        }
      } = listings[i];

      const newContacts = [];
      const newTapkeyOwnerContacts =
        members.filter(
          member => !tapkeyOwnerContacts.find(contact => contact.email === member.email)
        );

      for (let j = 0; j < newTapkeyOwnerContacts.length; j++) {
        if (!newTapkeyOwnerContacts[j].email) {
          continue;
        }
        const ownerContacts = await getOwnerContacts(newTapkeyOwnerContacts[j].email, ownerId, tapkeyToken);
        if (ownerContacts[0]) {
          newContacts.push({email: ownerContacts[0].identifier, id: ownerContacts[0].id});
        } else {
          const newContact = await createOwnerContact(newTapkeyOwnerContacts[j].email, ownerId, tapkeyToken);
          newContacts.push({email: newTapkeyOwnerContacts[j].email, id: newContact.id});
        }
      }

      await sdk.ownListings.update({
        id,
        publicData: {
          tapkeyOwnerContacts: [...tapkeyOwnerContacts, ...newContacts]
        },
      });
    }

    const updatedValues = {
      publicData: {
        tapkeyOwnerId: ownerId,
      },
      privateData: {
        tapkeyToken
      },
    }

    dispatch(updateProfile(updatedValues));
  } catch(err) {
    console.log(err);
    dispatch(getTokenError(err));
  }
}

export const disconnectTapkey = () => (dispatch) => {
  try {
    const updatedValues = {
      publicData: {
        tapkeyOwnerId: null,
      },
      privateData: {
        tapkeyToken: null
      },
    }

    dispatch(updateProfile(updatedValues));
  } catch(err) {
    dispatch(getTokenError(err));
  }
}

// ================ Helpers ================ //

export const updateUserTapkeyToken = async (dispatch, tapkeyToken) => {
  const { accessToken, tapkeyParams } = tapkeyToken;
  const currentMilliseconds = (new Date()).getTime();
  const isExpired = currentMilliseconds > (accessToken.expires_in * 1000 + accessToken.currentDate);
  if (isExpired) {
    const newTapkeyAccessToken = await refreshToken(accessToken);
    const updatedValues = {
      protectedData: {
        tapkeyToken: {
          accessToken: newTapkeyAccessToken,
          tapkeyParams,
        }
      },
    }

    await dispatch(updateProfile(updatedValues));
    return { tapkeyParams, accessToken: newTapkeyAccessToken }
  }
  return tapkeyToken;
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ensureOwnListing } from '../../util/data';

import { ListingLink } from '../../components';
import { EditListingLocksForm } from '../../forms';

import css from './EditListingLocksPanel.css';

const EditListingLocksPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    updateInProgress,
    stripeConnected,
    isPastDraft,
    onGetTapkeyLocks,
    tapkeyStateData,
    currentUser,
    onFlinkeyCredentialsExist,
    flinkeyCredentialsExist,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const isPriceNull = publicData;

  const panelTitle = currentListing.id ? (
    <FormattedMessage
      id="EditListingLocksPanel.title"
      values={{
        listingTitle: <ListingLink listing={listing} />,
      }}
    />
  ) : (
    <FormattedMessage
      id="EditListingLocksPanel.createListingTitle"
      values={{
        listingTitle: <ListingLink listing={listing} />,
      }}
    />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingLocksForm
        className={css.form}
        updateInProgress={updateInProgress}
        stripeConnected={stripeConnected}
        isPastDraft={isPastDraft}
        isPriceNull={isPriceNull}
        tapkeyData={publicData.tapkey_data}
        accessProvider={publicData.access_provider}
        onSubmit={onSubmit}
        onGetTapkeyLocks={onGetTapkeyLocks}
        tapkeyStateData={tapkeyStateData}
        currentUser={currentUser}
        currentListing={currentListing}
        onFlinkeyCredentialsExist={onFlinkeyCredentialsExist}
        flinkeyCredentialsExist={flinkeyCredentialsExist}
      />
    </div>
  );
};

const { array, func, object, string, bool, shape } = PropTypes;

EditListingLocksPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingLocksPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  updateInProgress: bool.isRequired,
  onGetTapkeyLocks: func.isRequired,
  tapkeyStateData: shape({
    locks: array.isRequired,
    getTokenError: string,
    getLocksError: object,
    getLocksPending: bool,
  }),
};

export default EditListingLocksPanel;

import pick from 'lodash/pick';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_FIXED,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_FIXED,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { getAliasHelper } from '../../util/timeOptionHelper';
import {
  metadataChangeRequest,
  changeCountNotification,
  getListingAuthor,
  createAssignments,
  makeTapkeyRequest,
} from '../../util/api';
import { fetchTransaction } from '../../containers/TransactionPage/TransactionPage.duck';
import { createGrant, updateGrant } from './CheckoutPage.helpers';
import { updateUserTapkeyToken } from '../../ducks/Tapkey.duck';

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const METADATA_TRANSACTION_REQUEST = 'app/CheckoutPage/METADATA_TRANSACTION_REQUEST';
export const METADATA_TRANSACTION_SUCCESS = 'app/CheckoutPage/METADATA_TRANSACTION_SUCCESS';
export const METADATA_TRANSACTION_ERROR = 'app/CheckoutPage/METADATA_TRANSACTION_ERROR';

export const USER_COUNT_NOTIFICATION_REQUEST = 'app/CheckoutPage/USER_COUNT_NOTIFICATION_REQUEST';
export const USER_COUNT_NOTIFICATION_SUCCESS = 'app/CheckoutPage/USER_COUNT_NOTIFICATION_SUCCESS';
export const USER_COUNT_NOTIFICATION_ERROR = 'app/CheckoutPage/USER_COUNT_NOTIFICATION_ERROR';

export const CREATE_GRUNT_REQUEST = 'app/CheckoutPage/CREATE_GRUNT_REQUEST';
export const CREATE_GRUNT_SUCCESS = 'app/CheckoutPage/CREATE_GRUNT_SUCCESS';
export const CREATE_GRUNT_ERROR = 'app/CheckoutPage/CREATE_GRUNT_ERROR';

export const CREATE_FLINKEY_ASSIGNMENT_REQUEST =
  'app/CheckoutPage/CREATE_FLINKEY_ASSIGNMENT_REQUEST';
export const CREATE_FLINKEY_ASSIGNMENT_SUCCESS =
  'app/CheckoutPage/CREATE_FLINKEY_ASSIGNMENT_SUCCESS';
export const CREATE_FLINKEY_ASSIGNMENT_ERROR = 'app/CheckoutPage/CREATE_FLINKEY_ASSIGNMENT_ERROR';

export const GET_GRUNT_REQUEST = 'app/TransactionPage/GET_GRUNT_REQUEST';
export const GET_GRUNT_SUCCESS = 'app/TransactionPage/GET_GRUNT_SUCCESS';
export const GET_GRUNT_ERROR = 'app/TransactionPage/GET_GRUNT_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  bookingData: null,
  bookingDates: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  metadataTransactionInProgress: false,
  metadataTransactionError: null,
  metadataTransactionSuccess: false,
  userCountNotificationProgress: false,
  userCountNotificationError: null,
  userCountNotificationSuccess: false,
  grunt: null,
  gruntError: null,
  flinkeyAssignment: null,
  flinkeyAssignmentError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case CREATE_GRUNT_ERROR:
      return { ...state, gruntError: payload };

    case METADATA_TRANSACTION_REQUEST:
      return {
        ...state,
        metadataTransactionInProgress: true,
        metadataTransactionError: null,
        metadataTransactionSuccess: false,
      };
    case METADATA_TRANSACTION_SUCCESS:
      return {
        ...state,
        metadataTransactionInProgress: false,
        metadataTransactionError: null,
        metadataTransactionSuccess: true,
      };
    case METADATA_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        metadataTransactionInProgress: false,
        metadataTransactionError: payload,
        metadataTransactionSuccess: false,
      };

    case USER_COUNT_NOTIFICATION_REQUEST:
      return {
        ...state,
        userCountNotificationProgress: true,
        userCountNotificationError: null,
        userCountNotificationSuccess: false,
      };
    case USER_COUNT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        userCountNotificationProgress: false,
        userCountNotificationError: null,
        userCountNotificationSuccess: true,
      };
    case USER_COUNT_NOTIFICATION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        userCountNotificationProgress: false,
        userCountNotificationError: payload,
        userCountNotificationSuccess: false,
      };

    case CREATE_FLINKEY_ASSIGNMENT_REQUEST:
      return { ...state, flinkeyAssignmentError: null };
    case CREATE_FLINKEY_ASSIGNMENT_SUCCESS:
      return { ...state, flinkeyAssignment: payload };
    case CREATE_FLINKEY_ASSIGNMENT_ERROR:
      return { ...state, flinkeyAssignmentError: payload, flinkeyAssignment: null };

    case GET_GRUNT_REQUEST:
      return {...state,  gruntError: null};
    case GET_GRUNT_SUCCESS:
      return {...state, gruntError: null, grunt: payload};
    case GET_GRUNT_ERROR:
      return {...state, gruntError: payload};

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const createGruntRequest = () => ({ type: CREATE_GRUNT_REQUEST });
export const createGruntSuccess = () => ({ type: CREATE_GRUNT_SUCCESS });
export const createGruntError = e => ({
  type: CREATE_GRUNT_ERROR,
  error: true,
  payload: e,
});

export const fetchMetadataTransactionRequest = () => ({ type: METADATA_TRANSACTION_REQUEST });
export const fetchMetadataTransactionSuccess = () => ({ type: METADATA_TRANSACTION_SUCCESS });
export const fetchMetadataTransactionError = e => ({
  type: METADATA_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const userCountNotificationRequest = () => ({ type: USER_COUNT_NOTIFICATION_REQUEST });
export const userCountNotificationSuccess = () => ({ type: USER_COUNT_NOTIFICATION_SUCCESS });
export const userCountNotificationError = e => ({
  type: USER_COUNT_NOTIFICATION_ERROR,
  error: true,
  payload: e,
});

export const createFlinkeyAssignmentRequest = () => ({ type: CREATE_FLINKEY_ASSIGNMENT_REQUEST });
export const createFlinkeyAssignmentSuccess = payload => ({
  type: CREATE_FLINKEY_ASSIGNMENT_SUCCESS,
  payload,
});
export const createFlinkeyAssignmentError = e => ({
  type: CREATE_FLINKEY_ASSIGNMENT_ERROR,
  error: true,
  payload: e,
});

export const getGruntRequest = () => ({ type: GET_GRUNT_REQUEST });
export const getGruntSuccess = payload => ({
  type: GET_GRUNT_SUCCESS,
  payload,
});
export const getGruntError = error => ({
  type: GET_GRUNT_ERROR,
  error: true,
  payload: error,
});

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId, isPriceNull) => async (
  dispatch,
  getState,
  sdk
) => {
  const { timeOption } = await getState().ListingPage;
  let alias = getAliasHelper(timeOption, isPriceNull);

  const quantity = orderParams && orderParams.lineItems[0].quantity;
  const price = orderParams && (orderParams.lineItems[0].unitPrice.amount / 100) * quantity;

  dispatch(initiateOrderRequest());
  const bodyParams =
    transactionId && !isPriceNull
      ? {
          id: transactionId,
          transition:
            price >= 4
              ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
              : TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY_FIXED,
          params: orderParams,
        }
      : {
          processAlias: alias,
          transition:
            price < 4 && !isPriceNull
              ? TRANSITION_REQUEST_PAYMENT_FIXED
              : TRANSITION_REQUEST_PAYMENT,
          params: orderParams,
        };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const createOrder =
    transactionId && !isPriceNull ? sdk.transactions.transition : sdk.transactions.initiate;

  return createOrder(bodyParams, queryParams)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      const order = entities[0];
      dispatch(initiateOrderSuccess(order));
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      return order;
    })
    .catch(e => {
      dispatch(initiateOrderError(storableError(e)));
      const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
        listingId: orderParams.listingId.uuid,
        bookingStart: orderParams.bookingStart,
        bookingEnd: orderParams.bookingEnd,
      });
      throw e;
    });
};

export const changeUserCountNotification = ({
  userId,
  operationType,
  transactionId,
  transactionRole,
}) => dispatch => {
  dispatch(userCountNotificationRequest());

  changeCountNotification({ userId, operationType })
    .then(response => {
      dispatch(fetchCurrentUser());
      dispatch(userCountNotificationSuccess());
      transactionId &&
        transactionRole &&
        dispatch(fetchTransaction(transactionId, transactionRole));
    })
    .catch(e => {
      dispatch(userCountNotificationError(storableError(e)));
      log.error(e, 'change-user-count-notification-failed', {
        userId: userId.uuid,
      });
    });
};

let count = 0;
let newTransactionId;
let newIsOpenTransactionProvider;
let newIsOpenTransactionCustomer;
let newAuthorId;
let newOperationType;
let newTransactionRole;

export const fetchMetadataChangeRequest = ({
  transactionId,
  isOpenTransactionProvider,
  isOpenTransactionCustomer,
  authorId,
  operationType,
  transactionRole,
  grantId,
  flinkeyAssignment,
}) => dispatch => {
  newTransactionId = transactionId ? transactionId : newTransactionId;
  newIsOpenTransactionProvider =
    isOpenTransactionProvider !== undefined
      ? isOpenTransactionProvider
      : newIsOpenTransactionProvider;
  newIsOpenTransactionCustomer =
    isOpenTransactionCustomer !== undefined
      ? isOpenTransactionCustomer
      : newIsOpenTransactionCustomer;
  newAuthorId = authorId ? authorId : newAuthorId;
  newOperationType = operationType ? operationType : newOperationType;
  newTransactionRole = transactionRole ? transactionRole : newTransactionRole;

  dispatch(fetchMetadataTransactionRequest());

  metadataChangeRequest({
    transactionId: newTransactionId,
    bodyParams: {
      isOpenTransactionProvider: newIsOpenTransactionProvider,
      isOpenTransactionCustomer: newIsOpenTransactionCustomer,
      grantId,
      flinkeyAssignment,
    },
  })
    .then(response => {
      dispatch(confirmPaymentSuccess(response.data.data.id));
      dispatch(fetchMetadataTransactionSuccess());
      if (newAuthorId) {
        dispatch(
          changeUserCountNotification({
            userId: newAuthorId,
            operationType: newOperationType,
            transactionId: newTransactionId,
            transactionRole: newTransactionRole,
          })
        );
      }
      count = 0;
      newTransactionId = '';
      newIsOpenTransactionProvider = '';
      newIsOpenTransactionCustomer = '';
      newAuthorId = '';
      newOperationType = '';
      newTransactionRole = '';
    })
    .catch(e => {
      count += 1;
      if (count < 7) {
        setTimeout(() => {
          dispatch(
            fetchMetadataChangeRequest({
              transactionId: newTransactionId,
              isOpenTransactionProvider: newIsOpenTransactionProvider,
              isOpenTransactionCustomer: newIsOpenTransactionCustomer,
              authorId: newAuthorId,
              operationType: newOperationType,
              transactionRole: newTransactionRole,
              grantId,
              flinkeyAssignment,
            })
          );
        }, 1000);
      } else {
        dispatch(fetchMetadataTransactionError(storableError(e)));
        log.error(e, 'fetching-metadata-failed', {
          transactionId: transactionId.uuid,
        });
      }
    });
};

export const confirmPayment = (
  orderParams,
  isPriceNull,
  authorId,
  currentUserId,
  metadata,
  grantId,
  flinkeyAssignment = null
) => async (dispatch, getState, sdk) => {
  const isOpenTransactionProvider = metadata ? metadata.isOpenTransactionProvider : null;
  // const isOpenTransactionCustomer = metadata ? metadata.isOpenTransactionCustomer : null;

  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: isPriceNull ? orderParams.id : orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(async response => {
      const order = response.data.data;
      await dispatch(
        fetchMetadataChangeRequest({
          transactionId: order.id,
          isOpenTransactionProvider: false,
          isOpenTransactionCustomer: false,
          grantId,
          flinkeyAssignment,
        })
      );

      isOpenTransactionProvider &&
        dispatch(changeUserCountNotification({ userId: authorId, operationType: 'increment' }));
      isOpenTransactionProvider === null &&
        dispatch(changeUserCountNotification({ userId: authorId, operationType: 'increment' }));
      // isOpenTransactionCustomer &&
      //   dispatch(
      //     changeUserCountNotification({ userId: currentUserId, operationType: 'increment' })
      //   );

      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id || params.orderId;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate the speculative transaction with the given booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (params, isPriceNull) => async (dispatch, getState, sdk) => {
  const { timeOption } = await getState().ListingPage;
  let alias = getAliasHelper(timeOption, isPriceNull);

  const quantity = params && params.lineItems[0].quantity;
  const price = params && (params.lineItems[0].unitPrice.amount / 100) * quantity;

  dispatch(speculateTransactionRequest());
  const bodyParams = {
    transition:
      price < 4 && !isPriceNull ? TRANSITION_REQUEST_PAYMENT_FIXED : TRANSITION_REQUEST_PAYMENT,
    processAlias: alias,
    params: {
      ...params,
      cardToken: 'CheckoutPage_speculative_card_token',
    },
  };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };
  return sdk.transactions
    .initiateSpeculative(bodyParams, queryParams)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.transactions.initiateSpeculative response');
      }
      const tx = entities[0];
      dispatch(speculateTransactionSuccess(tx));
    })
    .catch(e => {
      const { listingId, bookingStart, bookingEnd } = params;
      log.error(e, 'speculate-transaction-failed', {
        listingId: listingId.uuid,
        bookingStart,
        bookingEnd,
      });
      return dispatch(speculateTransactionError(storableError(e)));
    });
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

export const handleCreateGrant = (params, grunt) => async dispatch => {
  dispatch(createGruntRequest());
  const {
    bookingStart,
    bookingEnd,
    lockId,
    // email,
    // userFullName,
    contact,
    tapkeyOwnerId,
    userId,
    listingId,
    timeZone,
  } = params;

  try {
    const listingAuthor = await getListingAuthor(userId);
    let tapkeyToken = listingAuthor?.data?.attributes?.profile?.privateData?.tapkeyToken ?? null;

    if (!tapkeyToken) {
      return 'Tapkey token does not exist!';
    }

    tapkeyToken = await updateUserTapkeyToken(dispatch, tapkeyToken);

    const currentGrunt = grunt.find(({ boundLockId, active }) => boundLockId === lockId && active);

    const res = !currentGrunt ? await createGrant(
      { bookingStart, bookingEnd, lockId, tapkeyOwnerId, contact, listingId, timeZone },
      tapkeyToken
    ) : await updateGrant(
      currentGrunt,
      { bookingStart, bookingEnd, lockId, tapkeyOwnerId, contact, listingId, timeZone },
      tapkeyToken
    );

    if (currentGrunt && !res.data) {
      res.data = currentGrunt;
    }

    if (res.status >= 400) {
      dispatch(createGruntError(storableError(res)));
    } else {
      dispatch(createGruntSuccess());
      return res;
    }
  } catch (e) {}
};

export const getGrunt = (params) => async (dispatch) => {
  dispatch(getGruntRequest());
  const { contactId, tapkeyOwnerId, providerId } = params;

  try {
    const listingAuthor = await getListingAuthor(providerId);
    let tapkeyToken = listingAuthor?.data?.attributes?.profile?.privateData?.tapkeyToken ?? null;

    if (tapkeyToken) tapkeyToken = await updateUserTapkeyToken(dispatch, tapkeyToken);

    if (!tapkeyToken) {
      dispatch(getGruntSuccess(null));
      return
    }

    const path = `/owners/${tapkeyOwnerId}/grants?$filter=ContactId eq '${contactId}'`;
    const res = await makeTapkeyRequest(path, "GET", {}, tapkeyToken);
    if (res === "Tapkey token does not exist!") {
      throw new Error("Tapkey token does not exist!");
    }
    dispatch(getGruntSuccess(res.data));
  } catch(err) {
    dispatch(getGruntError(storableError(err)));
  }
}

export const createFlinkeyAssignments = params => async dispatch => {
  dispatch(createFlinkeyAssignmentRequest());

  try {
    await createAssignments(params);
    dispatch(createFlinkeyAssignmentSuccess('created'));
    return true;
  } catch (e) {
    const result = await e.apiResponse.json();
    dispatch(createFlinkeyAssignmentError(result.error));
  }
};

import React from "react";
import Button from "../Button/Button";
import { FormattedMessage } from '../../util/reactIntl';
import { TAPKEY_PROVIDER } from '../../constants/accessProviders';
import { redirectToNativeApp as redirectToFlinkeyNativeApp } from "../../forms/EditListingLocksForm/EditListingLocksForm.helper";
import { redirectToNativeApp as redirectToTapkeyNativeApp } from "../../containers/TapkeyPage/TapkeyPage.helpers";

import css from './TransactionPanel.css';

const RedirectToNativeApp = ({ accessProvider }) => {
  return (
    <div className={css.accessProviderContainer}>
      <p className={css.openNativeAppTitle}><FormattedMessage id="TransactionPanel.openTapkeyNativeAppTitle" /></p>
      <Button
        className={css.openNativeAppBtn}
        onClick={
          accessProvider === TAPKEY_PROVIDER ?
          redirectToTapkeyNativeApp :
          redirectToFlinkeyNativeApp
        }
      >
        <FormattedMessage id={"TransactionPanel.openTapkeyNativeApp"} />
      </Button>
    </div>
  )
}

export default RedirectToNativeApp;

import React, { useState, useEffect, Fragment } from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { FieldTextInput, Form, PrimaryButton } from '../index';

import css from './Chat.css';

const formatDate = (intl, date) => {
  return {
    short: intl.formatDate(date, {
      month: 'short',
      day: 'numeric',
    }),
    long: `${intl.formatDate(date, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })} ${intl.formatTime(date, {
      hour: 'numeric',
      minute: 'numeric',
      // timeZoneName: 'short',
      hour12: false,
    })}`,
  };
};

const Chat = (props) => {
  const { rootClassName, className, currentUser, socket, listingId } = props;
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    socket.on('connection');
    socket.emit('join', listingId);
    return () => socket.disconnect();
  }, [listingId, socket]);

  socket.on('join', function(oldMessages) {
    setMessages(oldMessages);
  });
  socket.on('chat message', function(msg) {
    msg.id = messages.length + 1;
    const allMessages = [msg, ...messages];
    if (allMessages.length > 10) {
      allMessages.pop()
    }
    setMessages(allMessages);
  });

  // const { firstName, lastName } = (currentUser && currentUser.attributes.profile) || {};
  // const currentUserFullName = `${firstName} ${lastName}`;
  const currentUserId = currentUser && currentUser.id.uuid;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <FinalForm
        {...props}
        render={fieldRenderProps => {
          const { listingId, currentUser, intl, pristine, form, values } = fieldRenderProps;

          const sendMessage = async value => {
            value.preventDefault();
            const data = {
              userId: currentUser.id.uuid,
              userName: currentUser.attributes.profile.displayName,
              message: value.target.message.value,
              roomId: listingId,
            };
            socket.emit('chat message', data);
            form.change('message', null);
          };

          const buttonDisabled = pristine || !values.message;
          const placeholder = intl.formatMessage({
            id: 'Chat.placeholder',
          });

          const parse_w3w = (text) =>{

              if(text.indexOf("///") !== -1){
                let newkey = 0;
                let returnText = [];
                let start = text.indexOf("///");
                let end = text.length;
                if (text.indexOf(" ", start) !== -1){
                  end = text.indexOf(" ", start);
                }
                let link = text.substr(start, end-start);
                newkey ++;
                if (start > 0){
                  returnText.push(<Fragment key = {newkey} >{text.substr(0, start)}</Fragment>);
                }
                newkey ++;
                if (link.indexOf(".") !== -1){
                  returnText.push(<Fragment><a key = {newkey} href={'https://w3w.co/'+link.substr(3)} target="_blank" rel="noopener noreferrer"><span className={css.w3wStripes}>{'///'}</span>{link.substr(3)}</a><br/><span className={css.small}>(externer Link zu what3words)</span><br/></Fragment>);
                }else{
                  returnText.push(<Fragment key = {newkey} >{link}</Fragment>);
                }
                newkey ++;
                if (end < text.length-1){
                  returnText.push(<Fragment key = {newkey} >{text.substr(end)}</Fragment>);
                }
                return(<div>{returnText}</div>);
              }else{
                return(text);
              }
          };

          return (
            <>
              {/* <p className={css.userName}>{currentUserFullName}</p> */}
              <p className={css.boardTitle}>
                <FormattedMessage id="Chat.boardTitle" />
              </p>
              <Form onSubmit={sendMessage} className={css.formContainer}>
                <div className={css.inputContainer}>
                  <FieldTextInput
                    type="textarea"
                    id="message"
                    name="message"
                    maxLength={160}
                    placeholder={placeholder}
                    className={css.messageInput}
                  />
                </div>
                <PrimaryButton type="submit" className={css.buttonSubmit} disabled={buttonDisabled}>
                  <FormattedMessage id="Chat.submitButton" />
                </PrimaryButton>
              </Form>
              <div>
                {messages.length > 0
                  ? messages.map((message, index) => {
                      const isCurrentUserMessage = currentUserId === message.author_id;
                      return (
                        <div
                          key={index}
                          className={
                            isCurrentUserMessage ? css.messageContainerRight : css.messageContainer
                          }
                        >
                          <div className={css.nameDateContainer}>
                            <p className={css.userName}>{message.author_name}</p>
                            <p className={css.messageDate}>
                              <FormattedMessage id="Chat.messageTime" values={{ time: formatDate(intl, message.createdAt).long }} />
                            </p>
                          </div>
                          <p className={css.message}>{parse_w3w(message.text)}</p>
                        </div>
                      );
                    })
                  : null}
              </div>
            </>
          );
        }}
      />
    </div>
  );
}

Chat.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default Chat;
